/* eslint-disable prefer-promise-reject-errors */
import { LoginFields, RegisterFields } from '../types/formTypes';

export const verifyLogin = (fields: LoginFields) =>
	new Promise(
		(resolve: (res: string) => void, reject: (err: string) => void) => {
			if (!fields.email) reject('กรุณาใส่อีเมล');
			if (!validateEmail(fields.email))
				reject('อีเมลต้องอยู่ในรูปแบบที่ถูกต้อง');
			if (!fields.password) reject('กรุณาใส่ระหัสผ่าน');

			resolve('สำเร็จ');
		},
	);

export const verifyForm = (fields: RegisterFields) =>
	new Promise(
		(resolve: (res: string) => void, reject: (err: string) => void) => {
			if (!fields.displayName) reject('กรุณาใส่ชื่อ');
			if (!fields.email) reject('กรุณาใส่อีเมล');
			if (!validateEmail(fields.email))
				reject('อีเมลต้องอยู่ในรูปแบบที่ถูกต้อง');
			if (!fields.password) reject('กรุณาใส่ระหัสผ่าน');
			if (fields.password.length < 6)
				reject('รหัสผ่านต้องมีอย่างน้อย 6 ตัวอักษร');
			if (fields.password.length > 20)
				reject('รหัสผ่านมีได้มากสุด 20 ตัวอักษร');
			if (!validatePassword(fields.password))
				reject(
					'รหัสผ่านต้องมีตัวอักษรเล็ก (a-z) ใหญ่ (A-Z) และตัวเลข (0-9) อย่างน้อยอย่างละหนึ่งตัว',
				);
			if (fields.password !== fields.confirmPassword)
				reject('ยืนยันรหัสผ่านไม่เท่ากับรหัสผ่าน');
			if (fields.phoneNumber && !validatePhone(fields.phoneNumber))
				reject('เบอร์โทรศัพท์ไม่ถูกต้อง');

			resolve('สำเร็จ');
		},
	);

const validateEmail = (emailAddress: string) => {
	if (/^\S+@\S+\.\S+$/.test(emailAddress)) {
		return true;
	}
	return false;
};

/**
 * 
 * @param password 
 * 
 * Checks if password has
    Minimum 6 characters {>>6,20}
    Maximum 20 characters {6,>>20}
    At least one uppercase character (?=.*[A-Z])
    At least one lowercase character (?=.*[a-z])
    At least one digit (?=.*\d)

 */
const validatePassword = (password: string) => {
	if (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/.test(password)) {
		return true;
	}
	return false;
};

const validatePhone = (phoneNumber: string) => {
	if (
		/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(phoneNumber)
	) {
		return true;
	}
	return false;
};
