import { SelectionValue } from '../types/select';
import Roles from './roles';

export const ROLE_SELECTION_OPTIONS: Array<SelectionValue> = [
	{
		display: 'User',
		value: Roles.USER,
	},
	{
		display: 'Admin',
		value: Roles.ADMIN,
	},
];
