// @ts-ignore

import 'firebase/firestore';
import 'firebase/storage';
import Workbook from 'xlsx-template';
import XlsxPopulate from 'xlsx-populate';
import fetch from 'node-fetch';
import firebase from 'firebase/app';
import { FormattedProjectRow } from '../types/projectTypes';
import { Project, ProjectRow } from '../types/projectTypes';

const exportTable = async (projectId: string, tabId?: string) => {
	const projectRef = firebase
		.firestore()
		.collection('Project')
		.doc(projectId);
	const tabsRef = projectRef.collection('Tabs');
	const project: firebase.firestore.DocumentSnapshot = await projectRef.get();
	const tabsDocs: firebase.firestore.QuerySnapshot = await tabsRef.get();
	const projectData = project.data() as Project;
	const { info } = projectData;
	const sheetNames: Array<string> = [];
	sheetNames.push('ต้นฉบับ');
	const gsRef = firebase
		.storage()
		.refFromURL('gs://sampon-erp.appspot.com/template.xlsx');
	const url = await gsRef.getDownloadURL();
	const response = await fetch(url);
	const arrayBuffer = await response.arrayBuffer();
	const template = new Workbook(arrayBuffer as Buffer);
	let sheetNumber = 1;
	const sheetData = {
		ProjectName: info.name,
		ProjectArea: info.area,
		ProjectOrganization: info.organization,
		ProjectDate: info.date,
		row: formatRows(projectData.rows),
	};
	template.substitute(sheetNumber, sheetData);
	sheetNumber += 1;
	tabsDocs.forEach((tabDoc) => {
		const tabData = tabDoc.data();
		sheetNames.push(tabData.name);
		const tabRows = tabData.rows;
		sheetData.row = formatRows(tabRows);
		template.substitute(sheetNumber, sheetData);
		sheetNumber += 1;
	});
	const buffer = template.generate({ type: 'arraybuffer' });
	const workbook = await XlsxPopulate.fromDataAsync(buffer);
	sheetNames.forEach((sheetName, index) => {
		workbook.sheet(index).name(sheetName);
	});
	for (let index = sheetNames.length + 1; index <= 10; index++)
		workbook.deleteSheet(`${index}`);
	const data = await workbook.outputAsync();
	return { data, projectName: info.name };
};

const formatRows = (rows: Array<ProjectRow>) => {
	const formattedRows: Array<FormattedProjectRow> = [];
	rows.forEach((row: ProjectRow) => {
		const formattedRowTemp = {
			name: `   ${row.name}`,
			id: row.id,
			qty: row.qty || '',
			unit: row.unit,
			raw: {
				unit: row.price.raw || '',
				total: row.price.raw * row.qty || '',
			},
			labour: {
				unit: row.price.labour || '',
				total: row.price.labour * row.qty || '',
			},
			total: row.price.raw * row.qty + row.price.labour * row.qty || '',
			note: row.specifiers?.brand || '',
		};
		let formattedRow = {};
		if (!formattedRowTemp.id)
			formattedRow = {
				...formattedRowTemp,
				name: row.name,
			};
		else formattedRow = { ...formattedRowTemp };
		if (row.price.sum) {
			const sumRow = sumRows(
				rows.slice(
					row.price.sum[0],
					row.price.sum[row.price.sum.length - 1] + 1,
				),
				formattedRow as FormattedProjectRow,
			);
			formattedRows.push(sumRow);
			return;
		}
		formattedRows.push(formattedRow as FormattedProjectRow);
	});
	return formattedRows;
};

const sumRows = (
	selectedRows: Array<ProjectRow>,
	formattedRow: FormattedProjectRow,
) => {
	const sumRow = { ...formattedRow };
	sumRow.raw.unit = '';
	sumRow.labour.unit = '';
	sumRow.qty = '';
	let sumRaw = 0;
	let sumLabour = 0;
	selectedRows.forEach((row) => {
		sumRaw += row.price.raw * row.qty;
		sumLabour += row.price.labour * row.qty;
	});
	sumRow.raw.total = sumRaw;
	sumRow.labour.total = sumLabour;
	sumRow.total = sumRaw + sumLabour;
	return sumRow;
};
export default exportTable;
