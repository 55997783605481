import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { Product } from 'features/product/types/productTypes';
import { renameData, deleteData } from 'features/product/functions/products';
import { Brand, Model, Type } from '../types/types';
import Navigation from '../components/navigation';
import Intro from '../components/intro';

import {
	attachTypes,
	attachBrands,
	attachModels,
	attachProducts,
} from '../actions/update/listeners';
import Column from '../components/column/index';
import Showcase from '../components/showcase';

export type Params = {
	typeId: string;
	brandId: string;
	modelId: string;
	productId: string;
};

const ToolsPage: FC = () => {
	const history = useHistory();
	const params = useParams<Params>();

	const { typeId, brandId, modelId, productId } = params;

	/**
	 * Not actually know why I initialize it as object.
	 */
	const [types, setTypes] = useState<Record<string, Type>>({});
	const [brands, setBrands] = useState<Record<string, Brand>>({});
	const [models, setModels] = useState<Record<string, Model>>({});
	const [products, setProducts] = useState<Record<string, Product>>({});

	const level = [
		types[typeId]?.type,
		brands[brandId]?.brand,
		models[modelId]?.model,
		products[productId]?.name,
	];

	useEffect(() => {
		attachTypes(null, setTypes);
	}, [setTypes]);

	useEffect(() => {
		if (typeId) {
			attachBrands({ typeId }, setBrands);
		}
	}, [typeId, setBrands]);

	useEffect(() => {
		if (typeId && brandId) {
			attachModels({ typeId, brandId }, setModels);
		}
	}, [typeId, brandId, setModels]);

	useEffect(() => {
		if (typeId && brandId && modelId) {
			attachProducts({ typeId, brandId, modelId }, setProducts);
		}
	}, [typeId, brandId, modelId, setProducts]);

	const remove = (id: string) => deleteData(id);
	const rename = (id: string, newName: string) => renameData(id, newName);
	const navigate = (path: string) => history.push(`/tools/${path}`);

	return (
		<Container>
			<Navigation specifiersId={{ type: typeId, brand: brandId }} />
			<Intro level={level} />
			<Stack>
				<Curtain />
				<Column
					accessor="type"
					active={typeId}
					width="min(250px, 18%)"
					levelID={[]}
					addMoreText="เพิ่มหมวด"
					list={Object.values(types)}
					onClick={(x) => navigate(x)}
				/>
				{!!typeId && !!types[typeId] && (
					<Column
						accessor="brand"
						active={brandId}
						width="min(250px, 18%)"
						headerText={level[0]}
						addMoreText="เพิ่มยี่ห้อ"
						menuShown={true}
						level={level.slice(0, 1)}
						levelID={[typeId]}
						list={Object.values(brands)}
						onDelete={() => remove(`${typeId}`)}
						onClick={(x) => navigate(`${typeId}/${x}`)}
						onChangeName={(x) => rename(`${typeId}`, x)}
					/>
				)}
				{!!brandId && !!types[typeId] && !!brands[brandId] && (
					<Column
						accessor="model"
						active={modelId}
						width="min(250px, 18%)"
						headerText={level[1]}
						addMoreText="เพิ่มประเภท"
						menuShown={true}
						level={level.slice(0, 2)}
						levelID={[typeId, brandId]}
						list={Object.values(models)}
						onDelete={() => remove(`${typeId}${brandId}`)}
						onClick={(x) => navigate(`${typeId}/${brandId}/${x}`)}
						onChangeName={(x) => rename(`${typeId}${brandId}`, x)}
					/>
				)}
				{!!modelId &&
					!!types[typeId] &&
					!!brands[brandId] &&
					!!models[modelId] && (
						<Column
							accessor="name"
							active={productId}
							width="min(320px)"
							headerText={level[2]}
							addMoreText="เพิ่มอุปกรณ์"
							menuShown={true}
							productIndexer={true}
							level={level.slice(0, 3)}
							levelID={[typeId, brandId, modelId]}
							list={Object.values(products)}
							onDelete={() =>
								remove(`${typeId}${brandId}${modelId}`)
							}
							onChangeName={(x) =>
								rename(`${typeId}${brandId}${modelId}`, x)
							}
							onClick={(x) =>
								navigate(
									`${typeId}/${brandId}/${modelId}/${x.slice(
										6,
									)}`,
								)
							}
						/>
					)}
				{!!productId &&
					!!types[typeId] &&
					!!brands[brandId] &&
					!!models[modelId] &&
					!!products[productId] && (
						<Showcase
							onDeleteProduct={remove}
							product={products[productId]}
						/>
					)}
			</Stack>
		</Container>
	);
};

export default ToolsPage;

const Container = styled.main`
	display: flex;
	flex-direction: column;
	height: 100vh;
`;

const Stack = styled.div`
	height: 100%;
	display: flex;
	flex-direction: row;
	overflow-x: auto;
	overflow-y: hidden;

	/**
	 * Create new stacking context
	 */
	position: relative;
	z-index: 0;
`;

const Curtain = styled.div`
	height: 100%;
	width: 1rem;
	background: var(--neutrals-050);
	border-right: 1px solid var(--neutrals-100);
`;
