import React, { FC, useMemo } from 'react';

import {
	TypeDiscountItem,
	BrandDiscountItem,
	ModelDiscountItem,
} from 'features/discount/types';

import Row from 'features/discount/components/body/row';
import Cell from 'features/discount/components/body/cell';
import table from 'features/discount/constants/table';
import computePercentOfValue from 'features/discount/functions/computePercentOfValue';

interface Props {
	typeDiscount: TypeDiscountItem;
	brandDiscount: BrandDiscountItem;
	modelDiscount: ModelDiscountItem;
	onChange: (
		value: string,
		percentType: 'raw' | 'labour',
		type: string,
	) => void;
}

const ModelRow: FC<Props> = ({
	typeDiscount,
	brandDiscount,
	modelDiscount,
	onChange,
}) => {
	const discounted = useMemo(() => {
		const raw = computePercentOfValue(
			modelDiscount.totalPrice.raw,
			modelDiscount.discountPercent.raw,
		);

		const labour = computePercentOfValue(
			modelDiscount.totalPrice.labour,
			modelDiscount.discountPercent.labour,
		);

		return {
			total: raw + labour,
			raw,
			labour,
		};
	}, [modelDiscount.totalPrice, modelDiscount.discountPercent]);

	const displayedName = modelDiscount.hash
		? modelDiscount.model
		: `${typeDiscount.type} ${modelDiscount.model}`;

	return (
		<Row style={{ color: 'var(--neutrals-700)' }}>
			<Cell
				editable={false}
				width={table.columns[0].width}
				textAlign={table.columns[0].textAlign}
				value={modelDiscount.id}
			/>
			<Cell
				indent={1}
				editable={false}
				width={table.columns[1].width}
				textAlign={table.columns[1].textAlign}
				value={displayedName}
			/>
			<Cell
				editable={false}
				width={table.columns[2].width}
				textAlign={table.columns[2].textAlign}
				value={brandDiscount.brand}
			/>
			<Cell
				editable={false}
				width={table.columns[3].width}
				textAlign={table.columns[3].textAlign}
				value={modelDiscount.totalPrice.raw.toFixed(2)}
			/>
			<Cell
				editable={false}
				width={table.columns[4].width}
				textAlign={table.columns[4].textAlign}
				value={modelDiscount.totalPrice.labour.toFixed(2)}
			/>
			<Cell
				editable={false}
				width={table.columns[5].width}
				textAlign={table.columns[5].textAlign}
				value={(
					modelDiscount.totalPrice.raw +
					modelDiscount.totalPrice.labour
				).toFixed(2)}
			/>
			<Cell
				editable={true}
				width={table.columns[6].width}
				textAlign={table.columns[6].textAlign}
				value={modelDiscount.discountPercent.raw.toString()}
				onChange={(s) => onChange(s, 'raw', typeDiscount.type)}
			/>
			<Cell
				editable={true}
				width={table.columns[7].width}
				textAlign={table.columns[7].textAlign}
				value={modelDiscount.discountPercent.labour.toString()}
				onChange={(s) => onChange(s, 'labour', typeDiscount.type)}
			/>
			<Cell
				editable={false}
				width={table.columns[8].width}
				textAlign={table.columns[8].textAlign}
				value={discounted.raw.toFixed(2)}
			/>
			<Cell
				editable={false}
				width={table.columns[9].width}
				textAlign={table.columns[9].textAlign}
				value={discounted.labour.toFixed(2)}
			/>
			<Cell
				editable={false}
				width={table.columns[10].width}
				textAlign={table.columns[10].textAlign}
				value={discounted.total.toFixed(2)}
			/>
		</Row>
	);
};

export default ModelRow;
