import axios, { AxiosRequestConfig } from 'axios';
import { AppThunk } from 'redux/reducers';
import { HTTPMethods } from '../types/httpTypes';

export const getDataFromRequest = (
	endpoint: string,
	httpMethod: HTTPMethods,
	token: string | null = null,
	data: any = {},
	config: AxiosRequestConfig = {
		headers: {
			'Content-Type': 'application/json',
		},
	},
	// eslint-disable-next-line consistent-return
): AppThunk<Promise<any>> => async () => {
	let configUsed = config;

	if (token) {
		configUsed = {
			...configUsed,
			headers: {
				...configUsed.headers,
				Authorization: `Bearer ${token}`,
			},
		};
	}

	try {
		switch (httpMethod) {
			case HTTPMethods.GET:
				return (await axios.get(endpoint, configUsed)).data;
			case HTTPMethods.POST:
				return (await axios.post(endpoint, data, configUsed)).data;
			case HTTPMethods.PATCH:
				return (await axios.patch(endpoint, data, configUsed)).data;
			case HTTPMethods.DELETE:
				return (await axios.delete(endpoint, configUsed)).data;
		}
	} catch (err) {
		throw new Error(err?.response?.data?.message || 'เกิดข้อผิดพลาด');
	}
};
