import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'redux/reducers';
import { selectProject } from 'features/project/selectors/project';
import { selectTab } from 'features/project/selectors/table';
import addTab from 'features/project/actions/tabs/add';
import updateTab from 'features/project/actions/tabs/update';
import useQuery from 'utilities/hooks/useQuery';
import modifyModelPercent from '../functions/modifyModelPercent';
import modifyTypePercent from '../functions/modifyTypePercent';
import Table from '../components/table';
import Confirm from '../components/confirm';
import applyDiscount from '../functions/applyDiscount';
import { selectDiscountList } from '../selectors';

const DiscountPage: React.FC = () => {
	const query = useQuery();
	const history = useHistory();
	const dispatch = useDispatch();
	const project = useSelector(selectProject);
	const initialList = useSelector((s) => selectDiscountList(s, query));
	const tab = useSelector((s) => selectTab(s, query.tabId));

	const [name, setName] = useState(tab ? tab.name : '');
	const [discountList, setDiscountList] = useState(initialList);

	const handlePercentChange = useCallback(
		(
			value: string,
			percentType: 'raw' | 'labour',
			type: string,
			brand?: string,
			model?: string,
		) => {
			/**
			 * The cell already guaranteed that the
			 * string value is parsable as float.
			 */
			const newPercent = parseFloat(value);

			let newDiscountList;
			if (!brand || !model) {
				newDiscountList = modifyTypePercent(
					discountList,
					type,
					percentType,
					newPercent,
				);
			} else {
				newDiscountList = modifyModelPercent(
					discountList,
					type,
					brand,
					model,
					percentType,
					newPercent,
				);
			}

			setDiscountList(newDiscountList);
		},
		[discountList],
	);

	const handleConfirm = async () => {
		let tabId: string;

		if (!query.tabId) {
			const rows = applyDiscount(project.rows, discountList);
			const reference = project.rows;
			const discount = discountList;
			tabId = await dispatch(
				addTab({
					name,
					rows,
					reference,
					discount,
				}),
			);
		} else {
			tabId = query.tabId;
			const rows = applyDiscount(tab.reference, discountList);
			const discount = discountList;
			await dispatch(updateTab(query.tabId, { name, rows, discount }));
		}

		history.push({
			pathname: '/projects',
			search: `?id=${project.id}&tabId=${tabId}`,
		});
	};

	return (
		<Container>
			<Table
				discountList={discountList}
				handlePercentChange={handlePercentChange}
			/>
			<Confirm
				value={name}
				discountList={discountList}
				onChange={(e) => setName(e.target.value)}
				onConfirm={() => handleConfirm()}
				onCancel={() =>
					history.push({
						pathname: '/projects',
						search: `?id=${project.id}`,
					})
				}
			/>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 2rem 0;

	@media screen and (max-width: 1700px) {
		padding: 0;
		align-items: flex-start;
		width: 100%;
	}
`;

export default DiscountPage;
