import { ProductPrice } from 'features/product/types/productTypes';
import {
	ProjectRow,
	ProjectInfo,
	ProjectPrice,
	Project,
	ProjectDisplayInfo,
} from '../types/projectTypes';

export const EMPTY_PROJECT_INFO: ProjectInfo = {
	name: '',
	area: '',
	date: '',
	organization: '',
	contractor: '',
	contactInfo: '',
};

export const EMPTY_PROJECT_DISPLAY_INFO: ProjectDisplayInfo = {
	id: '',
	name: '',
	area: '',
	adjustment: '',
};

export const EMPTY_PROJECT_PRICE: ProjectPrice = {
	discount: 0,
	raw: 0,
	labour: 0,
	total: 0,
};

export const EMPTY_PRODUCT_PRICE: ProductPrice = {
	labour: 0,
	raw: 0,
	total: 0,
};

export const EMPTY_PROJECT: Project = {
	discount: [],
	id: '',
	rows: [],
	tabs: [],
	info: {
		...EMPTY_PROJECT_INFO,
	},
	price: {
		...EMPTY_PROJECT_PRICE,
	},
};

export const EMPTY_PROJECT_ROW: ProjectRow = {
	name: '',
	hash: '',
	price: {
		...EMPTY_PRODUCT_PRICE,
	},
	id: '',
	qty: 0,
	unit: '',
	note: '',
};
