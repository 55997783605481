import { createGlobalStyle } from 'styled-components';

const Default = createGlobalStyle`
    body {
        --primary-050: #DCEEFB;
        --primary-100: #B6E0FE;
        --primary-200: #84C5F4;
        --primary-300: #62B0E8;
        --primary-400: #4098D7;
        --primary-500: #2680C2;
        --primary-600: #186FAF;
        --primary-700: #0F609B;
        --primary-800: #0A558C;
        --primary-900: #003E6B;

        --accent-050: #FFFBEA;
        --accent-100: #FFF3C4;
        --accent-200: #FCE588;
        --accent-300: #FADB5F;
        --accent-400: #F7C948;
        --accent-500: #F0B429;
        --accent-600: #DE911D;
        --accent-700: #CB6E17;
        --accent-800: #B44D12;
        --accent-900: #8D2B0B;

        --neutrals-050: #F0F4F8;
        --neutrals-100: #D9E2EC;
        --neutrals-200: #BCCCDC;
        --neutrals-300: #9FB3C8;
        --neutrals-400: #829AB1;
        --neutrals-500: #627D98;
        --neutrals-600: #486581;
        --neutrals-700: #334E68;
        --neutrals-800: #243B53;
        --neutrals-900: #102A43; 

        --support-050: #E0FCFF;
        --support-100: #BEF8FD;
        --support-200: #87EAF2;
        --support-300: #54D1DB;
        --support-400: #38BEC9;
        --support-500: #2CB1BC;
        --support-600: #14919B;
        --support-700: #0E7C86;
        --support-800: #0A6C74;
        --support-900: #044E54; 

        --danger-050: #FFEEEE;
        --danger-100: #FACDCD;
        --danger-200: #F29B9B;
        --danger-300: #E66A6A;
        --danger-400: #D64545;
        --danger-500: #BA2525;
        --danger-600: #A61B1B;
        --danger-700: #911111;
        --danger-800: #780A0A;
        --danger-900: #610404; 

        margin: 0; 
        padding: 0;
        box-sizing: border-box;
    }

    body * {
        margin: 0; 
        padding: 0; 
        box-sizing: inherit; 
    }
`;

export default Default;
