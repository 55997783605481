import { TableDefinition } from 'utilities/types/table';
import common_th from 'translations/th/common.json';
import { TypeDiscountItem } from '../types';

const DISCOUNT_TABLE_DEFINITION: TableDefinition<TypeDiscountItem> = {
	columns: [
		{
			header: common_th.code,
			accessor: 'id',
			width: 90,
			editable: false,
			textAlign: 'center',
		},
		{
			header: common_th.type,
			accessor: 'type',
			width: 180,
			editable: false,
			textAlign: 'left',
		},
		{
			header: common_th.brand,
			accessor: 'brand',
			width: 200,
			editable: false,
			textAlign: 'center',
		},
		{
			header: common_th.material_price,
			accessor: 'price.raw',
			width: 120,
			editable: false,
			textAlign: 'center',
		},
		{
			header: common_th.labour_price,
			accessor: 'price.labour',
			width: 120,
			editable: false,
			textAlign: 'center',
		},
		{
			header: common_th.total_price,
			accessor: 'price.total',
			width: 120,
			editable: false,
			textAlign: 'center',
		},
		{
			header: common_th.material_discount_percent,
			accessor: 'percent.raw',
			width: 180,
			editable: true,
			textAlign: 'center',
		},
		{
			header: common_th.labour_discount_percent,
			accessor: 'percent.labour',
			width: 180,
			editable: true,
			textAlign: 'center',
		},
		{
			header: common_th.material_discounted_price,
			accessor: 'price.discounted.raw',
			width: 200,
			editable: false,
			textAlign: 'center',
		},
		{
			header: common_th.labour_discounted_price,
			accessor: 'price.discounted.labour',
			width: 200,
			editable: false,
			textAlign: 'center',
		},
		{
			header: common_th.total_remaining,
			accessor: 'total_remaining',
			width: 150,
			editable: false,
			textAlign: 'center',
		},
	],
	rowTemplate: [],
	sortKey: 'id',
};

export default DISCOUNT_TABLE_DEFINITION;
