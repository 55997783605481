import firebase from 'firebase/app';
import 'firebase/firestore';
import { Tab, TabsActionType, TabsUpdate } from 'features/tabs/types';
import { AppThunk } from 'redux/reducers';
import { batch as batchDispatch } from 'react-redux';
import { ProjectAction } from 'features/project/types/actionTypes';
import { ProjectTab } from 'features/project/types/projectTypes';

const updateTab = (
	tabId: string,
	m: Partial<Omit<Tab, 'id'>>,
): AppThunk<Promise<void>> => async (dispatch, getState) => {
	const { id, tabs } = getState().project;

	const mutation = { ...m };
	const projectRef = firebase.firestore().collection('Project').doc(id);
	const tabRef = projectRef.collection('Tabs').doc(tabId);

	(Object.keys(mutation) as (keyof Omit<Tab, 'id'>)[]).map(
		(x) => mutation[x] === undefined && delete mutation[x],
	);

	const batch = firebase.firestore().batch();

	batch.update(tabRef, {
		...mutation,
	});

	await batch.commit();
	dispatch(updateTabs(tabId, mutation));

	if (m.name) {
		batchDispatch(() => {
			const i = tabs.findIndex((t) => t.id === tabId);
			dispatch(removeTab(tabId));
			dispatch(
				setTab([
					...tabs.slice(0, i),
					{
						id: tabId,
						name: m.name!,
					},
					...tabs.slice(i + 1),
				]),
			);
			dispatch({
				type: ProjectAction.LOCK_ROWS,
			});
		});
	}
};

export default updateTab;

const updateTabs = (id: string, m: Partial<Omit<Tab, 'id'>>): TabsUpdate => ({
	type: TabsActionType.UPDATE,
	id,
	tab: {
		...m,
	},
});

const setTab = (tabs: ProjectTab[]) => ({
	type: ProjectAction.SET_TAB,
	tabs,
});

const removeTab = (id: string) => ({
	type: ProjectAction.ADD_TAB,
	id,
});
