import MiniWave from 'components/animated/miniwave';
import { Hammer, Admin, Add } from 'components/icons';
import React, { FC, ChangeEvent } from 'react';
import styled, { keyframes } from 'styled-components';
import useToggle from 'utilities/hooks/useToggle';
import computeTotal from '../functions/computeTotal';
import { DiscountList } from '../types';

interface Props {
	value: string;
	discountList: DiscountList;
	onChange: (e: ChangeEvent<HTMLInputElement>) => void;
	onConfirm: () => Promise<unknown>;
	onCancel: () => void;
}

const Confirm: FC<Props> = ({
	value,
	discountList,
	onChange,
	onConfirm,
	onCancel,
}) => {
	const total = computeTotal(discountList);
	const [loading, toggleLoading] = useToggle(false);

	const handleConfirm = async () => {
		toggleLoading();
		await onConfirm();
	};

	return (
		<Wrapper>
			<Internal>
				<Input
					placeholder="ปรับรอบที่ 1"
					value={value}
					onChange={onChange}
				/>
				<PrimaryButton onClick={handleConfirm}>
					ยืนยันการปรับ
					{loading && (
						<WaveWrapper>
							<MiniWave color="var(--neutrals-050)" />
						</WaveWrapper>
					)}
				</PrimaryButton>
				<Spacer />
				<SecondaryButton onClick={onCancel}>ยกเลิก</SecondaryButton>
			</Internal>
			<Summary>
				<Placeholder style={{ borderRadius: '8px 0 0 0' }} />
				<LabelRow style={{ borderRadius: '0 8px 0 0' }}>
					<NumberLabel>ราคารวมเดิม</NumberLabel>
					<NumberLabel>ราคารวมปรับแล้ว</NumberLabel>
					<PercentLabel>เปอร์เซนต์</PercentLabel>
				</LabelRow>
				<IconsWrapper>
					<Hammer
						size={22}
						color="none"
						style={{ transform: 'translateY(1px)' }}
					/>
					<Popup>ค่าวัสดุและอุปกรณ์</Popup>
				</IconsWrapper>
				<NumberWrapper>
					<OldNumber>{total.original.raw.toFixed(2)}</OldNumber>
					<NewNumber>{total.discounted.raw.toFixed(2)}</NewNumber>
					<PercentChange>
						{getPercentChange(
							total.original.raw,
							total.discounted.raw,
						) || 0}
						%
					</PercentChange>
				</NumberWrapper>
				<IconsWrapper>
					<Admin size={21} color="none" />
					<Popup>ค่าแรงงาน</Popup>
				</IconsWrapper>
				<NumberWrapper>
					<OldNumber>{total.original.labour.toFixed(2)}</OldNumber>
					<NewNumber>{total.discounted.labour.toFixed(2)}</NewNumber>
					<PercentChange>
						{getPercentChange(
							total.original.labour,
							total.discounted.labour,
						) || 0}
						%
					</PercentChange>
				</NumberWrapper>
				<IconsWrapper
					style={{
						background: 'white',
						borderRadius: '0 0 0 8px',
						borderTop: '1px solid var(--neutrals-100)',
					}}>
					<Add size={22} color="none" />
					<Popup>รวมทั้งคู่</Popup>
				</IconsWrapper>
				<LastNumberWrapper style={{ borderRadius: '0 0 8px 0' }}>
					<OldNumber>{total.original.total.toFixed(2)}</OldNumber>
					<NewNumber>{total.discounted.total.toFixed(2)}</NewNumber>
					<PercentChange>
						{getPercentChange(
							total.original.total,
							total.discounted.total,
						) || 0}
						%
					</PercentChange>
				</LastNumberWrapper>
			</Summary>
			<UserAidText>
				<Title>โปรดอ่าน</Title>
				การปรับราคาจะสร้างแท็บใหม่ให้คุณในชื่อที่ระบุไว้ข้างต้น
				แต่คุณจะไม่สามารถแก้ไขต้นฉบับได้หากคุณมีการปรับราคาในโครงการนี้
				<br />
				(แท็บการปรับราคาสามารถลบได้)
			</UserAidText>
		</Wrapper>
	);
};

export default Confirm;

function getPercentChange(before: number, later: number): string {
	return ((later / before) * 100 - 100).toFixed(2);
}

const WaveWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--primary-800);
	display: flex;
	align-items: center;
	justify-content: center;

	& > * {
		transform: translateY(2px);
	}
`;

const Summary = styled.div`
	width: 480px;
	display: grid;
	grid-template-columns: 60px 1fr;
	grid-template-rows: auto auto auto auto;
	border-radius: 8px;
	margin-top: 1rem;
	border: 1px solid var(--neutrals-100);
`;

const Delay = keyframes`
	0% {
		opacity: 0;
	}
	75% {
		opacity: 0; 
	}
	100% {
		opacity: 1; 
	}
`;

const Popup = styled.div`
	display: none;
	align-items: center;
	justify-content: center;
	padding: 0 0.5rem;
	font-size: 14px;
	color: var(--neutrals-050);
	background: var(--neutrals-900);
	border-radius: 6px;
	position: absolute;
	height: auto;
	left: 0;
	height: 32px;
	z-index: 1;
	transform: translateX(calc(-100% - 0.5rem));
	animation: ${Delay} 800ms ease-out;
	text-align: center;
	width: max-content;
`;

const IconsWrapper = styled.div`
	width: 100%;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: var(--neutrals-050);
	grid-column: 1 / 2;
	color: var(--neutrals-400);
	border-right: 1px solid var(--neutrals-100);
	position: relative;

	&:first-child {
		border-radius: 8px 0 0 0;
	}

	&:hover ${Popup} {
		display: flex;
	}
`;

const Placeholder = styled.div`
	background: var(--neutrals-050);
	border-right: 1px solid var(--neutrals-100);
	border-bottom: 1px solid var(--neutrals-100);
`;

const PercentChange = styled.span`
	display: flex;
	flex-direction: row;
	align-items: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 16px;
	color: var(--neutrals-200);

	&::after {
		content: '→';
		position: relative;
		font-size: 24px;
		margin-left: 0.5rem;
	}
`;

const NumberWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: baseline;
	justify-content: space-between;
	position: relative;
	padding: 1rem;
	background: var(--neutrals-050);
	color: var(--neutrals-500);
	grid-column: 2 / 3;
	height: 60px;

	${PercentChange} {
		color: var(--neutrals-200);
	}
`;

const LastNumberWrapper = styled(NumberWrapper)`
	background: white;
	color: var(--neutrals-600);
	border-top: 1px solid var(--neutrals-100);

	${PercentChange} {
		color: var(--accent-500);
	}
`;

const LabelRow = styled(NumberWrapper)`
	height: 40px;
	padding: 0 1rem;
	align-items: center;
	border-bottom: 1px solid var(--neutrals-100);

	&::after {
		display: none;
	}
`;

const NumberLabel = styled.span`
	font-size: 14px;
	font-weight: 500;
	color: var(--neutrals-400);
`;

const PercentLabel = styled.span`
	font-size: 14px;
	font-weight: 400;
	color: var(--neutrals-400);
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
`;

const OldNumber = styled.span`
	font-size: 24px;
	font-weight: 500;
`;

const NewNumber = styled.span`
	font-size: 24px;
	font-weight: 500;
`;

const Title = styled.div`
	margin-bottom: 0.5rem;
	font-size: 20px;
	font-weight: 600;
	color: var(--neutrals-400);
`;

const UserAidText = styled.div`
	width: 480px;
	padding: 1.125rem;
	text-align: center;
	color: var(--neutrals-600);
	font-size: 16px;
	font-weight: 500;
	background-color: var(--neutrals-050);
	border-radius: 8px;
	margin-top: 1rem;
	line-height: 1.45;
`;

const Wrapper = styled.div`
	margin-top: 1.5rem;
	height: auto;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const Internal = styled.div`
	display: flex;
	height: auto;
	flex-direction: row;
	justify-content: flex-start;
	width: 480px;
`;

const PrimaryButton = styled.button`
	outline: none;
	appearance: none;
	border: none;
	background: var(--primary-800);
	font-size: 16px;
	font-weight: 500;
	color: white;
	height: 48px;
	padding: 0 1rem;
	width: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 0 8px 8px 0;
	cursor: pointer;
	position: relative;
	overflow: hidden;

	&:hover {
		background: var(--primary-700);
	}

	&:active {
		background: var(--primary-600);
	}
`;

const SecondaryButton = styled(PrimaryButton)`
	flex-grow: 1;
	background: var(--neutrals-050);
	color: var(--neutrals-700);
	height: 48px;
	border-radius: 8px;

	&:hover {
		background: var(--neutrals-100);
	}

	&:active {
		background: var(--neutrals-200);
	}
`;

const Spacer = styled.div`
	width: 1rem;
	height: 100%;
`;

const Input = styled.input.attrs({
	type: 'text',
})`
	outline: none;
	border: 2px solid var(--neutrals-100);
	border-right: none;
	appearance: none;
	background: tranparent;
	height: 48px;
	min-width: 120px;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0 1rem;
	font-size: 16px;
	font-weight: 500;
	color: var(--neutrals-800);
	border-radius: 8px 0 0 8px;

	&::placeholder {
		color: var(--neutrals-400);
	}

	&:focus {
		border-color: var(--neutrals-300);
	}
`;
