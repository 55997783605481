import {
	ProjectActionTypes,
	ProjectAction,
} from 'features/project/types/actionTypes';
import { Project } from 'features/project/types/projectTypes';

const setProject = (project: Project): ProjectActionTypes => ({
	type: ProjectAction.SET_PROJECT,
	project,
});

export default setProject;
