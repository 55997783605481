import React from 'react';
import useToggle from 'utilities/hooks/useToggle';
import Browser from '../components/browser';

interface InjectedProps {
	toggleBrowser: () => void;
}

const withProjectBrowser = <P extends InjectedProps>(
	WrappedComponent: React.FC<P>,
) => {
	const ReturnedComponent: React.FC<Omit<P, 'toggleBrowser'>> = ({
		...props
	}) => {
		const [browser, toggleBrowser] = useToggle();

		return (
			<>
				<WrappedComponent
					{...(props as P)}
					toggleBrowser={toggleBrowser}
				/>
				{browser && <Browser onClose={toggleBrowser} />}
			</>
		);
	};
	return ReturnedComponent;
};

export default withProjectBrowser;
