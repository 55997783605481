import React, { FC } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'redux/reducers';
import { Cog, New, Tray, Home, Hammer, Excel } from 'components/icons';
import useQuery from 'utilities/hooks/useQuery';
import Portal from 'utilities/components/portal';
import useToggle from 'utilities/hooks/useToggle';
import deleteProject from 'features/project/actions/update/update.deleteProject';
import createProject from 'apis/project/create';
import Close from 'components/icons/close';
import duplicateProject from 'features/project/actions/update/update.duplicateProject';
import exportTable from 'features/project/functions/exportTable';
import setError from 'features/error/actions/edit/set.setError';
import withProjectBrowser from '../../wrappers/withProjectBrowser';

const XLSX =
	'vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8';

interface Props {
	onToggle: () => void;
	projectId: string;
	toggleBrowser: () => void;
}

const Menu: FC<Props> = ({ onToggle, projectId, toggleBrowser }) => {
	const history = useHistory();
	const query = useQuery();
	const dispatch = useDispatch() as AppDispatch;
	const userProjects = useSelector((state: RootState) => state.user.projects);

	const [setting, toggleSetting] = useToggle();

	const isUserOwnProject =
		userProjects.find((project) => project.info.id === projectId) !==
		undefined;

	const handleCreateProject = async () => {
		const id = await dispatch(createProject());
		history.push({
			pathname: '/projects',
			search: `?id=${id}`,
		});
	};

	const handleGoHome = () => {
		if (isUserOwnProject) history.push('/welcome');
		else history.goBack();
	};

	const handleDelete = async () => {
		toggleSetting();
		await dispatch(deleteProject(projectId));
		history.push('/welcome');
	};

	const handleDuplicate = async () => {
		const copyId = await dispatch(duplicateProject());
		const win = window.open(`/projects?id=${copyId}`, '_blank');
		if (win) win.focus();
	};

	const handleDownloadFile = async () => {
		const { id: projectId, tabId } = query;
		const { data, projectName } = await exportTable(projectId, tabId);
		const blob = new Blob([data], { type: XLSX });
		const a = window.document.createElement('a');
		a.href = window.URL.createObjectURL(blob);
		a.download = `${projectName}.xlsx`;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		dispatch(
			setError(
				'ระบบได้ทำการดาว์นโหลดโครงการเป็นไฟล์ Excel ให้ท่าน' +
					'\n' +
					'(โดยปกติแล้ว จะทำการดาว์นโหลดไปยังไฟล์' +
					'\n' +
					'downloads หรือตำแหน่งที่ท่านได้ตั้งไว้)',
				{
					errorHeaderText: 'โปรดทราบ',
					errorBtnText: 'ตกลง',
					themeColor: 'var(--primary-800)',
				},
			),
		);
	};

	return (
		<Container>
			<Group>
				<Border />
				<SquareButton onClick={handleGoHome}>
					{isUserOwnProject ? (
						<Home
							style={{ transform: 'translateY(-1px)' }}
							size={21}
							color="none"
						/>
					) : (
						<Close
							style={{ transform: 'translateY(-1px)' }}
							size={21}
							color="white"
						/>
					)}
				</SquareButton>
				<Border />
				{isUserOwnProject ? (
					<>
						<RectangleButton onClick={handleCreateProject}>
							<New size={22} color="none" />
							<Text>สร้างใหม่</Text>
						</RectangleButton>
						<Border />
						<RectangleButton onClick={toggleBrowser}>
							<Tray size={21} color="none" />
							<Text>เปิดโครงการ</Text>
						</RectangleButton>
						<Border />
						<RectangleButton onClick={onToggle}>
							<Hammer size={20} color="none" />
							<Text>ค้นหาอุปกรณ์</Text>
						</RectangleButton>
						<Border />
					</>
				) : null}
			</Group>
			<Spacer />
			<Group>
				<Border />
				<RectangleButton onClick={handleDownloadFile}>
					<Excel size={22} color="none" />
					<Text>Export เป็น Excel</Text>
				</RectangleButton>
				<Border />
				{isUserOwnProject ? (
					<RectangleButton onClick={toggleSetting}>
						<Cog size={24} color="none" />
						<Text>ลบหรือคัดลอกโครงการ</Text>
						{setting && (
							<Portal>
								<Backdrop>
									<SettingModal
										onClick={(e) => e.stopPropagation()}>
										<SettingTitle>
											ลบหรือคัดลอกโครงการ
										</SettingTitle>
										<CloseButton onClick={toggleSetting}>
											<Close
												size={26}
												color="var(--neutrals-300)"
											/>
										</CloseButton>
										<WarningText>
											การคัดลอกโครงการจะทำการสร้างโครงการใหม่
											ด้วยคุณสมบัติคล้ายกับอันปัจจุบัน
										</WarningText>
										<NeutralButton
											onClick={handleDuplicate}>
											คัดลอกโครงการ
										</NeutralButton>
										<StyledDivider />
										<WarningText>
											เราไม่สามารถกู้โครงการของคุณได้
											หลังจากลบไปแล้ว
											คุณคิดดีแล้วใช่หรือไม่
										</WarningText>
										<DangerButton onClick={handleDelete}>
											ลบโครงการ
										</DangerButton>
									</SettingModal>
								</Backdrop>
							</Portal>
						)}
					</RectangleButton>
				) : null}
				<Border />
			</Group>
		</Container>
	);
};

export default withProjectBrowser(Menu);

const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	position: sticky;
	top: 0;

	z-index: 1;

	width: 100%;
	height: 2.5rem;

	background-color: var(--primary-900);
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 4px 16px rgba(0, 0, 0, 0.05);
`;

const Spacer = styled.div`
	flex: 1;
`;

const Group = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	height: 40px;
	background: transparent;
`;

const SquareButton = styled.button`
	position: relative;
	outline: none;
	display: flex;
	align-items: center;
	justify-content: center;
	appearance: none;
	border: none;
	background: transparent;
	width: 40px;
	height: 100%;
	color: var(--neutrals-050);
	cursor: pointer;

	&:hover {
		background: var(--primary-800);
	}

	&:active {
		background: var(--primary-700);
	}
`;

const RectangleButton = styled(SquareButton)`
	width: auto;
	padding: 0 0.5rem;
`;

const Text = styled.span`
	margin-left: 0.5rem;
	margin-right: 0.25rem;
	font-family: Sarabun;
	font-size: 14px;
	font-weight: 500;
	color: var(--neutrals-050);
`;

const Border = styled.div`
	height: 100%;
	width: 1px;
	background: var(--neutrals-700);
`;

const Backdrop = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: ${document.body.clientWidth}px;
	height: ${document.body.clientHeight}px;
	background: rgba(0, 0, 0, 0.6);
`;

const SettingModal = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	padding: 0;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 4px 8px rgba(0, 0, 0, 0.05),
		0 16px 32px rgba(0, 0, 0, 0.05);
	background: white;
	transform: translate(-50%, -50%);
	width: 300px;
	border-radius: 8px;
	overflow: hidden;
`;

const SettingTitle = styled.div`
	font-size: 20px;
	font-weight: 600;
	color: var(--neutrals-800);
	padding: 0.75rem 1rem;
	background: var(--neutrals-050);
`;

const WarningText = styled.div`
	font-size: 14px;
	font-weight: 400;
	color: var(--neutrals-500);
	padding: 1rem 1rem 0.75rem;
	line-height: 1.5;
`;

const DangerButton = styled.button`
	outline: none;
	border: none;
	appearance: none;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	height: 36px;
	color: var(--danger-500);
	background: var(--danger-050);
	border-radius: 6px;
	font-size: 16px;
	font-weight: 500;
	margin: 1rem;
	margin-top: 0;
	cursor: pointer;

	&:hover {
		background: var(--danger-100);
	}
`;

const NeutralButton = styled(DangerButton)`
	color: var(--primary-600);
	background: var(--primary-050);
	&:hover {
		background: var(--primary-100);
	}
`;

const CloseButton = styled.button`
	outline: none;
	border: none;
	background: transparent;
	appearance: none;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
	position: absolute;
	right: 10px;
	top: 10px;
	border-radius: 100px;
	cursor: pointer;

	&:hover {
		background: rgba(0, 0, 0, 0.07);
	}

	&:active {
		background: rgba(0, 0, 0, 0.09);
	}
`;

const StyledDivider = styled.hr`
	margin: 0 auto;
	width: 75%;
	border: none;
	border-top: 1px solid var(--neutrals-200);
`;
