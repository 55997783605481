import { EMPTY_PROJECT_ROW } from 'features/project/constants/templates';
import { batch } from 'react-redux';
import { AppThunk } from 'redux/reducers';
import { SelectionAction } from 'features/selection/types';
import { ProjectActionTypes } from 'features/project/types/actionTypes';
import clearSelect from 'features/project/actions/select/clear';
import setRows from 'features/project/actions/edit/setRows';

const batchDelete = (): AppThunk<void> => (dispatch, getState) => {
	const { project, selection } = getState();
	const { list } = selection;
	const { rows } = project;

	const nextRows = [...rows];

	list.forEach((index) => {
		nextRows[index] = {
			...EMPTY_PROJECT_ROW,
			hash: nextRows[index].hash,
		};
	});

	batch(() => {
		dispatch<SelectionAction>(clearSelect());
		dispatch<ProjectActionTypes>(setRows(nextRows));
	});
};

export default batchDelete;
