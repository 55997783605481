/* eslint-disable react/jsx-curly-newline */

import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import Roles from 'features/admin/constants/roles';

const PrivateRoute: FC<RouteProps & { requiredRole?: Array<string> }> = ({
	children,
	requiredRole = [Roles.USER, Roles.ADMIN],
	...delegated
}) => {
	const { isLoggedIn, role } = useSelector((state: RootState) => state.user);

	return (
		<Route
			{...delegated}
			render={() =>
				isLoggedIn && requiredRole.includes(role) ? (
					children
				) : (
					<Redirect
						to={{
							pathname: '/',
						}}
					/>
				)
			}
		/>
	);
};

export default PrivateRoute;
