import { RootState } from 'redux/reducers';
import { createSelector } from 'reselect';
import { Query } from 'utilities/hooks/useQuery';
import { selectTab } from './table';

export const selectProject = (s: RootState) => s.project;

export const selectBreadcrumb = createSelector<
	RootState,
	Query,
	string | undefined,
	string | undefined,
	string | undefined,
	string
>(
	(s) => selectProject(s)?.info?.name,
	(s, q) => selectTab(s, q.tabId)?.name,
	(_, q) => (q?.ref ? 'ต้นฉบับเดิม' : 'ฉบับปรับแล้ว'),
	(a, b, c) => {
		if (a && b && c) return `${a}/${b}/${c}`;
		if (a && b) return `${a}/${b}`;
		return a || '';
	},
);

export const selectLock = (s: RootState) => s.project.rowsLocked;
