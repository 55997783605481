// @ts-nocheck
import React, { FC } from 'react';
import styled from 'styled-components';
import { Add, PriceTags, ChevronUp, File, Bin } from 'components/icons';
import useToggle from 'utilities/hooks/useToggle';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch, RootState } from 'redux/reducers';
import useQuery from 'utilities/hooks/useQuery';
import deleteTab from 'features/project/actions/tabs/remove';
import setError from 'features/error/actions/edit/set.setError';

const Tab: FC = () => {
	const { id, tabId } = useQuery();
	const [callout, toggleCallout] = useToggle(false);
	const [options, toggleOptions] = useToggle(false);
	const tabs = useSelector((s) => s.project.tabs);
	const name = useSelector((s) => s.project.info.name);
	const dispatch = useDispatch();
	const userProjects = useSelector((state: RootState) => state.user.projects);
	const projectId = useSelector((state: RootState) => state.project.id);

	const history = useHistory();

	const isUserOwnProject =
		userProjects.find((project) => project.info.id === projectId) !==
		undefined;

	const isTabsLimitExceeded = tabs.length > 9;

	const closeBoth = () => {
		callout && toggleCallout();
		options && toggleOptions();
	};

	const handleAddDiscount = () => {
		if (isTabsLimitExceeded) {
			dispatch(
				setError('สามารถทำการปรับลด/เพิ่มราคาได้ไม่เกิน 9 รอบ', {
					returnHome: false,
				}),
			);
			return;
		}
		closeBoth();
		history.push(`/projects/adjust?id=${id}`);
	};

	const handleDelete = async (id: string) => {
		closeBoth();
		await dispatch(deleteTab(id));
	};

	return (
		<Container>
			{isUserOwnProject ? (
				<SquareButton
					onClick={() => {
						toggleCallout();
						options && toggleOptions();
					}}>
					<Add
						size={24}
						color="none"
						style={{
							transition: 'all 200ms ease',
							transform: callout ? 'rotate(45deg)' : '',
						}}
					/>
				</SquareButton>
			) : null}
			{!tabId ? (
				<ActiveTab>
					<TextWrapper>ต้นฉบับ: {name}</TextWrapper>
				</ActiveTab>
			) : (
				<TabButton to={{ pathname: '/projects', search: `?id=${id}` }}>
					<TextWrapper>ต้นฉบับ: {name}</TextWrapper>
				</TabButton>
			)}
			{tabs.map((t) =>
				tabId === t.id ? (
					<ActiveTab key={t.id}>
						<TextWrapper>{t.name}</TextWrapper>
						<ChevronWrapper
							onClick={() => {
								toggleOptions();
								callout && toggleCallout();
							}}
							style={{ color: 'var(--primary-600)' }}>
							<ChevronUp
								style={{
									transition: 'transform 250ms',
									transform: options
										? 'rotate(180deg) translateY(-1px)'
										: 'translateY(1px)',
								}}
								size={18}
							/>
						</ChevronWrapper>
						{options && (
							<OptionCallout>
								<OptionChoice
									to={{
										pathname: '/projects',
										search: `?id=${id}&tabId=${t.id}`,
									}}
									onClick={closeBoth}>
									<File
										size={22}
										color="var(--neutrals-200)"
									/>
									ฉบับปรับราคา
								</OptionChoice>
								{/* <OptionChoice
									to={{
										pathname: '/projects',
										search: `?id=${id}&tabId=${t.id}&ref=true`,
									}}
									onClick={closeBoth}>
									<Tray
										size={22}
										color="var(--neutrals-200)"
									/>
									ต้นฉบับเดิม
								</OptionChoice> */}
								<OptionChoice
									to={{
										pathname: '/projects/adjust',
										search: `?id=${id}&tabId=${t.id}`,
									}}
									onClick={closeBoth}>
									<PriceTags
										size={22}
										color="var(--neutrals-200)"
									/>
									ข้อมูลการปรับราคา
								</OptionChoice>
								<OptionChoice
									to={{
										pathname: '/projects',
										search: `?id=${id}`,
									}}
									onClick={() => handleDelete(t.id)}>
									<Bin
										size={22}
										color="var(--neutrals-200)"
									/>
									ลบแทบนี้
								</OptionChoice>
							</OptionCallout>
						)}
					</ActiveTab>
				) : (
					<TabButton
						key={t.id}
						to={{
							pathname: '/projects',
							search: `?id=${id}&tabId=${t.id}`,
						}}>
						{t.name}
						<ChevronWrapper
							style={{
								pointerEvents: 'none',
								color: 'var(--neutrals-300)',
							}}>
							<ChevronUp size={18} />
						</ChevronWrapper>
					</TabButton>
				),
			)}
			{callout && (
				<AdditionCallout>
					<AdditionChoice
						to="#"
						as="div"
						onClick={() => handleAddDiscount()}>
						<PriceTags size={22} color="var(--neutrals-200)" />
						ปรับลด/เพิ่มราคา
					</AdditionChoice>
				</AdditionCallout>
			)}
		</Container>
	);
};

export default Tab;

const Container = styled.div`
	height: 40px;
	padding: 0 1rem;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	background: var(--neutrals-050);
	border-top: 1px solid var(--neutrals-100);
	position: fixed;
	bottom: 0;
	width: 100%;
`;

const TextWrapper = styled.div`
	max-width: 240px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

const ActiveTab = styled.div`
	position: relative;
	outline: none;
	appearance: none;
	border: none;
	background: transparent;
	font-size: 16px;
	font-weight: 500;
	height: 100%;
	width: auto;
	border-left: 1px solid var(--neutrals-100);
	border-right: 1px solid var(--neutrals-100);
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 1.25rem;

	color: var(--primary-700);
	background: white;
	cursor: default;

	&::after {
		content: '';
		display: block;
		width: 100%;
		height: 3px;
		position: absolute;
		top: 0;
		left: 0;
		background: var(--primary-600);
	}
`;

const TabButton = styled(Link)`
	position: relative;
	outline: none;
	appearance: none;
	border: none;
	background: transparent;
	color: var(--neutrals-500);
	font-size: 16px;
	font-weight: 500;
	height: 100%;
	width: auto;
	border-left: 1px solid var(--neutrals-100);
	border-right: 1px solid var(--neutrals-100);
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 1.25rem;
	text-decoration: none;

	&:hover {
		background: var(--neutrals-100);
	}

	&:active {
		background: var(--neutrals-200);
	}
`;

const ChevronWrapper = styled.button`
	outline: none;
	border: none;
	appearance: none;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	margin-left: 4px;
	width: 24px;
	height: 24px;
	color: var(--neutrals-300);
	margin-right: -0.5rem;
	cursor: pointer;
	transform: translateY(1px);
	background: transparent;

	&:hover {
		background: var(--primary-050);
	}

	&:active {
		background: var(--primary-100);
	}
`;

const SquareButton = styled.button`
	outline: none;
	border: none;
	appearance: none;
	background: transparent;
	height: 100%;
	width: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--neutrals-500);
	border-left: 1px solid var(--neutrals-100);
	cursor: pointer;

	&:hover {
		background: var(--neutrals-100);
	}

	&:active {
		background: var(--neutrals-200);
	}
`;

const AdditionCallout = styled.div`
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	position: absolute;
	top: -4.5rem;
	width: 240px;
	background: white;
	padding: 0.5rem 0;
	border-radius: 4px;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07), 0 6px 18px rgba(0, 0, 0, 0.07);
`;

const AdditionChoice = styled(Link)`
	outline: none;
	appearance: none;
	border: none;
	background: transparent;
	width: 100%;
	padding: 0 1rem;
	height: 40px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	font-size: 16px;
	font-weight: 500;
	color: var(--neutrals-600);
	background: white;
	cursor: pointer;
	text-decoration: none;

	&:hover {
		background: var(--neutrals-050);
	}

	&:active {
		background: var(--neutrals-100);
	}

	& > * {
		margin-right: 1rem;
	}
`;

const OptionCallout = styled.div`
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	position: absolute;
	top: -9.5rem;
	width: 240px;
	left: 0;
	background: white;
	padding: 0.5rem 0;
	border-radius: 4px;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07), 0 6px 18px rgba(0, 0, 0, 0.07);
`;

const OptionChoice = styled(AdditionChoice)``;
