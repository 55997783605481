import Modal from 'features/tools/constants/modal';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import useOutsideClick from 'utilities/hooks/useOutsideClick';

interface Props {
	onNameChangeHandler?: (name: string) => void;
	toggleModal: any;
	activeModals: any;
	closeAllModals: any;
	initialName: string;
	level?: Array<string>;
}

const EditHeader: React.FC<Props> = ({
	onNameChangeHandler,
	toggleModal,
	closeAllModals,
	activeModals,
	initialName,
	level,
}) => {
	const [name, setName] = useState(initialName);
	const editHeaderRef = useRef(null);

	useOutsideClick(editHeaderRef!, () => {
		if (!activeModals[Modal.CONFIRM]) {
			closeAllModals();
		}
	});

	const handleNameChange = (name: string) => {
		toggleModal(Modal.CONFIRM, true, {
			modalTitle: 'ยืนยันการเปลี่ยนชื่อ',
			message: `ท่านต้องการเปลี่ยนชื่อ "${
				level ? level[level.length - 1] : ''
			}" เป็น "${name}" จริงหรือ`,
			onConfirmCallback: () => {
				onNameChangeHandler && onNameChangeHandler(name);
				if (!activeModals[Modal.CONFIRM]) {
					closeAllModals();
				}
			},
		});
	};

	return (
		<Container ref={editHeaderRef}>
			<Input value={name} onChange={(e) => setName(e.target.value)} />
			<Confirm onClick={() => handleNameChange(name)}>ยืนยัน</Confirm>
		</Container>
	);
};

export default EditHeader;

const Container = styled.div`
	display: flex;
	height: 100%;
`;

const Input = styled.input`
	outline: none;
	appearance: none;
	background: white;
	border-radius: 8px 0 0 8px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	height: 100%;
	flex: 1;
	font-size: 16px;
	font-weight: 500;
	color: var(--neutrals-800);
	padding: 0 1rem;
	border: none;
	width: calc(100% - 68px);
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07), 0 6px 18px rgba(0, 0, 0, 0.07);
`;

const Confirm = styled.div`
	outline: none;
	appearance: none;
	border: none;
	background: var(--primary-600);
	color: white;
	font-size: 16px;
	font-weight: 500px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 68px;
	min-width: 68px;
	border-radius: 0 6px 6px 0;
	height: 100%;
	cursor: pointer;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07), 0 6px 18px rgba(0, 0, 0, 0.07);

	&:hover {
		background: var(--primary-700);
	}

	&:active {
		background: var(--primary-800);
	}
`;
