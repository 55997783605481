import insertProduct from 'features/project/actions/edit/set.insertProduct';
import selectClear from 'features/project/actions/select/clear';
import {
	ProjectAction,
	ProjectActionTypes,
} from 'features/project/types/actionTypes';
import { ProjectRow } from 'features/project/types/projectTypes';
import { AppThunk } from 'redux/reducers';
import { DraggerActionType, DraggerType } from './type';

export const dragSum = (): AppThunk<void> => (dispatch, getState) => {
	dispatch({
		type: DraggerActionType.DRAG_SUM,
		list: getState().selection.list,
	});
};

/**
 * Action that handles the droping of various thing in general
 * @param {number} index
 */
export const dropGeneral = (index: number): AppThunk<void> => (
	dispatch,
	getState,
) => {
	const { dragger, selection } = getState();

	switch (dragger.type) {
		case DraggerType.PRODUCT: {
			const { product, specifiers } = dragger;
			dispatch(insertProduct(product, index, specifiers));
			break;
		}
		case DraggerType.SUM: {
			const { list } = dragger;
			dispatch(
				editRow(index, {
					name: 'ผลรวม',
					price: { sum: list, raw: 0, labour: 0, total: 0 },
				}),
			);
			break;
		}
		case DraggerType.ROW: {
			const { from } = dragger;
			const { list } = selection;
			if (list.length) {
				dispatch(batchMove(index, list));
				dispatch(selectClear());
			} else {
				dispatch(moveProduct(from, index));
			}
		}
	}
};

/**
 * Local action creators for moving row
 * @param {number} from
 * @param {number} to
 */
const moveProduct = (from: number, to: number): ProjectActionTypes => ({
	type: ProjectAction.MOVE_PRODUCT,
	from,
	to,
});

/**
 * Local action creators for batch row moving
 * @param {number} to
 * @param {number[]} list
 */
const batchMove = (to: number, list: number[]): ProjectActionTypes => ({
	type: ProjectAction.BATCH_MOVE,
	to,
	list,
});

/**
 * Local action creators for editing price
 * @param {number} index
 * @param {Partial<ProjectRow>} mutation
 */
const editRow = (
	index: number,
	mutation: Partial<ProjectRow>,
): ProjectActionTypes => ({
	type: ProjectAction.EDIT_ROW,
	index,
	mutation,
});
