export const getToken = () => {
	return localStorage.getItem('BEARER_TOKEN');
};

export const setToken = (token: string) => {
	localStorage.setItem('BEARER_TOKEN', token);
};

export const deleteToken = () => {
	localStorage.removeItem('BEARER_TOKEN');
};
