import React, { FC, Fragment } from 'react';
import styled from 'styled-components';
import { DiscountList } from '../types';
import ModelRow from './body/model';
import TypeRow from './body/type';
import Header from './header';

interface Props {
	discountList: DiscountList;
	handlePercentChange: (
		value: string,
		percentType: 'raw' | 'labour',
		type: string,
		brand?: string,
		model?: string,
	) => void;
}

const Table: FC<Props> = ({ discountList, handlePercentChange }) => {
	return (
		<Container>
			<Header />
			{discountList.map((typeDiscount) => {
				return (
					<Fragment key={typeDiscount.type}>
						<TypeRow
							key={typeDiscount.type}
							discount={typeDiscount}
							onPercentChange={handlePercentChange}
						/>
						{typeDiscount.data.map((brandDiscount) =>
							brandDiscount.models.map((modelDiscount) => (
								<ModelRow
									key={modelDiscount.id}
									typeDiscount={typeDiscount}
									brandDiscount={brandDiscount}
									modelDiscount={modelDiscount}
									onChange={(a, b, c) =>
										handlePercentChange(
											a,
											b,
											c,
											brandDiscount.brand,
											modelDiscount.model,
										)
									}
								/>
							)),
						)}
					</Fragment>
				);
			})}
			<Spacer />
		</Container>
	);
};

export default Table;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	width: auto;

	@media screen and (max-width: 1700px) {
		padding: 2rem;
		overflow: auto;
		width: 100%;
	}

	transition: transform 300ms;
`;

const Spacer = styled.div`
	height: 1rem;
	width: 1rem;
`;
