import React, {
	ChangeEvent,
	FormEvent,
	useLayoutEffect,
	useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { AppDispatch, RootState } from 'redux/reducers';

import register from 'features/account/user/actions/auth/auth.register';
import withForm from 'utilities/wrappers/withForm';
import setError from 'features/error/actions/edit/set.setError';
import Ring from 'components/animated/ring';
import { IoChevronBack } from 'react-icons/io5';
import RedAsterisk from 'utilities/components/redAsterisk';
import { EMPTY_REGISTER_FIELDS } from '../constants/templates';
import { RegisterFields } from '../types/formTypes';
import { verifyForm } from '../functions/form';

interface Props {
	fields: RegisterFields;
	handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const RegisterPage: React.FC<Props> = ({ fields, handleChange }) => {
	const [isSubmitting, setIsSubmitting] = useState(false);

	const { isLoggedIn } = useSelector((state: RootState) => state.user);

	const dispatch = useDispatch<AppDispatch>();
	const history = useHistory();

	const handleRegister = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (isSubmitting) return;
		try {
			setIsSubmitting(true);
			await verifyForm(fields);
			await dispatch(register(fields));
			dispatch(
				setError('การสมัครสำเร็จ', {
					errorHeaderText: 'โปรดทราบ',
					errorBtnText: 'ตกลง',
					themeColor: 'var(--neutrals-600)',
				}),
			);
			handleLoginRedirect();
		} catch (err) {
			dispatch(
				setError(err.message || err, {
					returnHome: false,
				}),
			);
			setIsSubmitting(false);
		}
	};

	const handleLoginRedirect = () => {
		history.push('/');
	};

	useLayoutEffect(() => {
		if (isLoggedIn) {
			history.replace('/welcome');
		}
	}, [history, isLoggedIn]);

	return (
		<Container>
			<Form id="register" onSubmit={handleRegister}>
				<GoBackText onClick={handleLoginRedirect}>
					<IoChevronBack size={12} />
					กลับหน้าหลัก
				</GoBackText>
				<Title>ลงทะเบียน</Title>
				<Label>
					ขื่อ
					<RedAsterisk /> (เช่น นาย จอห์น โด)
				</Label>
				<Input
					name="displayName"
					value={fields.displayName}
					onChange={handleChange}
				/>
				<Label>
					อีเมล
					<RedAsterisk /> (เช่น johndoe@example.com)
				</Label>
				<Input
					name="email"
					value={fields.email}
					type="email"
					onChange={handleChange}
				/>
				<Label>
					รหัสผ่าน
					<RedAsterisk /> (6-20 ตัว มี A-Z a-z 0-9
					อย่างน้อยอย่างละตัว)
				</Label>
				<Input
					name="password"
					value={fields.password}
					type="password"
					onChange={handleChange}
				/>
				<Label>
					ยืนยันรหัสผ่าน
					<RedAsterisk />
				</Label>
				<Input
					name="confirmPassword"
					value={fields.confirmPassword}
					type="password"
					onChange={handleChange}
				/>
				<Label>เบอร์โทรศัพท์ (เช่น 081-xxx-xxxx)</Label>
				<Input
					name="phoneNumber"
					value={fields.phoneNumber}
					type="phone"
					onChange={handleChange}
				/>
				<Label>บริษัท (เช่น บริษัท กขค)</Label>
				<Input
					name="company"
					value={fields.company}
					onChange={handleChange}
				/>
				<Button type="submit" isDisabled={isSubmitting}>
					{isSubmitting ? (
						<>
							<Ring color="var(--neutrals-400)" />
						</>
					) : (
						<span>สมัคร</span>
					)}
				</Button>
				<Spacer />
			</Form>
		</Container>
	);
};

export default withForm(RegisterPage, { ...EMPTY_REGISTER_FIELDS });

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	height: 100vh;
	background: var(--neutrals-800);
`;

const Form = styled.form`
	display: flex;
	flex-flow: column;
	align-items: stretch;
	overflow: auto;
	height: 90vh;
	width: 480px;
	padding: 2rem;
	background: white;
	border-radius: 8px;
`;

const GoBackText = styled.div`
	display: flex;
	align-items: center;
	text-justify: center;
	height: 100%;
	color: var(--neutrals-200);
	font-size: 0.8rem;

	& > *:first-child {
		margin-top: 0.2rem;
		margin-right: 0.1rem;
	}

	&:hover {
		cursor: pointer;
		color: var(--neutrals-500);
		text-decoration: underline;
	}

	margin-bottom: 0.75rem;
`;

const Title = styled.h1`
	font-size: 24px;
	font-weight: 600;
	color: var(--neutrals-800);
	margin-bottom: 1.5rem;

	display: flex;
	align-items: center;
`;

const Input = styled.input`
	height: 48px;
	font-size: 16px;
	font-weight: 400;
	color: var(--neutrals-800);
	margin-bottom: 10px;
	background: var(--neutrals-050);
	padding: 0.75rem 0.875rem;
	display: flex;
	align-items: center;
	flex-direction: row;
	outline: none;
	border: none;
	border-radius: 8px;
`;

const Label = styled.label`
	font-size: 14px;
	color: var(--neutrals-500);
	margin: 0.5rem 0;
`;

const Button = styled.button<{ isDisabled?: boolean }>`
	outline: none;
	border: none;
	font-size: 1rem;
	font-weight: 600;
	color: white;
	background: ${(props) =>
		props.isDisabled ? 'var(--neutrals-100)' : 'var(--primary-800)'};
	appearance: none;
	transition: all 150ms;
	border-radius: 6.2rem;
	min-height: 3rem;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 1.5rem;
	margin-bottom: 2rem;

	transform: scale(1);

	& > * {
		transform: ${(props) => (props.isDisabled ? 'scale(0.4)' : 'none')};
		margin-bottom: ${(props) => (props.isDisabled ? '1.95rem' : 'none')};
	}

	&:hover {
		transform: ${(props) => (props.isDisabled ? 'none' : 'scale(1.03)')};
		cursor: ${(props) => (props.isDisabled ? 'default' : 'pointer')};
	}

	&:active {
		transform: scale(1);
	}
`;

const Spacer = styled.div`
	min-height: 1rem;
`;
