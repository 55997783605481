import {
	combineReducers,
	ThunkAction,
	ThunkDispatch,
	Action,
} from '@reduxjs/toolkit';
import {
	useDispatch as useDispatchBase,
	useSelector as useSelectorBase,
	TypedUseSelectorHook,
} from 'react-redux';
import user from 'features/account/user/reducers/user';
import admin from 'features/admin/reducers/admin';
import error from 'features/error/reducers/error';
import project from 'features/project/reducers/project';
import table from 'features/project/reducers/table';
import selection from 'features/selection/reducer';
import dragger from 'features/dragger/reducer';
import tabs from 'features/tabs/reducer';

const rootReducer = combineReducers({
	user,
	admin,
	error,
	project,
	table,
	selection,
	dragger,
	tabs,
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;
export type AppDispatch = ThunkDispatch<RootState, unknown, Action<string>>;

export default rootReducer;

export const useSelector: TypedUseSelectorHook<RootState> = useSelectorBase;
export const useDispatch = () => useDispatchBase<AppDispatch>();
