import { Component } from 'react';
import ReactDOM from 'react-dom';

const modalRoot = document.getElementById('modal-root') as HTMLElement;

interface Props {}

class Portal extends Component<Props> {
	el: HTMLElement;

	constructor(props: Props) {
		super(props);
		this.el = document.createElement('div');
		this.el.setAttribute('id', 'portal');
	}

	componentDidMount() {
		modalRoot.appendChild(this.el);
	}

	componentWillUnmount() {
		modalRoot.removeChild(this.el);
	}

	render() {
		return ReactDOM.createPortal(this.props.children, this.el);
	}
}

export default Portal;
