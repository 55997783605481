import Fuse from 'fuse.js';

export const searchArrayOfObjects = <T>(
	arr: T[],
	keys: string[],
	param: string,
) => {
	if (param === '' || !param) return arr;

	try {
		const options = {
			keys,
		};

		const fuse = new Fuse(arr, options);

		return fuse.search(param).map((result) => result.item);
	} catch (err) {
		return [];
	}
};
