import { getToken } from 'features/admin/functions/token';
import { AdminAction } from 'features/admin/types/actionTypes';
import { AppThunk } from 'redux/reducers';
import { ENDPOINT } from 'services/http/constants/endpoints';
import { getDataFromRequest } from 'services/http/functions/getDataFromRequest';
import { HTTPMethods } from 'services/http/types/httpTypes';
import listUsers from './auth.listUsers';

const deleteUser = (uid: string): AppThunk => (dispatch) => {
	dispatch({
		type: AdminAction.SET_USER_LIST_LOADING,
	});
	return dispatch(
		getDataFromRequest(
			ENDPOINT.FUNCTIONS_API.DELETE_USER,
			HTTPMethods.POST,
			getToken(),
			{
				uid,
			},
		),
	).then((res) => {
		dispatch(listUsers());
		return res;
	});
};

export default deleteUser;
