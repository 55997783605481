import Modal from 'features/tools/constants/modal';
import React from 'react';
import styled from 'styled-components';
import ColumnMenu from '../../modal/columnMenu';
import EditHeader from './content/editHeader';
import ReadModeHeader from './content/readModeHeader';

interface Props {
	onDeleteHandler: (e: React.MouseEvent<HTMLElement>) => void;
	onNameChangeHandler?: (name: string) => void;
	closeAllModals: any;
	toggleModal: any;
	activeModals: any;
	level?: string[];
	headerText?: string;
	accessor: 'type' | 'brand' | 'model' | 'name';
	menuIconShown: boolean | undefined;
}

const Header: React.FC<Props> = ({
	onNameChangeHandler,
	onDeleteHandler,
	closeAllModals,
	toggleModal,
	activeModals,
	level,
	headerText,
	accessor,
	menuIconShown,
}) => {
	return (
		<Container
			style={{
				padding: activeModals[Modal.HEADER_NAME_EDITOR]
					? '0'
					: '0 1rem',
			}}>
			{activeModals[Modal.HEADER_NAME_EDITOR] ? (
				<EditHeader
					onNameChangeHandler={onNameChangeHandler}
					initialName={headerText || ''}
					toggleModal={toggleModal}
					activeModals={activeModals}
					closeAllModals={closeAllModals}
					level={level}
				/>
			) : (
				<ReadModeHeader
					toggleModal={toggleModal}
					headerText={headerText}
					menuIconShown={menuIconShown}
				/>
			)}
			{activeModals[Modal.OPTIONS_MENU] &&
				!activeModals[Modal.HEADER_NAME_EDITOR] && (
					<ColumnMenu
						accessor={accessor}
						activeModals={activeModals}
						onOutsideClickHandler={() =>
							toggleModal(Modal.OPTIONS_MENU, false)
						}
						onUploadPDFHandler={() =>
							toggleModal(Modal.FILE_UPLOAD, true, {
								fileType: 'pdf',
							})
						}
						onUploadExcelHandler={() =>
							toggleModal(Modal.FILE_UPLOAD, true, {
								fileType: 'excel',
							})
						}
						onDeleteHandler={onDeleteHandler}
						onNameEditHandler={() => {
							toggleModal(Modal.HEADER_NAME_EDITOR, true);
						}}
					/>
				)}
		</Container>
	);
};

export default Header;

const Container = styled.div`
	height: 36px;
	min-height: 36px;
	width: 100%;
	padding: 0 1rem;
	background: var(--neutrals-050);
	border-bottom: 1px solid var(--neutrals-100);
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 16px;
	font-weight: 600;
	color: var(--neutrals-400);
	position: relative;
`;
