import React, { useState } from 'react';
import { motion, PanInfo, useDragControls } from 'framer-motion';
import styled from 'styled-components';

import DragIndicator from 'components/icons/dragIndicator';
import Close from 'components/icons/close';

export type Props = {
	url: string;
	style: React.CSSProperties;
	onClose: () => void;
	onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
};

const CatalogModal = React.forwardRef<React.RefObject<Element>, Props>(
	({ url, style, onClick, onClose }, ref) => {
		const [dragging, setDragging] = useState(false);
		const dragControls = useDragControls();

		const startDrag = (event: React.MouseEvent<HTMLDivElement>) => {
			setDragging(true);
			dragControls.start(event, { snapToCursor: false });
		};

		const endDrag = () => {
			setDragging(false);
		};

		const validateDrag = (
			e: MouseEvent | TouchEvent | PointerEvent,
			info: PanInfo,
		) => {
			if (!dragging) {
				(dragControls as any).componentControls.forEach(
					(entry: any) => {
						entry.stop(e, info);
					},
				);
			}
		};

		return (
			<Modal
				drag
				style={style}
				shown={true}
				onMouseDown={onClick}
				dragElastic={0}
				dragMomentum={false}
				dragPropagation={false}
				dragControls={dragControls}
				dragConstraints={ref as any}
				onDragStart={validateDrag}>
				<Handle onMouseDown={startDrag} onMouseUp={endDrag}>
					<DragIndicator />
					<Button onClick={onClose}>
						<Close size={24} color="white" />
					</Button>
				</Handle>
				<iframe title="Catalog" width="100%" height="100%" src={url} />
			</Modal>
		);
	},
);

export default CatalogModal;

const Modal = styled(motion.div)<{ shown: boolean }>`
	display: ${({ shown }) => (shown ? 'grid' : 'none')};
	grid-template-columns: minmax(0, 1fr);
	grid-template-rows: auto minmax(0, 1fr);

	position: absolute;
	width: 600px;
	height: 90vh;
	min-width: 450px;
	background: white;
	border-radius: 8px;
	overflow: hidden;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06), 0 4px 8px rgba(0, 0, 0, 0.06),
		0 8px 16px rgba(0, 0, 0, 0.06), 0 16px 32px rgba(0, 0, 0, 0.06);
`;

const Handle = styled.div`
	height: 29px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--primary-800);
	cursor: grab;

	&:active {
		cursor: grabbing;
	}
`;

const Button = styled.button`
	outline: none;
	appearance: none;
	border: none;
	background: transparent;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 26px;
	height: 26px;
	position: absolute;
	right: 0.25rem;
	cursor: pointer;

	&:hover {
		background: var(--primary-700);
	}

	&:active {
		background: var(--primary-600);
	}
`;
