import styled from 'styled-components';

const Row = styled.div`
	display: flex;
	align-items: stretch;
	justify-content: flex-start;
	background: white;
	width: auto;
	height: 34px;
	min-height: 34px;
	border-left: 1px solid var(--neutrals-100);
`;

export default Row;
