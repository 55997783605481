import React from 'react';
import styled from 'styled-components';

import { Add, Albums } from 'components/icons';
import { Product } from 'features/product/types/productTypes';
import { Brand, Model, Type, Specifier } from '../../../types/types';

interface Props {
	onNavigateToSpecifier: (specifierID: string) => void;
	onAddMoreHandler: () => void;
	list: Type[] | Brand[] | Model[] | Product[];
	accessor?: string;
	addMoreText?: string;
	activeList?: string;
	productIndexer?: boolean;
}

const SpecifierList: React.FC<Props> = ({
	list,
	accessor,
	addMoreText,
	activeList,
	productIndexer,
	onAddMoreHandler,
	onNavigateToSpecifier,
}) => {
	const specifierMapDisplay = (list as Specifier[])
		// sort by names alphabetically
		.sort((a, b) => {
			const [nameA, nameB] = [
				a[accessor as keyof Specifier],
				b[accessor as keyof Specifier],
			];
			if (accessor === 'brand') {
				if (nameA < nameB) return -1;
				if (nameA > nameB) return 1;
			} else {
				if (a.id < b.id) return -1;
				if (a.id > b.id) return 1;
			}
			return 0;
		})
		.map((l) =>
			(activeList && l.id === activeList) ||
			l.id.substring(6) === activeList ? (
				<Selected key={l.id}>
					{accessor !== 'name' && <Index>{l.id}</Index>}
					{productIndexer && <Index>{l.id.substring(6)}</Index>}
					{l[accessor as keyof Specifier]}
					{(l as Brand).file && (
						<PDFIndicator>
							<Albums size={20} color="none" />
						</PDFIndicator>
					)}
				</Selected>
			) : (
				<Button key={l.id} onClick={() => onNavigateToSpecifier(l.id)}>
					{accessor !== 'name' && <Index>{l.id}</Index>}
					{productIndexer && <Index>{l.id.substring(6)}</Index>}
					{l[accessor as keyof Specifier]}
					{(l as Brand).file && (
						<PDFIndicator>
							<Albums size={20} color="none" />
						</PDFIndicator>
					)}
				</Button>
			),
		);

	return (
		<Container>
			<AddMore onClick={onAddMoreHandler}>
				<Add size={24} />
				{addMoreText || 'เพิ่มข้อมูล'}
			</AddMore>
			{specifierMapDisplay}
			<Placeholder />
		</Container>
	);
};

export default SpecifierList;

const PDFIndicator = styled.div`
	position: absolute;
	right: 0.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 28px;
	height: 28px;
	color: var(--neutrals-300);
`;

const Container = styled.div`
	display: flex;
	height: 100%;
	overflow-y: auto;
	flex-direction: column;
	align-items: stretch;
`;

const Button = styled.button`
	position: relative;
	outline: none;
	appearance: none;
	border: none;
	background: none;
	padding: 0 1.125rem;
	min-height: 35px;
	width: 100%;
	height: 35px;
	font-size: 16px;
	font-weight: 400;
	color: var(--neutrals-600);
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	white-space: nowrap;
	text-overflow: ellipsis;

	&:hover {
		background: var(--neutrals-050);
	}
`;

const Placeholder = styled.div`
	min-height: 0.75rem;
	background: white;
	width: 100%;
`;

const AddMore = styled(Button)`
	margin-top: 0.5rem;
	font-size: 16px;
	font-weight: 600;
	color: var(--primary-600);
	cursor: pointer;
	margin-bottom: 0.5rem;

	& > *:first-child {
		margin-left: -0.25rem;
		margin-right: 0.25rem;
	}

	&::before {
		content: '';
		border-radius: 8px;
		width: calc(100% - 1rem);
		background: var(--primary-050);
		height: 34px;
		opacity: 0;
		position: absolute;
		left: 8px;
		z-index: -1;
	}

	&:hover {
		background: transparent;

		&::before {
			opacity: 0.7;
		}
	}
`;

const Index = styled.span`
	font-family: monospace;
	font-size: 14px;
	font-weight: 600;
	color: var(--neutrals-300);
	opacity: 0.7;
	width: 2.25rem;
	text-align: start;
	transform: translateY(1px);
`;

const Selected = styled.div`
	padding: 0 1.125rem;
	width: 100%;
	height: 35px;
	min-height: 35px;
	font-size: 16px;
	font-weight: 500;
	color: var(--neutrals-700);
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	background: var(--neutrals-050);
	white-space: nowrap;
	text-overflow: ellipsis;
	position: relative;
`;
