import React, { useState } from 'react';
import styled from 'styled-components';

import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/reducers';
import uploadCatalogFile from 'features/tools/actions/update/set.uploadCatalogFile';
import setError from 'features/error/actions/edit/set.setError';
import excelParser from 'features/tools/functions/excelParser';
import Ring from 'components/animated/ring';
import Modal from '.';

interface Props {
	onCloseModalHandler: () => void;
	specifierIDs: Array<string>;
	specifiers?: Array<string>;
	fileType: 'pdf' | 'excel';
}

const FileUploadModal: React.FC<Props> = ({
	onCloseModalHandler,
	specifierIDs,
	specifiers,
	fileType,
}) => {
	const dispatch = useDispatch<AppDispatch>();
	const [isLoading, setLoading] = useState(false);

	const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			try {
				const file = e.target.files[0];
				const [type, brand, model] = specifierIDs;
				switch (fileType) {
					case 'pdf':
						setLoading(true);
						await dispatch(
							uploadCatalogFile({
								file,
								specifiers: model
									? {
											type,
											brand,
											model,
									  }
									: {
											type,
											brand,
									  },
							}),
						);
						setLoading(false);
						dispatch(
							setError('การนำเข้าข้อมูลสำเร็จ', {
								errorHeaderText: 'โปรดทราบ',
								errorBtnText: 'ตกลง',
								themeColor: 'var(--primary-800)',
							}),
						);
						break;
					case 'excel':
						dispatch(
							excelParser(file, {
								type,
								brand,
							}),
						);
				}
				onCloseModalHandler();
			} catch (err) {
				dispatch(
					setError(
						err?.response?.data?.message ||
							'เกิดข้อผิดพลาดในการอัพโหลด',
						{
							returnHome: false,
						},
					),
				);
			}
		}
	};

	return (
		<Modal
			modalTitle={isLoading ? 'โปรดรอสักครู่' : 'อัพไฟล์'}
			onCloseModalHandler={onCloseModalHandler}>
			{isLoading ? (
				<LoadingContainer>
					<Ring color="var(--accent-500)" />
				</LoadingContainer>
			) : (
				<>
					<Group>
						<SectionLabel>เป้าหมาย</SectionLabel>
						<SectionData>
							{specifiers?.length ? (
								<>
									{specifiers.map((specifier) => (
										<Span key={specifier}>
											<Slash />
											{specifier}
										</Span>
									))}
								</>
							) : (
								'-'
							)}
						</SectionData>
					</Group>
					<ChooseFile htmlFor="fileImportOrUpload">
						เลือกไฟล์
					</ChooseFile>
					<input
						id="fileImportOrUpload"
						type="file"
						accept={
							fileType === 'pdf'
								? 'application/pdf'
								: '.xlsx, .xls, .csv'
						}
						onChange={handleFileUpload}
						style={{ display: 'none' }}
					/>
				</>
			)}
		</Modal>
	);
};

export default FileUploadModal;

const ChooseFile = styled.label`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	height: 36px;
	color: var(--primary-600);
	background: var(--primary-050);
	border-radius: 6px;
	font-size: 16px;
	font-weight: 500;
	margin: 1rem;
	margin-top: 0;
	cursor: pointer;
	&:hover {
		background: var(--primary-100);
	}
`;

const Group = styled.div`
	padding: 1rem;
	padding-top: 0;
	display: flex;
	flex-direction: column;
`;

const SectionLabel = styled.div`
	font-size: 14px;
	font-weight: 500;
	color: var(--neutrals-500);
	margin-bottom: 0.375rem;
`;

const SectionData = styled.div`
	font-size: 16px;
	font-weight: 500;
	color: var(--neutrals-700);
	display: flex;
	flex-direction: column;
`;

const Span = styled.span`
	margin-top: 0.375rem;
	color: var(--neutrals-600);

	&:first-of-type {
		margin-top: 0;
	}
`;

const Slash = styled.span`
	&::before {
		content: '→';
		padding: 0 0.375rem;
		font-size: 16px;
		font-weight: 500;
		color: var(--neutrals-400);
	}

	margin-right: 0.375rem;
`;

const LoadingContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: flex-start;
	height: 8rem;

	& > * {
		margin-top: 1rem;
	}
`;
