import { DiscountList } from 'features/discount/types';
import { ProjectRow } from 'features/project/types/projectTypes';

export type Tab = {
	id: string;
	name: string;
	rows: ProjectRow[];
	reference: ProjectRow[];
	discount: DiscountList;
};

export type TabsState = Tab[];

export enum TabsActionType {
	INIT = '[TABS] INIT',
	ADD = '[TABS] ADD',
	UPDATE = '[TABS] UPDATE',
}

export type TabsInit = {
	type: TabsActionType.INIT;
	tabs: Tab[];
};

export type TabsUpdate = {
	type: TabsActionType.UPDATE;
	id: string;
	tab: Partial<Tab>;
};

export type TabsAdd = {
	type: TabsActionType.ADD;
	tab: Tab;
};

export type TabsAction = TabsInit | TabsUpdate | TabsAdd;
