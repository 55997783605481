import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { File, Hammer, Admin, Tray, New } from 'components/icons';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'redux/reducers';
import { useHistory } from 'react-router-dom';
import createProject from 'apis/project/create';
import withProjectBrowser from 'features/project/wrappers/withProjectBrowser';
import Roles from 'features/admin/constants/roles';
import logout from 'features/account/user/actions/auth/auth.logout';
import { BiLogOut } from 'react-icons/bi';

interface Props {
	toggleBrowser: () => void;
}

const WelcomePage: React.FC<Props> = ({ toggleBrowser }) => {
	const recentProjects = useSelector((state: RootState) =>
		state.user?.projects?.filter((p) => p.lastOpened).slice(0, 4),
	);
	const dispatch = useDispatch<AppDispatch>();
	const userRole = useSelector((state: RootState) => state.user.role);
	const history = useHistory();

	const handleCreateNewProject = async () => {
		const id = await dispatch(createProject());
		handleOpenProject(id);
	};

	const handleOpenTools = () => {
		history.push({
			pathname: '/tools',
		});
	};

	const handleOpenProject = (id: string) => {
		history.push({
			pathname: '/projects',
			search: `?id=${id}`,
		});
	};

	const handleOpenAdminPage = () => {
		history.push('/admin');
	};

	const handleLogout = () => {
		dispatch(logout());
	};

	return (
		<Container>
			<Layout>
				<Header span={recentProjects?.length ? 2 : 1}>
					<Title>Ant_Estimate</Title>
					<Subtitle>เครื่องมือจัดทำบีโอคิว</Subtitle>
				</Header>
				<Section key="section1">
					<SectionTitle>เริ่มต้น</SectionTitle>
					<MenuButton onClick={handleCreateNewProject}>
						<New size={22} color="var(--neutrals-300)" />
						สร้างไฟล์ใหม่
					</MenuButton>
					<MenuButton onClick={toggleBrowser}>
						<Tray size={22} color="var(--neutrals-300)" />
						เปิดไฟล์
					</MenuButton>
					{userRole === Roles.ADMIN ? (
						<>
							<MenuButton onClick={handleOpenTools}>
								<Hammer size={22} color="var(--neutrals-300)" />
								ดูคลังอุปกรณ์
							</MenuButton>

							<MenuButton onClick={handleOpenAdminPage}>
								<Admin size={22} color="var(--neutrals-300)" />
								ระบบแอดมิน
							</MenuButton>
						</>
					) : null}
					<LogoutButton onClick={handleLogout}>
						<BiLogOut size={22} />
						ออกจากระบบ
					</LogoutButton>
				</Section>
				{recentProjects?.length ? (
					<Section key="section2">
						<SectionTitle>โครงการล่าสุด</SectionTitle>
						{recentProjects.map((p) => (
							<MenuButton
								key={p.info?.id}
								onClick={() => handleOpenProject(p.info?.id)}>
								<File size={22} color="var(--neutrals-300)" />
								{p.info?.name}
								<Spacer />
								<Time>{moment(p.lastOpened).fromNow()}</Time>
							</MenuButton>
						))}
					</Section>
				) : null}
			</Layout>
		</Container>
	);
};

export default withProjectBrowser(WelcomePage);

const Container = styled.div`
	height: 100vh;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: var(--neutrals-800);
`;

const Layout = styled.div`
	display: grid;
	grid-template-columns: 400px;
	grid-template-rows: auto auto;
	grid-row-gap: 36px;
	grid-column-gap: 24px;

	transform: translateY(10px);
	box-sizing: content-box;
	padding: 4rem;
	background: white;
	border-radius: 10px;

	@media (min-width: 800px) {
		grid-template-columns: 250px;
		grid-auto-columns: 400px;
	}

	@media (min-width: 1400px) {
		transform: translateY(-35px);
		grid-template-columns: 350px;
		grid-auto-columns: 520px;
		grid-column-gap: 48px;
	}
`;

const Header = styled.div<{ span: number }>`
	height: auto;
	width: 100%;
	grid-column-start: 1;
	grid-column-end: span ${({ span }) => span};

	grid-row: 1;
	display: flex;
	flex-direction: column;
`;

const Title = styled.h1`
	padding: 1rem 0 0.375rem;
	font-size: 36px;
	font-weight: 600;
	color: var(--neutrals-800);
`;

const Subtitle = styled.h2`
	font-size: 24px;
	font-weight: 500;
	color: var(--neutrals-500);
`;

const Section = styled.div`
	width: 100%;
	height: auto;
	margin-bottom: 1.5rem;
	display: flex;
	flex-direction: column;
	grid-row: 2 / 3;

	& > * {
		margin-bottom: 0.75rem;
	}
`;

const SectionTitle = styled.div`
	font-size: 18px;
	font-weight: 400;
	color: var(--neutrals-400);
`;

const MenuButton = styled.button`
	display: flex;
	flex-flow: row;
	align-items: center;
	padding: 0.75rem 0.875rem 0.75rem 0.75rem;
	border-radius: 8px;
	background: var(--neutrals-050);
	border: none;
	outline: none;
	appearance: none;

	font-size: 16px;
	font-weight: 500;
	color: var(--neutrals-700);
	cursor: pointer;

	transition: background 150ms;

	&:hover {
		background: var(--neutrals-100);
	}

	& > *:first-child {
		margin-right: 8px;
	}
`;

const LogoutButton = styled(MenuButton)`
	background: var(--neutrals-050);
	color: var(--neutrals-300);
	&:hover {
		background: var(--danger-050);
		color: var(--danger-300);

		& > *:first-child {
			color: var(--danger-300);
		}
	}

	& > *:first-child {
		color: var(--neutrals-200);
	}
`;

const Spacer = styled.span`
	flex-grow: 1;
`;

const Time = styled.span`
	font: inherit;
	color: var(--neutrals-400);
`;
