const errorTranslations: {
	[key: string]: Record<string, string>;
} = {
	login: {
		'auth/invalid-email': 'Email อยู่ในรูปแบบไม่ถูกต้อง',
		'auth/user-disabled': 'บัญชีของท่านถูกระงับ',
		'auth/user-not-found': 'ไม่มีบัญชีดังกล่าวในระบบ',
		'auth/wrong-password': 'รหัสผ่านไม่ถูกต้อง',
	},
};

export default errorTranslations;
