import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import ToolsPage from 'features/tools/screen/ToolsPage';
import LoginPage from 'features/account/auth/screens/LoginPage';
import ProjectPage from 'features/project/screens/ProjectPage';
import RegisterPage from 'features/account/auth/screens/RegisterPage';
import ErrorBoundary from 'features/error/components/ErrorBoundary';
import WelcomePage from 'features/welcome/screen';

import AdminPage from 'features/admin/screens/AdminPage';
import Roles from 'features/admin/constants/roles';
import PrivateRoute from './components/private';
import LoadingRoute from './components/loading';

const Router: FC = () => {
	const { isLoggedIn } = useSelector((state: RootState) => state.user);

	return typeof isLoggedIn !== 'undefined' ? (
		<BrowserRouter>
			<ErrorBoundary>
				<Switch>
					<Route exact path="/" component={LoginPage} />
					<Route exact path="/register" component={RegisterPage} />
					<PrivateRoute exact path="/welcome">
						<WelcomePage />
					</PrivateRoute>
					<PrivateRoute
						requiredRole={[Roles.ADMIN]}
						exact
						path="/admin">
						<AdminPage />
					</PrivateRoute>
					<PrivateRoute
						requiredRole={[Roles.ADMIN]}
						path="/tools/:typeId?/:brandId?/:modelId?/:productId?">
						<ToolsPage />
					</PrivateRoute>
					<PrivateRoute path="/projects">
						<ProjectPage />
					</PrivateRoute>
				</Switch>
			</ErrorBoundary>
		</BrowserRouter>
	) : (
		<LoadingRoute />
	);
};

export default Router;
