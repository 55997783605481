import React, { Component, ChangeEvent } from 'react';

interface WrapperState {
	fields: Record<string, unknown>;
}

const withForm = (
	WrappedComponent: any,
	initialFields: Record<string, unknown>,
	onSubmit?: (...args: any[]) => void,
) => {
	class Wrapper extends Component<any, WrapperState> {
		constructor(props: any) {
			super(props);
			this.state = {
				fields: { ...initialFields },
			};
		}

		handleChange = (e: ChangeEvent<HTMLInputElement>) => {
			this.setState({
				fields: {
					...this.state.fields,
					[e.target.name]: e.target.value,
				},
			});
		};

		handleSubmit = () => {
			const { fields } = this.state;
			if (onSubmit) onSubmit(fields);
		};

		render() {
			return (
				<WrappedComponent
					fields={this.state.fields}
					handleSubmit={this.handleSubmit}
					handleChange={this.handleChange}
				/>
			);
		}
	}
	return Wrapper;
};

export default withForm;
