import React, { FC } from 'react';
import styled from 'styled-components';

import { Product } from 'features/product/types/productTypes';
import useModal from 'features/tools/hooks/useModal';
import Modal from 'features/tools/constants/modal';
import { useHistory } from 'react-router-dom';
import { Brand, Model, Specifier, Type } from '../../types/types';
import FileUploadModal from '../modal/fileUploadModal';
import NameInputModal from '../modal/nameInputModal';
import Header from './header/header';
import SpecifierList from './body/specifierList';
import ConfirmModal from '../modal/confirmModal';

type Props = {
	list: Type[] | Brand[] | Model[] | Product[];
	accessor: 'type' | 'brand' | 'model' | 'name';

	/**
	 * UI related props
	 */
	level?: string[];
	active?: string;
	width?: number | string;
	headerText?: string;
	addMoreText?: string;
	productIndexer?: boolean;
	menuShown?: boolean;
	fileUrl?: string;

	// Special prop for excelParser (file upload) to specify ID of accessors
	levelID: string[];

	/**
	 * Event handler will pass the ID of the object that
	 * the user interact with to the callback.
	 */
	onClick: (id: string) => void;
	onDelete?: () => void;
	onChangeName?: (value: string) => void;
};

const Column: FC<Props> = ({
	list,
	accessor,
	level,
	levelID,
	active,
	width,
	headerText,
	addMoreText,
	productIndexer,
	menuShown,
	onClick,
	onDelete,
	onChangeName,
}) => {
	const [activeModals, toggleModal, closeAllModals] = useModal('column');
	const history = useHistory();

	const handleSetMenu = (menuShown: boolean) => (
		e: React.MouseEvent<HTMLElement>,
	) => {
		toggleModal(Modal.OPTIONS_MENU, menuShown);
	};

	const handleDelete = (e: React.MouseEvent<any>) => {
		toggleModal(Modal.CONFIRM, true, {
			modalTitle: 'ยืนยันการลบ',
			message: `ท่านต้องการลบ "${
				level ? level[level.length - 1] : ''
			}" จริงหรือ`,
			onConfirmCallback: () => {
				if (onDelete) {
					handleSetMenu(false)(e);
					onDelete();
					history.push(`/tools/${levelID.slice(0, -1).join('/')}`);
				}
			},
		});
	};

	return (
		<Outer style={{ width }}>
			<Header
				headerText={headerText}
				accessor={accessor}
				level={level}
				menuIconShown={menuShown}
				toggleModal={toggleModal}
				closeAllModals={closeAllModals}
				activeModals={activeModals}
				onNameChangeHandler={onChangeName}
				onDeleteHandler={handleDelete}
			/>
			<SpecifierList
				list={list}
				accessor={accessor}
				activeList={active}
				addMoreText={addMoreText}
				productIndexer={productIndexer}
				onAddMoreHandler={() => toggleModal(Modal.NAME_INPUT, true)}
				onNavigateToSpecifier={onClick}
			/>
			{activeModals[Modal.FILE_UPLOAD] && (
				<FileUploadModal
					onCloseModalHandler={() =>
						toggleModal(Modal.FILE_UPLOAD, false)
					}
					fileType={activeModals[Modal.FILE_UPLOAD].fileType}
					specifierIDs={levelID}
					specifiers={level}
				/>
			)}
			{activeModals[Modal.NAME_INPUT] && (
				<NameInputModal
					onCloseModalHandler={() =>
						toggleModal(Modal.NAME_INPUT, false)
					}
					toggleModal={toggleModal}
					onNavigateToSpecifier={onClick}
					sameLevelNeighbors={list as Specifier[]}
					specifierIDs={levelID}
					specifiers={level}
					accessor={accessor}
				/>
			)}
			{activeModals[Modal.CONFIRM] && (
				<ConfirmModal
					modalTitle={activeModals[Modal.CONFIRM].modalTitle}
					message={activeModals[Modal.CONFIRM].message}
					onCloseModalHandler={() =>
						toggleModal(Modal.CONFIRM, false)
					}
					onConfirmCallback={
						activeModals[Modal.CONFIRM].onConfirmCallback
					}
				/>
			)}
		</Outer>
	);
};

export default Column;

const Outer = styled.div`
	display: flex;
	height: 100%;
	flex-direction: column;
	border-right: 1px solid var(--neutrals-100);
`;
