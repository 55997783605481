import {
	ProjectAction,
	ProjectActionTypes,
} from 'features/project/types/actionTypes';
import { ProjectRow } from 'features/project/types/projectTypes';

const setRows = (rows: ProjectRow[]): ProjectActionTypes => ({
	type: ProjectAction.SET_ROWS,
	rows,
});

export default setRows;
