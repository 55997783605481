import {
	ProjectAction,
	ProjectActionTypes,
} from 'features/project/types/actionTypes';
import { Product } from 'features/product/types/productTypes';
import { Specifiers } from 'features/project/types/projectTypes';

const insertProduct = (
	product: Product,
	index: number,
	specifiers?: Specifiers,
): ProjectActionTypes => ({
	type: ProjectAction.INSERT_PRODUCT,
	product,
	index,
	specifiers,
});

export default insertProduct;
