import firebase from 'firebase/app';
import 'firebase/firestore';

import { AdminAction } from 'features/admin/types/actionTypes';
import { AppThunk } from 'redux/reducers';

const listenToSiteSettings = (): AppThunk<() => void> => (dispatch) => {
	const detachListener = firebase
		.firestore()
		.collection('settings')
		.doc('admin')
		.onSnapshot((doc) => {
			const data = doc.data();
			dispatch({
				type: AdminAction.SET_REGISTRATIONS_ALLOWED,
				allowed: data?.allowRegistrations,
			});
		});

	return () => {
		detachListener();
	};
};

export default listenToSiteSettings;
