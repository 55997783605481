import React, { FC, useState, useRef, useCallback } from 'react';
import useOutsideClick from 'utilities/hooks/useOutsideClick';
import styled from 'styled-components';
import { Product, ProductPrice } from 'features/product/types/productTypes';
import {
	Hammer,
	Admin,
	Ellipsis,
	Bin,
	Edit,
	Close,
	Scale,
} from 'components/icons';
import Portal from 'utilities/components/portal';
import { updateProduct } from 'features/product/functions/products';
import Modal from '../constants/modal';
import useModal from '../hooks/useModal';
import ConfirmModal from './modal/confirmModal';

type Props = {
	product: Product;
	onDeleteProduct: (id: string) => void;
};

const Showcase: FC<Props> = ({ product, onDeleteProduct }) => {
	const [menu, setMenu] = useState(false);
	const [editor, setEditor] = useState(false);

	const [name, setName] = useState(product.name);
	const [unit, setUnit] = useState(product.unit);
	const [raw, setRaw] = useState(product.price.raw.toString());
	const [lab, setLab] = useState(product.price.labour.toString());

	const menuRef = useRef<HTMLDivElement>(null);
	const closeMenu = useCallback(() => setMenu(false), [setMenu]);

	const [activeModals, toggleModal] = useModal('showcase');

	useOutsideClick(menuRef!, closeMenu);

	const handleOpenEditor = () => {
		setEditor(true);
		closeMenu();
	};

	const handleEdit = () => {
		const mutation: Partial<Product> = {};
		if (name !== product.name) mutation.name = name;
		if (unit !== product.unit) mutation.unit = unit;

		const parsedRaw = parseFloat(raw);
		const parsedLabour = parseFloat(lab);

		const price: ProductPrice = { ...product.price };
		if (!isNaN(parsedRaw)) price.raw = parsedRaw;
		if (!isNaN(parsedLabour)) price.labour = parsedLabour;
		mutation.price = price;

		toggleModal(Modal.CONFIRM, true, {
			modalTitle: 'ยืนยันการเปลี่ยนชื่อ',
			message: `ท่านต้องการเปลี่ยนข้อมูล "${product.name}" จริงหรือ`,
			onConfirmCallback: () => {
				updateProduct(product.id, mutation);
				setEditor(false);
				closeMenu();
			},
		});
	};

	const handleDeleteProduct = () => {
		toggleModal(Modal.CONFIRM, true, {
			message: 'หากกดยืนยัน รายการดังกล่าวจะถูกลบ',
			onConfirmCallback: () => {
				onDeleteProduct(product.id);
			},
		});
	};

	return (
		<Outer>
			<Header>
				{product.name}
				<ActionButton ref={menuRef} onClick={() => setMenu(true)}>
					<Ellipsis size={21} color="var(--neutrals-400)" />
					{menu && (
						<ActionCallout onClick={(e) => e.stopPropagation()}>
							<ActionChoice onClick={handleDeleteProduct}>
								<Bin size={22} color="var(--neutrals-200)" />
								ลบอุปกรณ์
							</ActionChoice>
							<ActionChoice onClick={handleOpenEditor}>
								<Edit size={23} color="var(--neutrals-200)" />
								แก้ไขข้อมูล
							</ActionChoice>
						</ActionCallout>
					)}
				</ActionButton>
			</Header>
			<Container>
				<Eyebrow>{product.id}</Eyebrow>
				<Name>{product.name}</Name>
				<Spacer />
				<Border />
				<Price>
					<Label>ค่าวัสดุ</Label>
					<Number>{product.price.raw}</Number>
					<IconWrapper>
						<Hammer
							size={22}
							color="none"
							style={{ transform: 'translateY(1px)' }}
						/>
					</IconWrapper>
				</Price>
				<Border />
				<Price>
					<Label>ค่าแรงงาน</Label>
					<Number>{product.price.labour}</Number>
					<IconWrapper>
						<Admin size={21} color="none" />
					</IconWrapper>
				</Price>
				<Border />
				<Price>
					<Label>ค่าแรงงานและวัสดุรวม</Label>
					<Number>{product.price.raw + product.price.labour}</Number>
					<IconWrapper>
						<Admin size={21} color="none" />
						<SumIndicator />
						<Hammer
							size={22}
							color="none"
							style={{ transform: 'translateY(1px)' }}
						/>
					</IconWrapper>
				</Price>
				<Border />
				<Price>
					<Label>หน่วย</Label>
					<Unit>{product.unit}</Unit>
					<IconWrapper>
						<Scale size={21} color="none" />
					</IconWrapper>
				</Price>
				<Border />
			</Container>
			{editor && (
				<Portal>
					<Backdrop onClick={() => setEditor(false)}>
						<EditorModal onClick={(e) => e.stopPropagation()}>
							<EditorTitle>แก้ไขข้อมูล</EditorTitle>
							<CloseButton onClick={() => setEditor(false)}>
								<Close size={26} color="var(--neutrals-300)" />
							</CloseButton>
							<InputWrapper>
								<InputLabel>ชื่ออุปกรณ์</InputLabel>
								<InputField
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
							</InputWrapper>
							<InputWrapper>
								<InputLabel>หน่วย</InputLabel>
								<InputField
									value={unit}
									onChange={(e) => setUnit(e.target.value)}
								/>
							</InputWrapper>
							<InputWrapper>
								<InputLabel>ค่าวัสดุ</InputLabel>
								<InputField
									value={raw}
									onChange={(e) => setRaw(e.target.value)}
								/>
							</InputWrapper>
							<InputWrapper>
								<InputLabel>ค่าแรงงาน</InputLabel>
								<InputField
									value={lab}
									onChange={(e) => setLab(e.target.value)}
								/>
							</InputWrapper>
							<ButtonWrapper>
								<NeutralButton onClick={() => setEditor(false)}>
									ยกเลิก
								</NeutralButton>
								<SubmitButton onClick={() => handleEdit()}>
									แก้ไข
								</SubmitButton>
							</ButtonWrapper>
						</EditorModal>
					</Backdrop>
				</Portal>
			)}
			{activeModals[Modal.CONFIRM] && (
				<ConfirmModal
					message={activeModals[Modal.CONFIRM].message}
					onCloseModalHandler={() =>
						toggleModal(Modal.CONFIRM, false)
					}
					onConfirmCallback={
						activeModals[Modal.CONFIRM].onConfirmCallback
					}
				/>
			)}
		</Outer>
	);
};

export default Showcase;

const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	margin-top: 0.375rem;
	padding: 0 1rem;
	margin-bottom: 1rem;

	& > * {
		flex-grow: 1;
	}
`;

const SubmitButton = styled.div`
	outline: none;
	border: none;
	appearance: none;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	height: 36px;
	color: var(--primary-600);
	background: var(--primary-050);
	border-radius: 6px;
	font-size: 16px;
	font-weight: 500;
	cursor: pointer;

	&:last-child {
		margin-left: 0.5rem;
	}

	&:hover {
		background: var(--primary-100);
	}
`;

const NeutralButton = styled(SubmitButton)`
	color: var(--neutrals-600);
	background: var(--neutrals-050);

	&:hover {
		background: var(--neutrals-100);
	}
`;

const Backdrop = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: ${document.body.clientWidth}px;
	height: ${document.body.clientHeight}px;
	background: rgba(0, 0, 0, 0.6);
`;

const InputWrapper = styled.div`
	position: relative;
	width: 100%;
	padding: 0 1rem;
	background: white;
	margin-top: 0.5rem;
	display: flex;
	flex-direction: column;

	&:last-child {
		margin-bottom: 0.5rem;
	}

	&:focus-within::before {
		content: '';
		height: calc(100% - 2px);
		position: absolute;
		top: 0;
		left: 0;
		background: var(--primary-500);
		width: 3px;
	}

	&:focus-within::after {
		content: '';
		height: calc(100% - 2px);
		position: absolute;
		top: 0;
		right: 0;
		background: var(--primary-500);
		width: 3px;
	}
`;

const InputLabel = styled.label`
	font-size: 14px;
	font-weight: 500;
	color: var(--neutrals-500);
	margin-top: 0.25rem;
	margin-bottom: 0.125rem;
`;

const InputField = styled.input`
	outline: none;
	appearance: none;
	border: none;
	width: 100%;
	height: 34px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	font-size: 16px;
	font-weight: 500;
	color: var(--neutrals-700);
`;

const CloseButton = styled.div`
	outline: none;
	border: none;
	background: transparent;
	appearance: none;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
	position: absolute;
	right: 10px;
	top: 10px;
	border-radius: 100px;
	cursor: pointer;

	&:hover {
		background: rgba(0, 0, 0, 0.07);
	}

	&:active {
		background: rgba(0, 0, 0, 0.09);
	}
`;

const EditorModal = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	padding: 0;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 4px 8px rgba(0, 0, 0, 0.05),
		0 16px 32px rgba(0, 0, 0, 0.05);
	background: white;
	transform: translate(-50%, -50%);
	width: 300px;
	border-radius: 8px;
	overflow: hidden;
`;

const EditorTitle = styled.div`
	font-size: 20px;
	font-weight: 600;
	color: var(--neutrals-800);
	padding: 0.75rem 1rem;
	background: var(--neutrals-050);
	margin-bottom: 0.375rem;
`;

const Outer = styled.div`
	flex-grow: 1;
	max-width: 400px;
	display: flex;
	flex-direction: column;
	height: 100%;
	border-right: 1px solid var(--neutrals-100);
	background: white;
`;

const Header = styled.div`
	margin-bottom: 0.5rem;
	height: 36px;
	min-height: 36px;
	width: 100%;
	padding: 0 1rem;
	background: var(--neutrals-050);
	border-bottom: 1px solid var(--neutrals-100);
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 16px;
	font-weight: 600;
	color: var(--neutrals-400);
	justify-content: space-between;
	position: sticky;
	top: 0;
	z-index: 1;
`;

const ActionCallout = styled.div`
	z-index: 2;
	position: absolute;
	top: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	padding: 0.5rem 0rem;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07), 0 6px 18px rgba(0, 0, 0, 0.07);
	border-radius: 8px;
	background: white;
	width: 180px;
`;

const ActionChoice = styled.div`
	outline: none;
	appearance: none;
	border: none;
	background: transparent;
	width: 100%;
	padding: 0 1rem;
	height: 40px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	font-size: 16px;
	font-weight: 500;
	color: var(--neutrals-600);
	background: white;
	cursor: pointer;
	text-decoration: none;

	&:hover {
		background: var(--neutrals-050);
	}

	&:active {
		background: var(--neutrals-100);
	}

	& > * {
		margin-right: 1rem;
	}
`;

const Container = styled.div`
	flex-grow: 1;
	padding: 0.875rem 1rem;
`;

const Eyebrow = styled.h4`
	font-size: 16px;
	font-weight: 500;
	color: var(--neutrals-500);
	margin-bottom: 0.25rem;
`;

const Name = styled.h3`
	font-size: 20px;
	font-weight: 600;
	color: var(--neutrals-700);
`;

const Price = styled.div`
	position: relative;

	&:not(:first-of-type) {
		margin-top: 0.75rem;
	}
`;

const Spacer = styled.div`
	width: 1rem;
	height: 1rem;
`;

const Label = styled.div`
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 2px;
	color: var(--neutrals-400);
`;

const Number = styled.div`
	font-size: 24px;
	font-weight: 600;
	color: var(--neutrals-600);

	&::after {
		content: 'บาท';
		font-weight: 500;
		color: var(--neutrals-500);
		margin-left: 0.375rem;
	}
`;

const Border = styled.div`
	height: 0;
	margin-top: 0.75rem;
	border-top: 1px solid var(--neutrals-100);
`;

const IconWrapper = styled.div`
	position: absolute;
	display: flex;
	flex-direction: row;
	right: 0.625rem;
	top: 50%;
	transform: translateY(-50%);
	color: var(--neutrals-300);
`;

const SumIndicator = styled.span`
	margin: 0 0.25rem;

	&::after {
		content: '+';
	}
`;

const Unit = styled(Number)`
	display: flex;
	flex-direction: row;
	align-items: baseline;

	font-size: 20px;
	font-weight: 500;
	color: var(--neutrals-600);

	&::after {
		display: none;
	}
`;

const ActionButton = styled.div`
	outline: none;
	appearance: none;
	border: none;
	background: none;

	display: flex;
	align-items: center;
	justify-content: center;
	width: 36px;
	height: 36px;
	border-radius: 8px;

	position: absolute;
	right: 0;
	top: 0;

	cursor: pointer;

	&:hover {
		background: var(--neutrals-100);
	}
`;
