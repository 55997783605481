import range from 'utilities/functions/range';
import { SelectionAction, SelectionActionType, SelectionState } from './types';

const genesis: SelectionState = {
	previous: -1,
	list: [],
};

const selectionReducer = (
	state: SelectionState = genesis,
	action: SelectionAction,
): SelectionState => {
	switch (action.type) {
		case SelectionActionType.SINGLE: {
			const foundIndex = state.list.indexOf(action.index);
			if (foundIndex === -1) {
				return {
					previous: action.index,
					list: [action.index],
				};
			}
			return state.list.length > 1
				? {
						previous: action.index,
						list: [action.index],
				  }
				: {
						previous: -1,
						list: [],
				  };
		}
		case SelectionActionType.TOGGLE: {
			const foundIndex = state.list.indexOf(action.index);
			return foundIndex === -1
				? {
						previous: action.index,
						list: [...state.list, action.index].sort(
							(a, b) => a - b,
						),
				  }
				: {
						previous: state.previous,
						list: [
							...state.list.slice(0, foundIndex),
							...state.list.slice(foundIndex + 1),
						],
				  };
		}
		case SelectionActionType.RANGE: {
			if (state.previous === -1) {
				return {
					previous: action.to,
					list: [action.to],
				};
			}

			return {
				previous: state.previous,
				list: [
					...new Set([
						...state.list,
						...range(
							Math.min(state.previous, action.to),
							Math.max(state.previous, action.to) + 1,
						),
					]),
				].sort((a, b) => a - b),
			};
		}
		case SelectionActionType.CLEAR: {
			return {
				previous: -1,
				list: [],
			};
		}
		default:
			return state;
	}
};

export default selectionReducer;
