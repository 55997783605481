const sortByKey = <T extends { [key: string]: any }>(
	list: Array<T>,
	key: string, // Can use 'key' for normal key or 'key.subkey' or subkeys
) => {
	const keys = key.split('.');
	const sortedList = list.sort((a: T, b: T) => {
		const getElement = (n: T) =>
			keys[1] ? n[keys[0]][keys[1]] : n[keys[0]];
		const c = [getElement(a), getElement(b)];

		if (c[0] < c[1]) return -1;
		if (c[0] > c[1]) return 1;
		return 0;
	});
	return sortedList;
};

export default sortByKey;
