import { UserAccountList } from './adminTypes';

export enum AdminAction {
	SET_USER_LIST = '[ADMIN] SET_USER_LIST',
	SET_USER_LIST_LOADING = '[ADMIN] SET_USER_LIST_LOADING',
	SET_REGISTRATIONS_ALLOWED = '[ADMIN] SET_REGISTRATIONS_ALLOWED',
}

export interface SetUserList {
	type: AdminAction.SET_USER_LIST;
	userList: UserAccountList;
}

export interface SetUserListLoading {
	type: AdminAction.SET_USER_LIST_LOADING;
}

export interface SetRegistrationsAllowed {
	type: AdminAction.SET_REGISTRATIONS_ALLOWED;
	allowed: boolean;
}

export type AdminActionTypes =
	| SetUserList
	| SetUserListLoading
	| SetRegistrationsAllowed;
