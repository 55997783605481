import React, { useRef } from 'react';
import styled from 'styled-components';
import { Bin, Edit } from 'components/icons';
import { RiFileExcel2Line } from 'react-icons/ri';
import { AiOutlineFilePdf } from 'react-icons/ai';
import useOutsideClick from 'utilities/hooks/useOutsideClick';
import Modal from 'features/tools/constants/modal';

interface Props {
	accessor: 'type' | 'brand' | 'model' | 'name';
	activeModals: any;
	onOutsideClickHandler: () => void;
	onNameEditHandler: () => void;
	onDeleteHandler: (e: React.MouseEvent<HTMLElement>) => void;
	onUploadPDFHandler: () => void;
	onUploadExcelHandler: () => void;
}

const ColumnMenu: React.FC<Props> = ({
	accessor,
	activeModals,
	onOutsideClickHandler,
	onUploadPDFHandler,
	onUploadExcelHandler,
	onNameEditHandler,
	onDeleteHandler,
}) => {
	const columnMenuRef = useRef(null);
	// stopping propagation as it will hit the parent
	// a.k.a. action button in the readModeHeader.tsx file
	const handleEventBubble = (callbackFn: any) => (
		e: React.MouseEvent<HTMLElement>,
	) => {
		e.stopPropagation();
		callbackFn(e);
	};

	useOutsideClick(columnMenuRef, () => {
		if (
			!activeModals[Modal.CONFIRM] &&
			!activeModals[Modal.FILE_UPLOAD] &&
			!activeModals[Modal.NAME_INPUT] &&
			activeModals[Modal.OPTIONS_MENU]
		)
			onOutsideClickHandler();
	});

	return (
		<ActionCallout ref={columnMenuRef}>
			{accessor === 'model' || accessor === 'name' ? (
				<ActionChoice onClick={handleEventBubble(onUploadPDFHandler)}>
					<AiOutlineFilePdf size={22} color="var(--neutrals-600)" />
					อัพโหลด PDF
				</ActionChoice>
			) : null}
			{accessor === 'model' ? (
				<ActionChoice onClick={handleEventBubble(onUploadExcelHandler)}>
					<RiFileExcel2Line size={22} color="var(--neutrals-600)" />
					นำเข้า Excel
				</ActionChoice>
			) : null}
			<ActionChoice onClick={handleEventBubble(onNameEditHandler)}>
				<Edit size={23} color="var(--neutrals-200)" />
				แก้ชื่อ
			</ActionChoice>
			<ActionChoice onClick={handleEventBubble(onDeleteHandler)}>
				<Bin size={22} color="var(--neutrals-200)" />
				ลบ
			</ActionChoice>
		</ActionCallout>
	);
};

export default ColumnMenu;

const ActionCallout = styled.div`
	z-index: 2;
	position: absolute;
	top: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	padding: 0.5rem 0rem;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07), 0 6px 18px rgba(0, 0, 0, 0.07);
	border-radius: 8px;
	background: white;
	width: 180px;
`;

const ActionChoice = styled.div`
	outline: none;
	appearance: none;
	border: none;
	background: transparent;
	width: 100%;
	padding: 0 1rem;
	height: 40px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	font-size: 16px;
	font-weight: 500;
	color: var(--neutrals-600);
	background: white;
	cursor: pointer;
	text-decoration: none;

	&:hover {
		background: var(--neutrals-050);
	}

	&:active {
		background: var(--neutrals-100);
	}

	& > * {
		margin-right: 1rem;
	}
`;
