import { DiscountList } from '../types';
import computeTypeTotalOriginal from './computeTypeTotal';

const modifyModelPercent = (
	discountList: DiscountList,
	type: string,
	brand: string,
	model: string,
	percentType: 'raw' | 'labour',
	percent: number,
): DiscountList => {
	const typeIndex = discountList.findIndex(
		(typeDiscount) => typeDiscount.type === type,
	);
	const brandIndex = discountList[typeIndex].data.findIndex(
		(brandDiscount) => brandDiscount.brand === brand,
	);
	const modelIndex = discountList[typeIndex].data[
		brandIndex
	].models.findIndex((modelDiscount) => modelDiscount.model === model);
	const newDiscountList = [
		...discountList.slice(0, typeIndex),
		{
			...discountList[typeIndex],
			data: [
				...discountList[typeIndex].data.slice(0, brandIndex),
				{
					...discountList[typeIndex].data[brandIndex],
					models: [
						...discountList[typeIndex].data[
							brandIndex
						].models.slice(0, modelIndex),
						{
							...discountList[typeIndex].data[brandIndex].models[
								modelIndex
							],
							discountPercent: {
								...discountList[typeIndex].data[brandIndex]
									.models[modelIndex].discountPercent,
								[percentType]: percent,
							},
						},
						...discountList[typeIndex].data[
							brandIndex
						].models.slice(modelIndex + 1),
					],
				},
				...discountList[typeIndex].data.slice(brandIndex + 1),
			],
		},
		...discountList.slice(typeIndex + 1),
	];

	// This part computes the overall type discount percent
	const typeTotalPrice = computeTypeTotalOriginal(newDiscountList[typeIndex]);
	const toFixedNumber = (num: number, digits: number) => {
		const pow = Math.pow(10, digits);
		return Math.round(num * pow) / pow;
	};
	const { discounted, original } = typeTotalPrice;
	const percentDifference =
		((toFixedNumber(discounted[percentType], 2) -
			toFixedNumber(original[percentType], 2)) /
			toFixedNumber(original[percentType], 2)) *
		100;

	newDiscountList[typeIndex] = {
		...newDiscountList[typeIndex],
		discountPercent: {
			...newDiscountList[typeIndex].discountPercent,
			[percentType]: percentDifference.toFixed(2),
		},
	};

	return newDiscountList;
};

export default modifyModelPercent;
