import firebase from 'firebase/app';
import 'firebase/firestore';

import {
	ProjectAction,
	ProjectActionTypes,
} from 'features/project/types/actionTypes';
import { AppThunk } from 'redux/reducers';

const deleteTab = (tabId: string): AppThunk<void> => async (
	dispatch,
	getState,
) => {
	const { id, tabs } = getState().project;

	if (tabs.findIndex((t) => t.id === tabId) === -1) {
		return;
	}

	const tabRef = firebase
		.firestore()
		.collection('Project')
		.doc(id)
		.collection('Tabs')
		.doc(tabId);

	tabRef.delete();

	dispatch<ProjectActionTypes>({
		type: ProjectAction.REMOVE_TAB,
		id: tabId,
	});
};

export default deleteTab;
