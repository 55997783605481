import React, {
	useState,
	useLayoutEffect,
	ChangeEvent,
	FormEvent,
	FC,
} from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'redux/reducers';
import splashUrl from 'components/images/ant.png';

import login from 'features/account/user/actions/auth/auth.login';
import withForm from 'utilities/wrappers/withForm';

import setError from 'features/error/actions/edit/set.setError';
import Ring from 'components/animated/ring';
import RedAsterisk from 'utilities/components/redAsterisk';
import { LoginFields } from '../types/formTypes';
import { EMPTY_LOGIN_FIELDS } from '../constants/templates';
import { verifyLogin } from '../functions/form';

interface Props {
	fields: LoginFields;
	handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const LoginPage: FC<Props> = ({ fields, handleChange }) => {
	const [isSubmitting, setIsSubmitting] = useState(false);

	const { isLoggedIn } = useSelector((state: RootState) => state.user);

	const dispatch = useDispatch();
	const history = useHistory();

	const handleLogin = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (isSubmitting) return;
		try {
			setIsSubmitting(true);
			await verifyLogin(fields);
			dispatch(login(fields));
		} catch (err) {
			dispatch(
				setError(err.message || err, {
					returnHome: false,
				}),
			);
			setIsSubmitting(false);
		}
	};

	const handleRegister = () => {
		history.push('/register');
	};

	useLayoutEffect(() => {
		if (isLoggedIn) {
			history.replace('/welcome');
		}
	}, [history, isLoggedIn]);

	return (
		<Container>
			<Splash />
			<Form id="login" onSubmit={handleLogin}>
				<Title>เข้าสู่ระบบ</Title>
				<Label>
					อีเมล
					<RedAsterisk />
				</Label>
				<Input
					name="email"
					value={fields.email}
					type="email"
					onChange={handleChange}
				/>
				<Label>
					รหัสผ่าน
					<RedAsterisk />
				</Label>
				<Input
					name="password"
					value={fields.password}
					type="password"
					onChange={handleChange}
				/>
				<Button type="submit" isDisabled={isSubmitting}>
					{isSubmitting ? (
						<>
							<Ring color="var(--neutrals-400)" />
						</>
					) : (
						<span>เข้าสู่ระบบ</span>
					)}
				</Button>
				<RegisterText onClick={handleRegister}>
					ยังไม่มีบัญชี? กดเพื่อสมัคร
				</RegisterText>
			</Form>
		</Container>
	);
};

export default withForm(LoginPage, { ...EMPTY_LOGIN_FIELDS });

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	height: 100vh;
	width: 100%;
	background: var(--neutrals-800);
`;

const Splash = styled.div`
	width: 360px;
	height: 360px;
	background: transparent url(${splashUrl}) no-repeat center bottom;
	background-size: contain;

	transform: translateY(-30px);

	@media (min-width: 1024px) {
		transform: translateY(-50px);
	}
`;

const Form = styled.form`
	display: flex;
	flex-flow: column;
	align-items: stretch;
	width: 480px;
	height: auto;
	padding: 2rem;
	background: white;
	border-radius: 8px;

	transform: translateY(-30px);

	@media (min-width: 1024px) {
		transform: translateY(-50px);
	}
`;

/*
const Brand = styled.h1`
	font-size: 24px;
	font-weight: 600;
	color: var(--neutrals-800);
	margin-bottom: 1.5rem;
`; */

const Title = styled.h2`
	font-size: 18px;
	font-weight: 500;
	color: var(--neutrals-500);
	margin-bottom: 0.5rem;
`;

const Input = styled.input`
	height: 48px;
	font-size: 16px;
	font-weight: 400;
	color: var(--neutrals-800);
	margin-bottom: 10px;
	background: var(--neutrals-050);
	padding: 0.75rem 0.875rem;
	display: flex;
	align-items: center;
	flex-direction: row;
	outline: none;
	border: none;
	border-radius: 8px;
`;

const Label = styled.label`
	font-size: 14px;
	color: var(--neutrals-500);
	margin: 0.5rem 0;
`;

const Button = styled.button<{ isDisabled?: boolean }>`
	outline: none;
	border: none;
	font-size: 1rem;
	font-weight: 600;
	color: white;
	background: ${(props) =>
		props.isDisabled ? 'var(--neutrals-100)' : 'var(--primary-800)'};
	appearance: none;
	transition: all 150ms;
	border-radius: 6.2rem;
	min-height: 3rem;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 1.5rem;
	margin-bottom: 2rem;

	transform: scale(1);

	& > * {
		transform: ${(props) => (props.isDisabled ? 'scale(0.4)' : 'none')};
		margin-bottom: ${(props) => (props.isDisabled ? '1.95rem' : 'none')};
	}

	&:hover {
		transform: ${(props) => (props.isDisabled ? 'none' : 'scale(1.03)')};
		cursor: ${(props) => (props.isDisabled ? 'default' : 'pointer')};
	}

	&:active {
		transform: scale(1);
	}
`;

const RegisterText = styled.div`
	margin-top: 1.2rem;
	text-decoration: underline;
	color: var(--neutrals-300);
	text-align: center;

	&:hover {
		cursor: pointer;
		color: var(--neutrals-400);
	}
`;
