import { UserProject } from 'features/account/user/types/userTypes';
import Roles from 'features/admin/constants/roles';

export enum UserAction {
	LOGIN = '[USER] LOGIN',
	LOGOUT = '[USER] LOGOUT',
	SET_ROLE = '[USER] SET_ROLE',
	SET_PROJECTS = '[USER] SET_PROJECTS',
	SET_ACCOUNT_INFO = '[USER] SET_ACCOUNT_INFO',
}

interface Login {
	type: UserAction.LOGIN;
}

interface Logout {
	type: UserAction.LOGOUT;
}

interface SetRole {
	type: UserAction.SET_ROLE;
	role: Roles;
}

interface SetAccountInfo {
	type: UserAction.SET_ACCOUNT_INFO;
	uid: string;
	company?: string;
}
interface SetProjects {
	type: UserAction.SET_PROJECTS;
	projects: Array<UserProject>;
}

export type UserActionTypes =
	| Login
	| Logout
	| SetRole
	| SetProjects
	| SetAccountInfo;
