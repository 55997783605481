import { ProjectInfo } from 'features/project/types/projectTypes';
import { ProjectAction } from 'features/project/types/actionTypes';
import React, { FC, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from 'redux/reducers';
import Compact from './compact';
import Expanded from './expanded';

import selectInfo from './select';

interface Props {
	expanded: boolean;
	toggleExpanded: () => void;
}

const Info: FC<Props> = ({ expanded, toggleExpanded }) => {
	const info = useSelector(selectInfo);
	const projectId = useSelector((state: RootState) => state.project.id);
	const userProjects = useSelector((state: RootState) => state.user.projects);
	const dispatch = useDispatch();

	const isProjectOwner = userProjects.find(
		(project) => project.info.id === projectId,
	);

	const changeInfo = (info: ProjectInfo) => {
		dispatch({
			type: ProjectAction.SET_INFO,
			info,
		});
	};

	return (
		<Fragment>
			<Compact info={info} toggled={expanded} onClick={toggleExpanded} />
			{expanded && (
				<Expanded
					info={info}
					onChange={changeInfo}
					readOnly={!isProjectOwner}
				/>
			)}
		</Fragment>
	);
};

export default Info;
