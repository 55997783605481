import React from 'react';
import styled from 'styled-components';

import Modal from './modal';
import ModalHeader from './modalHeader';

interface Props<T, F> {
	form: React.FC<{
		onSubmitCallback: (fields: F) => void;
		submitBtnText: string;
		uid?: string;
		originalFields?: T;
		readOnlyMode?: boolean;
	}>;
	headingText: string;
	submitBtnText: string;
	uid?: string;
	readOnlyMode?: boolean;
	originalFields?: any;
	onSubmitCallback?: (fields: F) => void;
	onCloseCallback: () => void;
}

const GenericModal = <T, F>({
	form: Form,
	headingText,
	submitBtnText,
	readOnlyMode,
	uid,
	originalFields,
	onSubmitCallback,
	onCloseCallback,
}: Props<T, F>) => {
	const handleSubmit = (fields: F) => {
		if (onSubmitCallback) {
			onSubmitCallback(fields);
			onCloseCallback();
		}
	};

	return (
		<Modal onCloseHandler={onCloseCallback}>
			<Container>
				<ModalHeader
					headingText={headingText}
					onCloseHandler={() => onCloseCallback()}
				/>
				<Form
					onSubmitCallback={handleSubmit}
					submitBtnText={submitBtnText}
					originalFields={originalFields}
					readOnlyMode={readOnlyMode}
					uid={uid}
				/>
			</Container>
		</Modal>
	);
};

const Container = styled.div`
	background: var(--neutrals-100);
	color: var(--neutrals-800);
	height: 83vh;
	width: 70vh;
	border-radius: 0.4rem;

	resize: both;
	overflow: auto;

	display: flex;
	flex-flow: column;

	& > *:first-child {
		height: 10%;
	}

	& > *:nth-child(2) {
		height: 90%;
	}

	@media (max-width: 768px) {
		height: 70vh;
		width: 95vw;
	}
`;

export default GenericModal;
