import firebase from 'firebase/app';
import 'firebase/firestore';
import { AppThunk } from 'redux/reducers';

const setSiteSettings = (params: {
	allowRegistrations: boolean;
}): AppThunk<Promise<void>> => () => {
	return firebase.firestore().collection('settings').doc('admin').update({
		allowRegistrations: params.allowRegistrations,
	});
};

export default setSiteSettings;
