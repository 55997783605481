import React, { FC, useMemo } from 'react';
import common_th from 'translations/th/common.json';
import computeTypeTotal from 'features/discount/functions/computeTypeTotal';
import { TypeDiscountItem } from 'features/discount/types';

import Row from 'features/discount/components/body/row';
import Cell from 'features/discount/components/body/cell';
import table from 'features/discount/constants/table';

interface Props {
	discount: TypeDiscountItem;
	onPercentChange: (
		value: string,
		percentType: 'raw' | 'labour',
		type: string,
	) => void;
}

const TypeRow: FC<Props> = ({ discount, onPercentChange }) => {
	const total = useMemo(() => computeTypeTotal(discount), [discount]);

	return (
		<Row style={{ fontWeight: 600 }}>
			<Cell
				value=""
				editable={false}
				width={table.columns[0].width}
				textAlign={table.columns[0].textAlign}
			/>
			<Cell
				editable={false}
				width={table.columns[1].width}
				textAlign={table.columns[1].textAlign}
				value={`${common_th.type}${discount.type}`}
			/>
			<Cell
				value=""
				editable={false}
				width={table.columns[2].width}
				textAlign={table.columns[2].textAlign}
			/>
			<Cell
				editable={false}
				width={table.columns[3].width}
				textAlign={table.columns[3].textAlign}
				value={total.original.raw.toFixed(2)}
			/>
			<Cell
				editable={false}
				width={table.columns[4].width}
				textAlign={table.columns[4].textAlign}
				value={total.original.labour.toFixed(2)}
			/>
			<Cell
				editable={false}
				width={table.columns[5].width}
				textAlign={table.columns[5].textAlign}
				value={total.original.total.toFixed(2)}
			/>
			<Cell
				editable={true}
				width={table.columns[6].width}
				textAlign={table.columns[6].textAlign}
				value={discount.discountPercent.raw.toString()}
				onChange={(s) => onPercentChange(s, 'raw', discount.type)}
			/>
			<Cell
				editable={true}
				width={table.columns[7].width}
				textAlign={table.columns[7].textAlign}
				value={discount.discountPercent.labour.toString()}
				onChange={(s) => onPercentChange(s, 'labour', discount.type)}
			/>
			<Cell
				editable={false}
				width={table.columns[8].width}
				textAlign={table.columns[8].textAlign}
				value={total.discounted.raw.toFixed(2)}
			/>
			<Cell
				editable={false}
				width={table.columns[9].width}
				textAlign={table.columns[9].textAlign}
				value={total.discounted.labour.toFixed(2)}
			/>
			<Cell
				editable={false}
				width={table.columns[10].width}
				textAlign={table.columns[10].textAlign}
				value={total.discounted.total.toFixed(2)}
			/>
		</Row>
	);
};

export default TypeRow;
