import { RootState } from 'redux/reducers';
import { createSelector } from 'reselect';
import { Query } from 'utilities/hooks/useQuery';
import { ProjectRow } from '../types/projectTypes';
import { selectRows } from './table';

export type Sum = {
	raw: number;
	labour: number;
	both: number;
};

export const selectSum = createSelector<
	RootState,
	Query,
	ProjectRow[],
	number[],
	Sum
>(
	selectRows,
	(s) => s.selection.list,
	(r, s) => {
		const t: ProjectRow[] = [];
		s.forEach((index) => t.push(r[index]));

		const raw = t
			.map((x) => x.price.raw * x.qty)
			.reduce((a, b) => a + b, 0);

		const labour = t
			.map((x) => x.price.labour * x.qty)
			.reduce((a, b) => a + b, 0);

		const both = raw + labour;

		return {
			raw,
			labour,
			both,
		};
	},
);

export type PriceSelectorProps = {
	list: number[];
	query: Query;
};

export const makePriceSelector = () =>
	createSelector<RootState, PriceSelectorProps, ProjectRow[], Sum>(
		(s, p) => {
			const i = p.list;
			const t: ProjectRow[] = [];
			const rows = selectRows(s, p.query);
			i.forEach((x) => rows[x] && t.push(rows[x]));
			return t;
		},
		(t) => {
			if (!t.length) {
				return {
					raw: 0,
					labour: 0,
					both: 0,
				};
			}

			const raw = t
				.map((x) => x.price.raw * x.qty)
				.reduce((a, b) => a + b);

			const labour = t
				.map((x) => x.price.labour * x.qty)
				.reduce((a, b) => a + b);

			const both = raw + labour;

			return {
				raw,
				labour,
				both,
			};
		},
	);
