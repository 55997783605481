import React, { FC } from 'react';
import styled from 'styled-components';
import Portal from 'utilities/components/portal';
import useQuery from 'utilities/hooks/useQuery';
import DragIndicator from 'components/icons/dragIndicator';
import { Cross } from 'components/icons';
import { useSelector, useDispatch } from 'redux/reducers';
import { dragSum } from 'features/dragger/actions';
import { selectSum } from 'features/project/selectors/sum';
import { selectSelectionLength } from 'features/selection/selector';
import batchDelete from '../actions/batch/delete';

function getDisplay(x: number): string {
	return (Math.round(x / 0.01) * 0.01).toString();
}

const Palette: FC = () => {
	const query = useQuery();
	const dispatch = useDispatch();
	const length = useSelector(selectSelectionLength);
	const sum = useSelector((s) => selectSum(s, query));

	const handleDelete = () => dispatch(batchDelete());
	const handleDrag = () => dispatch(dragSum());

	return (
		<Portal>
			<Container active={Boolean(length)}>
				<Action onClick={handleDelete}>
					<Cross size={24} color="var(--neutrals-200)" />
					ลบ {length} บรรทัดที่เลือก
				</Action>
				<Text>— หรือ —</Text>
				<DraggersWrapper draggable onDragStart={handleDrag}>
					<Handler>
						<DragIndicator size={22} color="var(--neutrals-700)" />
					</Handler>
					<Dragger>
						<Label>วัสดุ</Label>
						{getDisplay(sum.raw)}
					</Dragger>
					<Dragger>
						<Label>ร.ง.</Label>
						{getDisplay(sum.labour)}
					</Dragger>
					<Dragger>
						<Label>รวม</Label>
						{getDisplay(sum.both)}
					</Dragger>
				</DraggersWrapper>
			</Container>
		</Portal>
	);
};

export default React.memo(Palette);

const Container = styled.div<{ active: boolean }>`
	width: auto;
	height: 4.5rem;
	background: white;
	position: fixed;
	bottom: 3.5rem;
	left: 50%;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0 0.875rem 0 1.375rem;
	transition: all 300ms ease;
	border-radius: 8px;
	transform: translateX(-50%)
		translateY(${({ active }) => (active ? '0' : '8rem')});
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04), 0 4px 8px rgba(0, 0, 0, 0.04),
		0 8px 16px rgba(0, 0, 0, 0.04);
	overflow: hidden;
`;

const Text = styled.div`
	font-size: 16px;
	font-weight: 500;
	color: var(--neutrals-400);
	margin: 0 1.25rem 0 1rem;
`;

const Action = styled.button`
	font-size: 16px;
	outline: none;
	appearance: none;
	border: none;
	background: var(--neutrals-050);
	padding: 0.75rem 0.875rem 0.75rem 0.625rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	width: auto;
	border-radius: 100px;
	height: 40px;
	color: var(--neutrals-700);
	cursor: pointer;

	&:not(:first-child) {
		margin-left: 0.25rem;
	}

	& > *:first-child {
		margin-right: 0.5rem;
	}

	&:hover {
		background: var(--neutrals-100);
	}
`;

const DraggersWrapper = styled.div`
	display: grid;
	grid-template-columns: auto repeat(3, 1fr);
	height: 48px;
	background: white;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08), 0 4px 12px rgba(0, 0, 0, 0.08);
	border-radius: 4px 4px 0 0;
	box-sizing: border-box;
	cursor: grab;
`;

const Handler = styled.div`
	height: 3rem;
	display: flex;
	flex-direction: center;
	align-items: center;
	width: 24px;
	background: var(--neutrals-050);

	& > * {
		transform: rotate(90deg);
	}
`;

const Dragger = styled.div`
	height: 3rem;
	font-size: 16px;
	padding: 0 0.75rem 0 0.5rem;
	background: transparent;
	color: var(--neutrals-800);
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	cursor: grab;
	border-left: 1px solid var(--neutrals-100);
`;

const Label = styled.label`
	font-size: 14px;
	color: var(--neutrals-500);
	cursor: grab;
`;
