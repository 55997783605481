import { AppThunk } from 'redux/reducers';
import { RegisterFields } from 'features/account/auth/types/formTypes';
import { getDataFromRequest } from 'services/http/functions/getDataFromRequest';
import { ENDPOINT } from 'services/http/constants/endpoints';
import { HTTPMethods } from 'services/http/types/httpTypes';

const register = (fields: RegisterFields): AppThunk<Promise<void>> => async (
	dispatch,
) => {
	const { email, password, displayName, phoneNumber, company } = fields;

	await dispatch(
		getDataFromRequest(
			ENDPOINT.FUNCTIONS_API.REGISTER_USER,
			HTTPMethods.POST,
			null,
			{
				email,
				password,
				displayName,
				phoneNumber,
				company,
			},
		),
	);
};

export default register;
