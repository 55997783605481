import firebase from 'firebase/app';
import 'firebase/firestore';

import setError from 'features/error/actions/edit/set.setError';
import { AppThunk } from 'redux/reducers';

const addSpecifierOrProduct = (params: {
	name: string; // new specfiiers' name
	id: string; // new specifiers' ID
	specifiers: Array<string>; // path leading to specifier
}): AppThunk<Promise<void>> => async (dispatch) => {
	try {
		const { name, id, specifiers } = params;

		if (specifiers.length < 3 && id.length !== 2) {
			dispatch(
				setError('id ต้องมีขนาด 2 ตัวอักษร', { returnHome: false }),
			);
			return;
		}

		if (specifiers.length === 3 && id.length !== 3) {
			dispatch(
				setError('id ต้องมีขนาด 3 ตัวอักษร', { returnHome: false }),
			);
			return;
		}

		const productsRef = firebase.firestore().collection('products');

		switch (specifiers.length) {
			case 0:
				await productsRef.doc(id).set({
					id,
					type: name,
					isRemoved: false,
				});
				break;
			case 1:
				await productsRef
					.doc(specifiers[0])
					.collection('children')
					.doc(id)
					.set({
						id,
						brand: name,
						file: '',
						isRemoved: false,
					});
				break;
			case 2:
				await productsRef
					.doc(specifiers[0])
					.collection(`children/${specifiers[1]}/children`)
					.doc(id)
					.set({
						id,
						model: name,
						isRemoved: false,
					});
				break;
			case 3:
				await productsRef
					.doc(specifiers[0])
					.collection(
						`children/${specifiers[1]}/children/${specifiers[2]}/children`,
					)
					.doc(id)
					.set({
						id: specifiers.join('') + id,
						name,
						unit: '',
						price: {
							raw: 0,
							labour: 0,
							total: 0,
						},
						isRemoved: false,
					});
				break;
		}
	} catch (err) {
		dispatch(setError(err, { returnHome: false }));
	}
};

export default addSpecifierOrProduct;
