import React, { FC } from 'react';
import styled from 'styled-components';

import table from '../../constants/table';
import Cell from './cell';

const HeaderRow: FC = () => {
	return (
		<Container>
			{table.columns.map((c) => (
				<Cell key={c.accessor} width={c.width}>
					{c.header}
				</Cell>
			))}
		</Container>
	);
};

export default HeaderRow;

const Container = styled.div`
	display: inline-flex;
	flex-flow: row nowrap;
	align-items: stretch;
	justify-content: flex-start;

	position: relative;
	z-index: 0;
	width: min-content;

	height: 29px;
	width: auto;
	border-left: 1px solid var(--neutrals-100);

	@media screen and (max-width: 1700px) {
		&::after {
			content: '';
			display: block;
			min-width: 2rem;
			width: 2rem;
		}
	}
`;
