/* eslint-disable no-nested-ternary */
import {
	Modal,
	ModalConfig,
	ModalController,
} from 'features/admin/types/modal';
import React from 'react';

import GenericModal from './genericModal';

interface Props<T, F> {
	controller: ModalController;
	form: React.FC<{
		onSubmitCallback: (fields: F) => void;
		submitBtnText: string;
		originalFields?: T;
		readOnlyMode?: boolean;
	}>;
	indexer: string;
	modalConfig: ModalConfig;
	onAddCallback?: (fields: F) => void;
	onUpdateCallback: (id: string, fields: F) => void;
	onClear: () => void;
}

const ModalDisplayAdapter = <T, F>({
	controller,
	form,
	modalConfig,
	indexer = 'id',
	onAddCallback,
	onUpdateCallback,
	onClear,
}: Props<T, F>) => {
	if (controller.modal === Modal.EMPTY) return null;

	return (
		<GenericModal
			form={form}
			headingText={modalConfig[controller.modal].headingText}
			submitBtnText={modalConfig[controller.modal].submitBtnText || ''}
			originalFields={
				controller.modal === Modal.VIEW ||
				controller.modal === Modal.UPDATE
					? controller.params.user
					: undefined
			}
			uid={controller.params?.uid}
			onSubmitCallback={
				controller.modal === Modal.ADD
					? onAddCallback
					: controller.modal === Modal.UPDATE
					? (fields: F) =>
							onUpdateCallback(controller.params[indexer], fields)
					: undefined
			}
			onCloseCallback={onClear}
			readOnlyMode={controller.modal === Modal.VIEW}
		/>
	);
};

export default ModalDisplayAdapter;
