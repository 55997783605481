import firebase from 'firebase/app';

/**
 * Get a PDF url associated with the given specifiers
 * @param {[string, string]} specifiers
 * @return {Promise<string>}
 */
const getUrl = async ([type, brand, model]: [string, string, string]): Promise<
	string
> => {
	let docRef = firebase
		.firestore()
		.collection(`products/${type}/children`)
		.doc(brand);

	if (model) docRef = docRef.collection('children').doc(model);

	const doc = await docRef.get();

	if (!doc.exists) {
		return '';
	}

	const url = doc.data()?.file || '';

	return url;
};

export default getUrl;
