import React from 'react';
import styled from 'styled-components';
import Switch from 'react-switch';
import { BsFillPeopleFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'redux/reducers';
import setSiteSettings from 'features/admin/actions/site/site.setSiteSettings';

const AdminInfoBar = () => {
	const { allowRegistrations } = useSelector(
		(state: RootState) => state.admin.siteSettings,
	);
	const dispatch = useDispatch<AppDispatch>();

	const handleAllowRegistrationsChange = () => {
		dispatch(
			setSiteSettings({
				allowRegistrations: !allowRegistrations,
			}),
		);
	};

	return (
		<Container>
			<Block>
				<Heading>
					<BsFillPeopleFill />
					<span>ระบบแอดมิน</span>
				</Heading>
			</Block>
			<Block>
				<span>สถานะการเปิดรับสมัคร</span>
				<Switch
					onChange={handleAllowRegistrationsChange}
					checked={allowRegistrations}
				/>
			</Block>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 0 1rem;

	width: 100%;

	background: var(--neutrals-800);
	box-shadow: inset 0 7px 9px -7px rgba(0, 0, 0, 0.2);
`;

const Block = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	min-height: max-content;

	width: auto;

	&:last-child {
		& > *:first-child {
			margin-right: 1rem;
			color: var(--neutrals-050);
		}
	}
`;

const Heading = styled.h1`
	font-size: 1.125rem;
	font-weight: 600;
	display: flex;
	align-items: center;
	color: var(--neutrals-050);

	& > *:first-child {
		margin-right: 1rem;
		height: 1.5rem;
		width: 1.5rem;
	}
`;

export default AdminInfoBar;
