import React from 'react';
import styled, { keyframes } from 'styled-components';

const Ring: React.FC<{ color: string }> = ({ color }) => {
	return (
		<RingContainer color={color}>
			<div />
			<div />
			<div />
			<div />
		</RingContainer>
	);
};

const RingAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

const RingContainer = styled.div<{ color: string }>`
	display: inline-block;
	position: relative;
	width: 80px;
	width: 80px;

	div {
		box-sizing: border-box;
		display: block;
		position: absolute;
		width: 64px;
		height: 64px;
		margin: 8px;
		border: 8px solid ${(props) => props.color};
		border-radius: 50%;
		animation: ${RingAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		border-color: ${(props) => props.color} transparent transparent
			transparent;
	}

	div:nth-child(1) {
		animation-delay: -0.45s;
	}
	div:nth-child(2) {
		animation-delay: -0.3s;
	}
	div:nth-child(3) {
		animation-delay: -0.15s;
	}
`;

export default Ring;
