import React from 'react';
import styled from 'styled-components';
import Modal from '.';

interface Props {
	message: string;
	modalTitle?: string;
	onCloseModalHandler: () => void;
	onConfirmCallback: () => void;
}

const ConfirmModal: React.FC<Props> = ({
	message,
	modalTitle = 'ทำการยืนยัน',
	onCloseModalHandler,
	onConfirmCallback,
}) => {
	const handleConfirm = () => {
		onConfirmCallback();
		onCloseModalHandler();
	};

	return (
		<Modal
			modalTitle={modalTitle}
			onCloseModalHandler={onCloseModalHandler}>
			<SectionData>{message}</SectionData>
			<ButtonGroup>
				<PrimaryButton onClick={onCloseModalHandler}>
					ยกเลิก
				</PrimaryButton>
				<SecondaryButton onClick={handleConfirm}>ตกลง</SecondaryButton>
			</ButtonGroup>
		</Modal>
	);
};

export default ConfirmModal;

const ButtonGroup = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;

	& > * {
		width: 40%;
	}
`;

const PrimaryButton = styled.div`
	outline: none;
	border: none;
	appearance: none;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	height: 36px;
	color: var(--neutrals-050);
	background: var(--danger-400);
	border-radius: 6px;
	font-size: 1rem;
	font-weight: 500;
	margin: 1rem;
	margin-top: 0;
	cursor: pointer;

	&:hover {
		background: var(--danger-300);
	}
`;

const SecondaryButton = styled(PrimaryButton)`
	color: var(--danger-300);
	background: none;
	border: 2.5px solid var(--danger-100);
	margin-left: 0.5rem;

	&:hover {
		border: none;
		background: var(--danger-400);
		color: var(--neutrals-050);
	}
`;

const SectionData = styled.div`
	font-size: 1rem;
	font-weight: 500;
	color: var(--neutrals-700);
	display: flex;
	justify-content: center;
	margin: 0.5rem 0 1.5rem 1rem;
`;
