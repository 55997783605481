import firebase from 'firebase/app';
import 'firebase/firestore';

import Roles from 'features/admin/constants/roles';
import setError from 'features/error/actions/edit/set.setError';
import { AppThunk } from 'redux/reducers';
import { UserProject } from 'features/account/user/types/userTypes';

const getUserProjects = (
	uid: string,
): AppThunk<Promise<Array<UserProject>>> => async (dispatch, getState) => {
	const isAdmin = getState().user.role === Roles.ADMIN;
	if (isAdmin) {
		try {
			const userRef = firebase.firestore().collection('User').doc(uid);
			const userProjects = (await userRef.get())?.data()?.projects;
			return userProjects;
		} catch (err) {
			dispatch(setError('เกิดข้อผิดพลาด', { returnHome: false }));
		}
	} else {
		dispatch(setError('คุณไม่มีสิทธิ์เพียงพอ'));
	}
	return [];
};

export default getUserProjects;
