import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { RootState } from 'redux/reducers';

import { ColumnName, TableAction } from '../../types/tableTypes';

interface Props {
	width: number;
	dragId: ColumnName;
	dragging: boolean;
	onDraggingInit: (value: number, min: number) => void;
	onDraggingMove: (value: number) => void;
	onDraggingEnd: (value: number) => void;
	[prop: string]: any;
}

const Cell: React.FunctionComponent<Props> = ({
	dragId,
	width,
	dragging,
	onDraggingInit,
	onDraggingMove,
	onDraggingEnd,
	children,
	...delegated
}) => {
	const wrapper = React.useRef<HTMLDivElement>(null);
	const x = React.useRef<number>(0);

	const onMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
		const { width } = wrapper.current?.getBoundingClientRect()!;
		onDraggingInit(e.pageX, e.pageX - width + 50);
		x.current = e.pageX;
	};

	React.useEffect(() => {
		if (dragging) {
			const onMouseMove = (e: MouseEvent) => {
				onDraggingMove(e.pageX - x.current);
				x.current = e.pageX;
			};

			const onMouseUp = (e: MouseEvent) => {
				onDraggingEnd(e.pageX - x.current);
			};

			window.addEventListener('mousemove', onMouseMove);
			window.addEventListener('mouseup', onMouseUp);

			return () => {
				window.removeEventListener('mousemove', onMouseMove);
				window.removeEventListener('mouseup', onMouseUp);
			};
		}
		return undefined;
	}, [dragging, onDraggingMove, onDraggingEnd]);

	return (
		<Wrapper $width={width} ref={wrapper} {...delegated}>
			{children}
			<Draggable onMouseDown={onMouseDown} />
		</Wrapper>
	);
};

const mapState = (state: RootState, { dragId }: { dragId: ColumnName }) => {
	return {
		dragging: state.table.dragging.id === dragId,
	};
};

const mapDispatch = (
	dispatch: (action: TableAction) => void,
	{ dragId }: { dragId: ColumnName },
) => ({
	onDraggingInit: (value: number, min: number) =>
		dispatch({ type: 'table.dragging.init', id: dragId, value, min }),
	onDraggingMove: (value: number) =>
		dispatch({ type: 'table.dragging.update', value }),
	onDraggingEnd: (value: number) =>
		dispatch({ type: 'table.dragging.end', value }),
});

export default connect(mapState, mapDispatch)(Cell);

const Wrapper = styled.div<{ $width: number }>`
	flex: 0 0 auto;

	display: inline-flex;
	align-items: center;
	justify-content: center;

	position: relative;
	width: ${(props) => props.$width}px;
	min-width: 0;
	padding: 0.25rem 0.5rem;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;

	font-size: 1em;
	border-right: 1px solid var(--neutrals-100);
	color: var(--neutrals-900);
`;

const Draggable = styled.div`
	flex: 0 0 auto;

	position: absolute;
	top: 0;
	right: 0;
	width: 5px;
	height: 100%;
	background: transparent;

	cursor: col-resize;

	&::after {
		content: '';
		z-index: 1;
		position: absolute;
		right: 0px;

		display: block;
		min-width: 5px;
		height: 100%;
		background: var(--primary-400);
		transition: opacity 100ms ease;
		opacity: 0;
	}

	&:hover::after,
	&:active::after {
		opacity: 1;
	}
`;
