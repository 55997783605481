// @ts-nocheck

import {
	DroppableBorder,
	DroppableRow,
} from 'features/project/components/body';

import { store } from 'App';
import {
	DraggerAction,
	DraggerActionType,
	DraggerState,
	DraggerType,
} from './type';

let current: DraggerState;
let unsubscribe: () => void;

/**
 * We have to do it this way because it faster and our app has hundreds of
 * rows that is constantly adding and removing DOM event listener (up to 20-30
 * of these operation per second) which can cause zombie state. For example, a
 * dropable border might stuck in a wrong state or a row that is colored while
 * the drag is not above them. The solution of this is the dragger reducer and
 * this Redux subscriber function that minimize the amount of these operation to one
 * per dragger state change. The other benefits from this method is that we don't
 * have to transfer the data as JSON in event object which need to be serialized as text
 * and it is definitely slower that this method which uses Redux store to store object.
 */
const listenToDragger = () => {
	current = store.getState().dragger;

	window.addEventListener('dragend', () =>
		store.dispatch<DraggerAction>({
			type: DraggerActionType.DROP,
		}),
	);

	return store.subscribe(() => {
		const previous = current;
		current = store.getState().dragger;

		if (previous !== current) {
			unsubscribe && unsubscribe();

			switch (current.type) {
				case DraggerType.PRODUCT: {
					const elements = document.querySelectorAll<HTMLDivElement>(
						DroppableRow.toString(),
					);

					if (elements) {
						elements.forEach((item) => {
							item.addEventListener('dragenter', updateRowStyle);
							item.addEventListener('dragleave', revertRowStyle);
							item.addEventListener('drop', revertRowStyle);
						});
						unsubscribe = () =>
							elements.forEach((item) => {
								item.removeEventListener(
									'dragenter',
									updateRowStyle,
								);
								item.removeEventListener(
									'dragleave',
									revertRowStyle,
								);
								item.removeEventListener(
									'drop',
									revertRowStyle,
								);
							});
					}

					break;
				}
				case DraggerType.SUM: {
					const elements = document.querySelectorAll<HTMLDivElement>(
						DroppableRow.toString(),
					);

					if (elements) {
						elements.forEach((item) => {
							item.addEventListener('dragenter', updateRowStyle);
							item.addEventListener('dragleave', revertRowStyle);
							item.addEventListener('drop', revertRowStyle);
						});
						unsubscribe = () =>
							elements.forEach((item) => {
								item.removeEventListener(
									'dragenter',
									updateRowStyle,
								);
								item.removeEventListener(
									'dragleave',
									revertRowStyle,
								);
								item.removeEventListener(
									'drop',
									revertRowStyle,
								);
							});
					}

					break;
				}
				case DraggerType.ROW: {
					const elements = document.querySelectorAll<HTMLDivElement>(
						DroppableBorder.toString(),
					);

					if (elements) {
						elements.forEach((item) => {
							item.addEventListener(
								'dragenter',
								updateBorderStyle,
							);
							item.addEventListener(
								'dragleave',
								revertBorderStyle,
							);
							item.addEventListener('drop', revertBorderStyle);
						});
						unsubscribe = () =>
							elements.forEach((item) => {
								item.removeEventListener(
									'dragenter',
									updateBorderStyle,
								);
								item.removeEventListener(
									'dragleave',
									revertBorderStyle,
								);
								item.removeEventListener(
									'drop',
									revertBorderStyle,
								);
							});
					}

					break;
				}
			}
		}
	});
};

export default listenToDragger;

const counter = new Map<HTMLDivElement, number>();

function updateRowStyle(e: DragEvent) {
	const el = (e.target as unknown) as Element;
	const targetEl = el.closest(DroppableRow.toString()) as HTMLDivElement;
	const count = counter.get(targetEl);
	if (count) {
		counter.set(targetEl, count + 1);
	} else {
		counter.set(targetEl, 1);
		targetEl.style.background = 'var(--accent-050)';
	}
}

function revertRowStyle(e: DragEvent) {
	const el = (e.target as unknown) as Element;
	const targetEl = el.closest(DroppableRow.toString()) as HTMLDivElement;
	const count = counter.get(targetEl) as number;
	if (count === 1) {
		counter.set(targetEl, 0);
		targetEl.style.background = 'white';
	} else {
		counter.set(targetEl, count - 1);
	}
}

function updateBorderStyle(e: DragEvent) {
	const el = (e.target as unknown) as HTMLDivElement;
	el.classList.add('targeted');
}

function revertBorderStyle(e: DragEvent) {
	const el = (e.target as unknown) as HTMLDivElement;
	el.classList.remove('targeted');
}
