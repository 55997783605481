import firebase from 'firebase/app';
import 'firebase/firestore';

import { Product, MutableProduct } from 'features/product/types/productTypes';

/**
 * Used in place of transaction
 * when
 */
class BatchWrapper {
	batch: firebase.firestore.WriteBatch[];

	counter: number;

	committed: boolean = false;

	constructor() {
		this.batch = [];
		this.counter = 0;
	}

	update(
		ref: firebase.firestore.DocumentReference,
		data: firebase.firestore.UpdateData,
	) {
		if (this.counter >= this.batch.length * 500) {
			this.batch.push(firebase.firestore().batch());
		}

		this.batch[this.batch.length - 1].update(ref, data);
		this.counter += 1;
	}

	commit() {
		this.batch.forEach((b) => b.commit());
	}
}

export const addProduct = (id: string, info: Product) => {
	const { typeId, brandId, modelId, productId } = splitId(id);
	const productCollectionRef = firebase.firestore().collection('products');
	const typeRef = productCollectionRef.doc(typeId);
	const brandRef = typeRef.collection('children').doc(brandId);
	const modelRef = brandRef.collection('children').doc(modelId);
	const productRef = modelRef.collection('children').doc(productId);
	return productRef.set(info);
};

export const updateProduct = (id: string, updatedInfo: MutableProduct) => {
	const { typeId, brandId, modelId, productId } = splitId(id);
	const productCollectionRef = firebase.firestore().collection('products');
	const typeRef = productCollectionRef.doc(typeId);
	const brandRef = typeRef.collection('children').doc(brandId);
	const modelRef = brandRef.collection('children').doc(modelId);
	const productRef = modelRef.collection('children').doc(productId);
	return productRef.update(updatedInfo);
};

export const renameData = (id: string, newValue: string) => {
	const { typeId, brandId, modelId, productId } = splitId(id);
	const productCollectionRef = firebase.firestore().collection('products');
	const typeRef = productCollectionRef.doc(typeId);
	if (!brandId) return typeRef.update({ type: newValue });
	const brandRef = typeRef.collection('children').doc(brandId);
	if (!modelId) return brandRef.update({ brand: newValue });
	const modelRef = brandRef.collection('children').doc(modelId);
	if (!productId) return modelRef.update({ model: newValue });
	const productRef = modelRef.collection('children').doc(productId);
	return productRef.update({ name: newValue });
};

export const deleteData = (id: string) => {
	const { typeId, brandId, modelId, productId } = splitId(id);

	const productsRef = firebase.firestore().collection('products');

	const typeRef = productsRef.doc(typeId);
	if (!brandId) return recursiveDelete(typeRef, 3);

	const brandRef = typeRef.collection('children').doc(brandId);
	if (!modelId) return recursiveDelete(brandRef, 2);

	const modelRef = brandRef.collection('children').doc(modelId);
	if (!productId) return recursiveDelete(modelRef, 1);

	const productRef = modelRef.collection('children').doc(productId);
	return recursiveDelete(productRef, 0);
};

export const recursiveDelete = async (
	ref: firebase.firestore.DocumentReference,
	depth: 3 | 2 | 1 | 0,
) => {
	const batch = new BatchWrapper();
	batch.update(ref, { isRemoved: true });

	if (depth > 0) {
		const collection = ref.collection('children');
		const query = await collection.get();
		await Promise.all(
			query.docs.map(async (doc) => {
				const { ref } = doc;
				batch.update(ref, { isRemoved: true });

				if (depth > 1) {
					const collection = ref.collection('children');
					const query = await collection.get();
					await Promise.all(
						query.docs.map(async (doc) => {
							const { ref } = doc;
							batch.update(ref, { isRemoved: true });

							if (depth > 2) {
								const collection = ref.collection('children');
								const query = await collection.get();
								await Promise.all(
									query.docs.map(async (doc) => {
										const { ref } = doc;
										batch.update(ref, { isRemoved: true });
									}),
								);
							}
						}),
					);
				}
			}),
		);
	}

	return batch.commit();
};

export const attachPDF = (id: string, url: string) => {
	const { typeId, brandId, modelId } = splitId(id);
	const productCollectionRef = firebase.firestore().collection('products');
	const typeRef = productCollectionRef.doc(typeId);
	if (!brandId) return typeRef.update({ file: url });
	const brandRef = typeRef.collection('children').doc(brandId);
	if (!modelId) return brandRef.update({ file: url });
	const modelRef = brandRef.collection('children').doc(modelId);
	return modelRef.update({ file: url });
};

export const splitId = (id: string) => {
	const typeId = id.substring(0, 2);
	const brandId = id.substring(2, 4);
	const modelId = id.substring(4, 6);
	const productId = id.substring(6, 9);
	return { typeId, brandId, modelId, productId };
};
