/* eslint-disable no-nested-ternary */
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import OutsideClickHandler from 'react-outside-click-handler';
import { SelectionValue } from 'features/admin/types/select';

interface Props {
	options: Array<SelectionValue>;
	onChangeHandler: (value: SelectionValue['value']) => void;
	initialValue?: SelectionValue;
	initialMode?: boolean;
	disabled?: boolean;
}

const CustomSelect: React.FC<Props> = ({
	options,
	onChangeHandler,
	initialValue = options[0],
	initialMode = false,
	disabled = false,
}) => {
	const [isSelectShown, setShown] = useState(false);
	const [value, setValue] = useState(initialValue);

	const handleSelect = useCallback(
		(selectedValue: SelectionValue) => {
			if (initialMode) {
				setValue(
					options.filter((option) => option !== selectedValue)[0],
				);
			} else {
				setValue(selectedValue);
			}
			setShown(false);
			onChangeHandler(selectedValue.value);
		},
		[onChangeHandler, options, initialMode],
	);

	const dropdownDisplay = React.useMemo(
		() =>
			options.map((option) => (
				<Selection
					key={option.display}
					onClick={() => !disabled && handleSelect(option)}>
					{option.display}
				</Selection>
			)),
		[options, handleSelect, disabled],
	);

	return (
		<OutsideClickHandler
			onOutsideClick={() => isSelectShown && setShown(!isSelectShown)}>
			<Container>
				<SelectBar
					onClick={() =>
						!disabled && options.length && setShown(!isSelectShown)
					}>
					<div>
						{initialMode
							? options.length
								? 'Press to select...'
								: 'No options to select'
							: options.length
							? value?.display || options[0].display
							: 'No options to select'}
					</div>
					<div>
						{!disabled ? (
							isSelectShown ? (
								<AiFillCaretUp />
							) : (
								<AiFillCaretDown />
							)
						) : null}
					</div>
				</SelectBar>
				{isSelectShown && <Dropdown>{dropdownDisplay}</Dropdown>}
			</Container>
		</OutsideClickHandler>
	);
};

const Container = styled.div`
	position: relative;
	width: inherit;
	height: inherit;
	background: white;

	&:hover {
		cursor: pointer;
	}
`;

const SelectBar = styled.div`
	display: flex;
	justify-content: space-between;
	border: solid 1px var(--neutrals-300);
	background: solid 1px var(--neutrals-050);
	box-shadow: inset 0 0 0.5px 0.5px #dddddd;

	height: inherit;
	padding: 0 0.6rem;

	& > * {
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;

const Dropdown = styled.div`
	position: relative;
	z-index: 2;
	min-width: 100%;
	width: fit-content;
	box-shadow: 0.1px 0.1px 10px 1px #cacaca;

	display: flex;
	flex-flow: column;
`;

const Selection = styled.div`
	padding: 0 0.6rem;
	display: flex;
	align-items: center;
	background: var(--neutrals-200);
	color: var(--neutrals-800);
	overflow: hidden;
	text-overflow: ellipsis;
	width: 20rem;
	height: 3rem;
	white-space: nowrap;
	border-bottom: solid 0.5px var(--neutrals-100);

	&:hover {
		background: var(--neutrals-300);
		color: var(--neutrals-900);
	}
`;

export default CustomSelect;
