import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import { AppThunk } from 'redux/reducers';
import 'services/firebase';

import { User } from 'features/account/user/types/userTypes';
import { setToken } from 'features/admin/functions/token';
import setError from 'features/error/actions/edit/set.setError';
import { UserAction } from '../../types/actionTypes';
import checkSession from '../auth/auth.checkSession';

let hasSessionChecked = false;

const authListener = (): AppThunk<() => void> => (dispatch) => {
	return firebase.auth().onIdTokenChanged(async (user) => {
		if (user) {
			if (!hasSessionChecked)
				try {
					const userRef = await dispatch(checkSession(user.uid));
					await userRef.onDisconnect().set({
						is_online: false,
						last_seen: firebase.database.ServerValue.TIMESTAMP,
					});
					await userRef.set({
						is_online: true,
						last_seen: firebase.database.ServerValue.TIMESTAMP,
					});
					hasSessionChecked = true;
				} catch (err) {
					dispatch(setError(err?.message));
					firebase.auth().signOut();
				}

			const idTokenResult = await user.getIdTokenResult(true);
			const { claims, token } = idTokenResult;
			setToken(token);

			const userDoc = await firebase
				.firestore()
				.collection('User')
				.doc(user.uid)
				.get();

			if (userDoc.exists) {
				const userData = userDoc.data() as User;

				dispatch({
					type: UserAction.SET_PROJECTS,
					projects: userData.projects,
				});

				dispatch({
					type: UserAction.SET_ROLE,
					role: claims.role,
				});

				dispatch({
					type: UserAction.SET_ACCOUNT_INFO,
					uid: user.uid,
					company: userData.company || '',
				});

				dispatch({
					type: UserAction.LOGIN,
				});
			}
		} else {
			dispatch({
				type: UserAction.LOGOUT,
			});
			hasSessionChecked = false;
		}
	});
};

export default authListener;
