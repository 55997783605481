import { ModalConfig } from '../types/modal';

const ACCESS_MODAL_CONFIG: ModalConfig = {
	ADD: {
		headingText: 'เพิ่มผู้ใช้งานใหม่',
		submitBtnText: 'สร้าง',
	},
	UPDATE: {
		headingText: 'แก้ไขข้อมูลผู้ใช้งาน',
		submitBtnText: 'แก้ไข',
	},
	VIEW: {
		headingText: 'ข้อมูลผู้ใช้งาน',
	},
};

export default ACCESS_MODAL_CONFIG;
