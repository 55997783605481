import {
	DraggerActionType,
	DraggerState,
	DraggerType,
	DraggerAction,
} from './type';

const genesis: DraggerState = {
	type: DraggerType.IDLE,
};

const draggerReducer = (
	state: DraggerState = genesis,
	action: DraggerAction,
): DraggerState => {
	switch (action.type) {
		case DraggerActionType.DRAG_PRODUCT:
			return {
				type: DraggerType.PRODUCT,
				product: action.product,
				specifiers: action.specifiers,
			};
		case DraggerActionType.DRAG_ROW:
			return {
				type: DraggerType.ROW,
				from: action.from,
			};
		case DraggerActionType.DRAG_SUM:
			return {
				type: DraggerType.SUM,
				list: action.list,
			};
		case DraggerActionType.DROP:
			return {
				type: DraggerType.IDLE,
			};
		default:
			return state;
	}
};

export default draggerReducer;
