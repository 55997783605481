import firebase from 'firebase/app';
import 'firebase/firestore';
import * as XLSX from 'xlsx';

import { AppThunk } from 'redux/reducers';
import setError from 'features/error/actions/edit/set.setError';
import { Product } from '../../product/types/productTypes';

let idCheck: Array<string> = [];
let nameError: Array<string> = [];
let priceError: Array<string> = [];
let idError: Array<string> = [];
let sheetNameError: Array<string> = [];
let specifierError: boolean = false;
let modelError: boolean = false;
let batchArray: Array<firebase.firestore.WriteBatch> = [];
let operationCounter = 0;
let productUploaded = 0;
let batchIndex = 0;
batchArray.push(firebase.firestore().batch());

const excelParser = (
	file: File,
	specifier: { type: string; brand: string },
): AppThunk => async (dispatch) => {
	const reader: FileReader = new FileReader();
	reader.readAsBinaryString(file);
	reader.onload = async (e: any) => {
		const binarystr: string = e.target.result;
		const workbook = XLSX.read(binarystr, { type: 'binary' });
		const sheetNames = workbook.SheetNames;
		for (const sheetName of sheetNames) {
			if (!sheetName.startsWith('Sheet')) {
				const sheet = workbook.Sheets[sheetName];
				const workSheet = XLSX.utils.sheet_to_json(sheet);
				dispatch(uploadWorkSheet(sheetName, workSheet, specifier));
			}
		}
		if (dispatch(isError())) return;
		batchArray.forEach(async (batch) => {
			await batch.commit();
		});
		dispatch(
			setError('การนำเข้าข้อมูลสำเร็จ', {
				errorHeaderText: 'โปรดทราบ',
				errorBtnText: 'ตกลง',
				themeColor: 'var(--primary-800)',
			}),
		);
		resetAllVariables();
	};
};

const uploadWorkSheet = (
	sheetName: string,
	rows: Array<any>,
	specifier: { type: string; brand: string },
): AppThunk => async (dispatch) => {
	const typeRef: firebase.firestore.DocumentReference = firebase
		.firestore()
		.collection('products')
		.doc(specifier.type);
	const brandRef: firebase.firestore.DocumentReference = typeRef
		.collection('children')
		.doc(specifier.brand);
	let getModelId = false;
	let setModel = false;
	let modelId: string = '';
	rows.forEach(async (row: any) => {
		if (row['ลำดับที่'] && row['รายการ'] && row['ลำดับที่'].length === 9) {
			if (!getModelId) {
				modelId = row['ลำดับที่'].substring(4, 6);
				getModelId = true;
			}
			const data: Product = {
				id: row['ลำดับที่'],
				name: row['รายการ'].replace('   - ', '').trim(),
				price: {
					labour: Math.round(row['     ค่าแรงงาน'] * 100) / 100 || 0,
					raw: Math.round(row['ราคาวัสดุสิ่งของ'] * 100) / 100 || 0,
					total:
						Math.round(row['ค่าวัสดุและแรงงาน'] * 100) / 100 || 0,
				},
				unit: row['หน่วย'],
				isRemoved: false,
			};
			if (
				specifier.type !== row['ลำดับที่'].substring(0, 2) ||
				specifier.brand !== row['ลำดับที่'].substring(2, 4)
			)
				specifierError = true;
			if (getModelId && modelId !== row['ลำดับที่'].substring(4, 6))
				modelError = true;
			if (row['รายการ'].search('   - ') === -1)
				nameError.push(row['ลำดับที่']);
			if (
				typeof data.price.labour !== 'number' ||
				typeof data.price.raw !== 'number' ||
				typeof data.price.total !== 'number'
			)
				priceError.push(row['ลำดับที่']);
			if (idCheck.includes(data.id)) idError.push(row['ลำดับที่']);
			else idCheck.push(data.id);
			const productId: string = data.id.substring(6, 9);
			const modelRef = brandRef.collection('children').doc(modelId);
			if (!setModel) {
				const firstParenthesesIndex = sheetName.indexOf(`(${modelId})`);
				if (firstParenthesesIndex === -1)
					sheetNameError.push(sheetName);
				else {
					batchArray[batchIndex].set(modelRef, {
						id: modelId,
						isRemoved: false,
						model: sheetName
							.substring(0, firstParenthesesIndex)
							.trim(),
					});
					operationCounter += 1;
				}
				setModel = true;
			}
			const productRef = modelRef.collection('children').doc(productId);
			batchArray[batchIndex].set(productRef, data);
			operationCounter += 1;
			productUploaded += 1;
		}
		if (operationCounter === 499) {
			batchArray.push(firebase.firestore().batch());
			batchIndex += 1;
			operationCounter = 0;
		}
	});
};

const isError = (): AppThunk<boolean> => (dispatch) => {
	if (
		nameError.length ||
		priceError.length ||
		idError.length ||
		sheetNameError.length ||
		!productUploaded ||
		modelError ||
		specifierError
	) {
		if (specifierError)
			dispatch(
				setError('เลือกประเภท หรือ ยี่ห้อไม่ถูกต้อง', {
					returnHome: false,
				}),
			);
		if (modelError)
			dispatch(
				setError('มีหลาย model ใน  1 sheet', { returnHome: false }),
			);
		if (nameError.length)
			dispatch(
				setError(`${nameError.join('\n')} : ชื่อรายการไม่ถูกต้อง`, {
					returnHome: false,
				}),
			);

		if (priceError.length)
			dispatch(
				setError(`${priceError.join('\n')} : ราคาไม่ถูกต้อง`, {
					returnHome: false,
				}),
			);

		if (idError.length)
			dispatch(
				setError(`${idError.join('\n')} : รหัสไม่ถูกต้อง(ซ้ำ)`, {
					returnHome: false,
				}),
			);
		if (sheetNameError.length)
			dispatch(
				setError(`${sheetNameError.join('\n')} ชื่อ Sheet ไม่ถูกต้อง`, {
					returnHome: false,
				}),
			);
		if (!productUploaded)
			dispatch(
				setError('รูปแบบของไฟล์ไม่ถูกต้อง กรุณาตรวจสอบหัวตาราง', {
					returnHome: false,
				}),
			);
		resetAllVariables();
		return true;
	}
	return false;
};

const resetAllVariables = () => {
	idCheck = [];
	nameError = [];
	priceError = [];
	idError = [];
	sheetNameError = [];
	modelError = false;
	specifierError = false;
	batchArray = [];
	batchArray.push(firebase.firestore().batch());
	operationCounter = 0;
	productUploaded = 0;
	batchIndex = 0;
};

export default excelParser;
