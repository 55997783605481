import React from 'react';
import styled from 'styled-components';
import Portal from 'utilities/components/portal';
import Close from 'components/icons/close';

interface Props {
	modalTitle: string;
	onCloseModalHandler: () => void;
}

const Modal: React.FC<Props> = ({
	onCloseModalHandler,
	modalTitle,
	children,
}) => {
	return (
		<Portal>
			<Backdrop onClick={onCloseModalHandler}>
				<Container onClick={(e) => e.stopPropagation()}>
					<ModalTitle>{modalTitle}</ModalTitle>
					<CloseButton onClick={onCloseModalHandler}>
						<Close size={26} color="var(--neutrals-300)" />
					</CloseButton>
					{children}
				</Container>
			</Backdrop>
		</Portal>
	);
};

export default Modal;

const Container = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	padding: 0;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 4px 8px rgba(0, 0, 0, 0.05),
		0 16px 32px rgba(0, 0, 0, 0.05);
	background: white;
	transform: translate(-50%, -50%);
	width: 300px;
	border-radius: 8px;
	overflow: hidden;
`;

const ModalTitle = styled.div`
	font-size: 20px;
	font-weight: 600;
	color: var(--neutrals-800);
	padding: 0.75rem 1rem;
	background: var(--neutrals-050);
	margin-bottom: 1rem;
`;

const Backdrop = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: ${document.body.clientWidth}px;
	height: ${document.body.clientHeight}px;
	background: rgba(0, 0, 0, 0.6);
`;

const CloseButton = styled.button`
	outline: none;
	border: none;
	background: transparent;
	appearance: none;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
	position: absolute;
	right: 10px;
	top: 10px;
	border-radius: 100px;
	cursor: pointer;
	&:hover {
		background: rgba(0, 0, 0, 0.07);
	}
	&:active {
		background: rgba(0, 0, 0, 0.09);
	}
`;
