import firebase from 'firebase/app';
import 'firebase/firestore';

import { AppThunk } from 'redux/reducers';
import uploadFile from 'services/firebase/uploadFile';

interface FunctionParams {
	file: File;
	specifiers: {
		type: string;
		brand: string;
		model?: string;
	};
}

const STORAGE_FOLDER_PATH = 'pdf/';

const uploadCatalogFile = ({
	file,
	specifiers,
}: FunctionParams): AppThunk<Promise<void>> => async (dispatch) => {
	// Construct path from constant and relative components
	let path = STORAGE_FOLDER_PATH;

	Object.values(specifiers)
		.filter((path) => path)
		.forEach((value) => {
			path += `${value}/`;
		});

	const fileUrl = await dispatch(uploadFile({ file, path }));

	if (fileUrl) {
		let docRef = firebase
			.firestore()
			.collection('products')
			.doc(specifiers.type)
			.collection('children')
			.doc(specifiers.brand);

		if (specifiers.model) {
			docRef = docRef.collection('children').doc(specifiers.model);
		}

		await docRef.update({
			file: fileUrl,
		});
	}
};

export default uploadCatalogFile;
