import React, {
	FC,
	useState,
	ChangeEvent,
	useLayoutEffect,
	useRef,
} from 'react';
import styled, { css } from 'styled-components';
import range from 'utilities/functions/range';

type Props =
	| {
			indent?: number;
			width: number | string;
			textAlign: any;
			value: string;
			editable: false;
	  }
	| {
			width: number | string;
			value: string;
			textAlign: any;
			editable: true;
			onChange: (v: string) => void;
	  };

const BodyCell: FC<Props> = (props) => {
	const { width, value, textAlign } = props;
	const [field, setField] = useState(value);
	const previous = useRef(value);

	useLayoutEffect(() => {
		if (previous.current !== value) {
			previous.current = value;
			if (value !== field) {
				setField(value);
			}
		}
	}, [value, field]);

	if (props.editable) {
		const { onChange } = props;

		const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
			setField(e.target.value);
			if (Number.isNaN(parseFloat(e.target.value))) {
				return;
			}

			onChange(e.target.value);
		};

		return (
			<Container style={{ width }}>
				<Dynamic
					style={{ ...makeFlex(textAlign) }}
					onChange={handleChange}
					value={field}
				/>
			</Container>
		);
	}

	const indent = props.indent || 0;

	return (
		<Container style={{ width }}>
			{range(indent).map((i) => (
				<Bullet key={i} />
			))}
			<Static style={{ ...makeFlex(textAlign) }}>{value}</Static>
		</Container>
	);
};

export default React.memo(BodyCell);

function makeFlex(textAlign: string): any {
	switch (textAlign) {
		case 'left':
			return {
				justifyContent: 'flex-start',
				textAlign: 'left',
			};
		case 'center':
			return {
				justifyContent: 'center',
				textAlign: 'center',
			};
		case 'right':
			return {
				justifyContent: 'flex-end',
				textAlign: 'right',
			};
		default:
			return {};
	}
}

const Bullet = styled.span`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 14px;
	height: 100%;
	transform: translateX(5px);

	&::before {
		content: '-';
	}
`;

const Container = styled.div`
	flex: 0 0 auto;
	display: flex;
	flex-direction: row;
	min-width: 0;
	border-right: 1px solid var(--neutrals-100);
	background: white;
	border-bottom: 1px solid var(--neutrals-100);
`;

const Cell = css`
	color: var(--neutrals-900);
	padding: 0.25rem 0.5rem;
	white-space: nowrap;
	overflow: hidden;
	width: 100%;
	height: 100%;
	font-size: 1rem;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	border: none;
	background: transparent;
`;

const Static = styled.div`
	${Cell}
`;

const Dynamic = styled.input`
	${Cell}

	&:hover {
		outline: none;
		background: var(--accent-050);
	}

	&:focus {
		outline: none;
		border: 2px solid var(--accent-400);
	}
`;
