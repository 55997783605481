import {
	ErrorAction,
	ErrorActionTypes,
} from 'features/error/types/actionTypes';

const removeError = (): ErrorActionTypes => ({
	type: ErrorAction.REMOVE_ERROR,
});

export default removeError;
