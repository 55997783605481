/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import createProject from 'apis/project/create';
import { Home, New, Tray } from 'components/icons';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/reducers';
import withProjectBrowser from 'features/project/wrappers/withProjectBrowser';

interface Props {
	specifiersId: {
		type: string;
		brand: string;
	};
	toggleBrowser: () => void;
}

const Navigation: React.FC<Props> = ({ specifiersId, toggleBrowser }) => {
	const history = useHistory();
	const dispatch = useDispatch<AppDispatch>();

	const handleCreateProject = async () => {
		const id = await dispatch(createProject());
		history.push({
			pathname: '/projects',
			search: `?id=${id}`,
		});
	};

	return (
		<Container>
			<Group>
				<SquareButton onClick={() => history.push('/welcome')}>
					<Home
						style={{ transform: 'translateY(-1px)' }}
						size={21}
						color="none"
					/>
				</SquareButton>
				<Border />
				<RectangleButton onClick={handleCreateProject}>
					<New size={22} color="none" />
					<Text>สร้างโครงการใหม่</Text>
				</RectangleButton>
				<Border />
				<RectangleButton onClick={toggleBrowser}>
					<Tray size={21} color="none" />
					<Text>เปิดโครงการ</Text>
				</RectangleButton>
				<Border />
			</Group>
			<Spacer />
			<Group>
				<Border />
			</Group>
		</Container>
	);
};

export default withProjectBrowser(Navigation);

const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	position: sticky;
	top: 0;
	width: 100%;
	height: 40px;
	min-height: 40px;
	background-color: var(--primary-900);
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 4px 16px rgba(0, 0, 0, 0.05);
`;

const Text = styled.span`
	margin-left: 0.5rem;
	margin-right: 0.25rem;
	font-family: Sarabun;
	font-size: 14px;
	font-weight: 500;
	color: var(--neutrals-050);
`;

const Spacer = styled.div`
	flex: 1;
`;

const Group = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	height: 40px;
	background: transparent;
`;

/**
 * @duplicate from features/project/component/menu
 */
const Border = styled.div`
	height: 100%;
	width: 1px;
	background: var(--neutrals-700);
`;

/**
 * @duplicate from features/project/component/menu
 */
const SquareButton = styled.button`
	position: relative;
	outline: none;
	display: flex;
	align-items: center;
	justify-content: center;
	appearance: none;
	border: none;
	background: transparent;
	width: 40px;
	height: 100%;
	color: var(--neutrals-050);
	cursor: pointer;
	&:hover {
		background: var(--primary-800);
	}
	&:active {
		background: var(--primary-700);
	}
`;

const RectangleButton = styled(SquareButton)`
	width: auto;
	padding: 0 0.5rem;
`;
