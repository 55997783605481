/* eslint-disable prefer-promise-reject-errors */
import { ProjectInfo } from '../types/projectTypes';

export const verifyForm = (fields: ProjectInfo) =>
	new Promise(
		(resolve: (res: string) => void, reject: (err: string) => void) => {
			if (!fields.name) reject('กรุณาใส่ชื่อโครงการ');
			if (!fields.area) reject('กรุณาใส่สถานที่');
			if (!fields.date) reject('กรุณาใส่วันที่');
			if (!fields.organization)
				reject('กรุณาใส่ชื่อบริษัท / หจก. / ชื่อบุคคล');
			if (!fields.contractor) reject('กรุณาใส่ชื่อผู้ประมาณราคา');
			if (!fields.contactInfo) reject('กรุณาใส่ email');
			if (!validateEmail(fields.contactInfo))
				reject('อีเมลต้องอยู่ในรูปแบบที่ถูกต้อง');

			resolve('สำเร็จ');
		},
	);

const validateEmail = (emailAddress: string) => {
	if (/^\S+@\S+\.\S+$/.test(emailAddress)) {
		return true;
	}
	return false;
};
