const CLOUD_FUNCTIONS = ' https://us-central1-sampon-erp.cloudfunctions.net';

const FUNCTIONS_API = {
	REGISTER_USER: `${CLOUD_FUNCTIONS}/registerUser`,
	CREATE_USER: `${CLOUD_FUNCTIONS}/createUser`,
	UPDATE_USER: `${CLOUD_FUNCTIONS}/updateUser`,
	DELETE_USER: `${CLOUD_FUNCTIONS}/deleteUser`,
	LIST_USERS: `${CLOUD_FUNCTIONS}/listUsers`,
};

export const ENDPOINT = {
	FUNCTIONS_API,
};
