import React, {
	FC,
	useState,
	useCallback,
	useRef,
	useEffect,
	useMemo,
} from 'react';
import styled from 'styled-components';
import { motion, useDragControls, PanInfo } from 'framer-motion';
import Switch from 'react-switch';

import Portal from 'utilities/components/portal';

import DragIndicator from 'components/icons/dragIndicator';
import Magnifier from 'components/icons/magnifier';
import Close from 'components/icons/close';
import useStickyState from 'components/hooks/useStickyState';

import { searchArrayOfObjects } from 'utilities/functions/search';

import common_th from 'translations/th/common.json';

import useToggle from 'utilities/hooks/useToggle';
import {
	Product,
	TypeSpecifier,
	BrandSpecifier,
	ModelSpecifier,
} from '../types/productTypes';
import Dropdown from './Dropdown';
import ProductResult from './ProductResult';

import getProductsFromID from '../actions/query/query.getProductsFromID';
import getTypes from '../actions/query/query.getTypes';
import getBrands from '../actions/query/query.getBrands';
import getUrl from '../actions/query/getUrl';
import getModels from '../actions/query/query.getModels';
import CatalogModal from './CatalogModal';

interface Props {
	onClose: () => void;
	isShown: boolean;
}

enum Window {
	PRODUCT,
	CATALOG,
}

type Filters = [string, string, string];
type Options = [TypeSpecifier[], BrandSpecifier[], ModelSpecifier[]];

const ProductModal: FC<Props> = ({ onClose, isShown }) => {
	const [activeWindow, setActiveWindow] = useState(Window.PRODUCT);
	const [url, setUrl] = useState<string>('');
	const [filters, setFilters] = useState<Filters>(['', '', '']);
	const [namedFilters, setNamedFilters] = useState<Filters>(['', '', '']);
	const [options, setOptions] = useState<Options>([[], [], []]);
	const [products, setProducts] = useState<Product[]>([]);
	const [catalog, toggleCatalog] = useToggle(false);

	const [dragging, setDragging] = useState(false);
	const [search, setSearch] = useState('');
	const [sticky, setSticky] = useStickyState(
		{ top: 0, left: 0 },
		'products-modal-position',
	);

	const position = useRef(sticky);
	const modal = useRef<HTMLDivElement>(null);
	const constraintsRef = useRef(null);
	const scrollableResultsRef = useRef<HTMLDivElement>(null);

	const dragControls = useDragControls();

	const onChange = useCallback(
		(id: number, event: React.ChangeEvent<HTMLSelectElement>) => {
			/* Must capture the value here */
			const { target } = event;
			const { value } = target;

			setFilters(
				(prev) =>
					[
						...prev.slice(0, id),
						value,
						...(id === 2 ? [] : new Array(2 - id).fill('')),
					] as Filters,
			);
		},
		[],
	);

	const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		// force results container to scroll to top
		scrollableResultsRef.current?.scrollTo({
			top: 0,
			behavior: 'smooth',
		});

		setSearch(event.target.value);
	};

	const startDrag = (event: React.MouseEvent<HTMLDivElement>) => {
		setDragging(true);
		dragControls.start(event, { snapToCursor: false });
	};

	const endDrag = () => {
		setDragging(false);
	};

	const validateDrag = (
		e: MouseEvent | TouchEvent | PointerEvent,
		info: PanInfo,
	) => {
		if (!dragging) {
			(dragControls as any).componentControls.forEach((entry: any) => {
				entry.stop(e, info);
			});
		}
	};

	const savePosition = () => {
		if (modal.current) {
			const rect = modal.current.getBoundingClientRect();
			const { top, left } = rect;
			setSticky({ top, left });
		}
	};

	useEffect(() => {
		// eslint-disable-next-line consistent-return
		const search = async () => {
			const filter = filters[0] + filters[1] + filters[2];

			if (filters[0] && filters[1]) {
				// If at least a type is selected,
				// the system will search for a catalog matching the filters
				const url = await getUrl([filters[0], filters[1], filters[2]]);
				setUrl(url);
			}

			if (!filters.includes('')) {
				return getProductsFromID(filter, (filteredProducts) => {
					setProducts(filteredProducts);

					// Set the named filters for the result items
					// When they get put into rows of the project
					// they enable access to product type/brand/category
					// without using querying the products collection
					const typeName = options[0].find(
						(type) => type.id === filters[0],
					)?.type as string;
					const brandName = options[1].find(
						(brand) => brand.id === filters[1],
					)?.brand as string;
					const modelName = options[2].find(
						(model) => model.id === filters[2],
					)?.model as string;

					setNamedFilters([typeName, brandName, modelName]);
				});
			}
			setProducts([]);
		};

		search();
	}, [filters, options]);

	useEffect(() => {
		return getTypes((x) => {
			setOptions((prev) => [
				x,
				...(prev.slice(1) as [BrandSpecifier[], ModelSpecifier[]]),
			]);
		});
	}, []);

	// eslint-disable-next-line consistent-return
	useEffect(() => {
		if (filters[0]) {
			return getBrands(filters[0], (x) => {
				setOptions((prev) => [prev[0], x, prev[2]]);
			});
		}
	}, [filters]);

	// eslint-disable-next-line consistent-return
	useEffect(() => {
		if (filters[0] && filters[1]) {
			return getModels(filters[0], filters[1], (x) => {
				setOptions((prev) => [prev[0], prev[1], x]);
			});
		}
	}, [filters]);

	const filteredProducts = useMemo(() => {
		return searchArrayOfObjects(
			products.sort((a, b) => {
				if (a.id < b.id) return -1;
				if (a.id > b.id) return 1;
				return 0;
			}),
			['name', 'id'],
			search,
		);
	}, [products, search]);

	const extendedOpenCatalog = () => {
		toggleCatalog();
		setActiveWindow(Window.CATALOG);
	};

	return (
		<Portal>
			<Backdrop ref={constraintsRef}>
				<Modal
					drag
					ref={modal}
					dragElastic={0}
					top={position.current.top}
					left={position.current.left}
					shown={isShown}
					dragMomentum={false}
					dragPropagation={false}
					dragControls={dragControls}
					dragConstraints={constraintsRef}
					onDragStart={validateDrag}
					onDragEnd={savePosition}
					onMouseDown={() => setActiveWindow(Window.PRODUCT)}
					style={{ zIndex: activeWindow === Window.PRODUCT ? 2 : 1 }}>
					<Handle onMouseDown={startDrag} onMouseUp={endDrag}>
						<DragIndicator />
					</Handle>
					<HeaderWrapper>
						<Title>{common_th.products}</Title>
						<CircleButton onClick={onClose}>
							<Close size={26} color="var(--neutrals-700)" />
						</CircleButton>
					</HeaderWrapper>
					<DropdownsWrapper>
						<SectionTitle>{common_th.filters}</SectionTitle>
						<Dropdown
							value={filters[0]}
							placeholder={common_th.select + common_th.type}
							onChange={(e) => onChange(0, e)}>
							{options[0]
								.sort((a, b) => {
									if (a.id < b.id) return -1;
									if (a.id > b.id) return 1;
									return 0;
								})
								.map((type: TypeSpecifier) => (
									<option value={type.id} key={type.id}>
										{type.type}
									</option>
								))}
						</Dropdown>
						<Dropdown
							value={filters[1]}
							placeholder={common_th.select + common_th.brand}
							onChange={(e) => onChange(1, e)}>
							{options[1]
								.sort((a, b) => {
									if (a.brand < b.brand) return -1;
									if (a.brand > b.brand) return 1;
									return 0;
								})
								.map((brand) => (
									<option value={brand.id} key={brand.id}>
										{brand.brand}
									</option>
								))}
						</Dropdown>
						<Dropdown
							value={filters[2]}
							placeholder={common_th.select + common_th.model}
							onChange={(e) => onChange(2, e)}>
							{options[2]
								.sort((a, b) => {
									if (a.id < b.id) return -1;
									if (a.id > b.id) return 1;
									return 0;
								})
								.map((model) => (
									<option value={model.id} key={model.id}>
										{model.model}
									</option>
								))}
						</Dropdown>
					</DropdownsWrapper>
					<FooterWrapper>
						<SectionTitle>{common_th.results}</SectionTitle>
						<ActionsWrapper>
							<CatalogToggler>
								<Switch
									borderRadius={8}
									checked={catalog}
									onChange={extendedOpenCatalog}
								/>
							</CatalogToggler>
							<InputWrapper>
								<Magnifier
									size={18}
									color="var(--neutrals-300)"
								/>
								<Input
									value={search}
									onChange={onSearchChange}
									placeholder={
										common_th.code_or_any_product_name
									}
								/>
							</InputWrapper>
						</ActionsWrapper>
						<ResultsWrapper ref={scrollableResultsRef}>
							{filteredProducts.map((product, idx) => (
								<ProductResult
									isHighlighted={!!search && !idx}
									product={product}
									filters={namedFilters}
									key={product.id}
								/>
							))}
						</ResultsWrapper>
					</FooterWrapper>
				</Modal>
				{isShown && catalog && url && (
					<CatalogModal
						url={url}
						ref={constraintsRef}
						onClick={() => setActiveWindow(Window.CATALOG)}
						onClose={toggleCatalog}
						style={{
							zIndex: activeWindow === Window.CATALOG ? 2 : 1,
						}}
					/>
				)}
			</Backdrop>
		</Portal>
	);
};

export default ProductModal;

const CatalogToggler = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 0.75rem;
	align-items: center;
	width: 100%;
	justify-content: space-between;
	background: var(--neutrals-050);
	padding: 0.5rem;
	padding-left: 1rem;
	border-radius: 8px;
	border: 2px solid var(--neutrals-100);

	&::before {
		content: 'ไฟล์ Catalog ';
		font-size: 16px;
		font-weight: 500;
		color: var(--neutrals-500);
		margin-right: 1rem;
	}
`;

const Backdrop = styled(motion.div)`
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	pointer-events: none;

	display: flex;
	align-items: center;
	justify-content: center;

	z-index: 10;

	* {
		pointer-events: auto;
	}
`;

const Modal = styled(motion.div)<{ top: number; left: number; shown: boolean }>`
	display: ${({ shown }) => (shown ? 'grid' : 'none')};
	grid-template-columns: minmax(0, 1fr);
	grid-template-rows: repeat(3, auto) minmax(0, 1fr);

	position: absolute;
	top: ${({ top }) => top}px;
	left: ${({ left }) => left}px;
	width: 450px;
	height: 90vh;
	min-width: 450px;
	background: white;
	border-radius: 8px;
	overflow: hidden;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06), 0 4px 8px rgba(0, 0, 0, 0.06),
		0 8px 16px rgba(0, 0, 0, 0.06), 0 16px 32px rgba(0, 0, 0, 0.06);
`;

const Handle = styled.div`
	height: 29px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--primary-800);
	cursor: grab;

	&:active {
		cursor: grabbing;
	}
`;

const HeaderWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	min-height: max-content;
	padding: 0.375rem 1rem;
`;

const Title = styled.h2`
	font-size: 20px;
	font-weight: 700;
	color: var(--neutrals-900);
	margin: 0;
`;

const DropdownsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: flex-start;
	height: auto;
	width: auto;
	padding: 0.5rem 1rem;
	margin-top: 2px;

	& > *:not(:first-child) {
		margin-top: 10px;
	}
`;

const SectionTitle = styled.div`
	position: sticky;
	top: 0;
	font-size: 12px;
	font-weight: 600;
	color: var(--accent-700);
	text-transform: uppercase;
	letter-spacing: 0.15px;
	background: white;
`;

const FooterWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto auto minmax(0, 1fr);
	grid-gap: 0.75rem;

	height: 100%;
	margin-top: 1rem;
	padding: 0 1rem;
`;

const ResultsWrapper = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	overflow: auto;
	height: 100%;
	border-radius: 8px 8px 0 0;

	& > *:not(:first-child) {
		margin-top: 0.75rem;
	}

	&::after {
		content: '';
		position: relative;
		display: block;
		min-height: 2rem;
	}
`;

const ActionsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	min-height: max-content;
`;

const InputWrapper = styled.div`
	flex-grow: 1;
	display: flex;
	flex-direction: row;
	align-items: center;
	min-height: min-content;
	background-color: var(--neutrals-050);
	padding: 0 0.5rem 0 0.875rem;
	border-radius: 8px;
	border: 2px solid var(--neutrals-100);
	transition: all 300ms;
	height: 44px;

	&:focus-within {
		border: 2px solid var(--support-500);
	}
`;

const Input = styled.input.attrs({
	type: 'text',
})`
	flex: 1;
	display: flex;
	align-items: center;
	font-size: 1rem;
	background: transparent;
	color: var(--neutrals-900);
	margin-left: 0.5rem;

	outline: none;
	border: none;
	appearance: none;

	&::placeholder {
		color: var(--neutrals-500);
	}
`;

const CircleButton = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 34px;
	width: 34px;
	margin-left: 0.375rem;
	padding: 0;

	background: none;
	border-radius: 100px;

	outline: none;
	border: none;
	cursor: pointer;
	transition: all 170ms;
	transform: translateX(8px);

	&:hover {
		opacity: 0.8;
		background: var(--neutrals-050);
	}

	&:active {
		opacity: 0.4;
		transform: translateX(8px) scale(0.95);
	}
`;
