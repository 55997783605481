import firebase from 'firebase/app';
import 'firebase/database';
import { AppThunk } from 'redux/reducers';

const checkSession = (
	userId: string,
): AppThunk<Promise<firebase.database.Reference>> => async () => {
	const userRef = firebase.database().ref(`/users/${userId}`);

	const snapshot = await userRef.once('value');
	if (!snapshot.exists()) {
		await userRef.set({
			last_seen: firebase.database.ServerValue.TIMESTAMP,
		});
		return userRef;
	}
	const user = snapshot.val();
	if (user.is_online) {
		throw new Error('ท่านสามารถ login ได้แค่ที่เดียว');
	}
	// eslint-disable-next-line consistent-return
	return userRef;
};

export default checkSession;
