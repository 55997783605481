import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/reducers';
import styled from 'styled-components';

import thaiAccessor from 'features/tools/constants/accessor';
import addSpecifierOrProduct from 'features/tools/actions/update/set.addSpecifierOrProduct';
import { Specifier } from 'features/tools/types/types';
import ModalName from 'features/tools/constants/modal';
import Modal from '.';

interface Props {
	onCloseModalHandler: () => void;
	onNavigateToSpecifier: (specifierID: string) => void;
	toggleModal: any;
	accessor: string;
	sameLevelNeighbors: Specifier[];
	specifierIDs: Array<string>;
	specifiers?: Array<string>;
}

const NameInputModal: React.FC<Props> = ({
	onCloseModalHandler,
	onNavigateToSpecifier,
	toggleModal,
	accessor,
	sameLevelNeighbors,
	specifierIDs,
	specifiers,
}) => {
	const [inputFields, setInputFields] = useState({
		name: '',
		id: '',
	});

	const dispatch = useDispatch<AppDispatch>();

	const handleChange = (field: 'name' | 'id') => (
		e: React.ChangeEvent<HTMLInputElement>,
	) => {
		setInputFields({
			...inputFields,
			[field]: e.target.value,
		});
	};

	const handleAddConfirm = async () => {
		const { id } = inputFields;
		const idAlreadyExists = sameLevelNeighbors
			.map((l) => l.id)
			.includes(id);

		if (idAlreadyExists) {
			toggleModal(ModalName.CONFIRM, true, {
				modalTitle: 'ยืนยันการเพิ่ม ID ซ้ำ',
				message:
					'ID ดังกล่าวมีอยู่แล้ว\nการเพิ่ม ID ดังกล่าวจะทับข้อมูลเดิม',
				onConfirmCallback: () => {
					handleAddSpecifierOrProduct();
					toggleModal(ModalName.CONFIRM, false);
				},
			});
		} else {
			handleAddSpecifierOrProduct();
		}
	};

	const handleAddSpecifierOrProduct = async () => {
		const { id } = inputFields;
		await dispatch(
			addSpecifierOrProduct({
				specifiers: specifierIDs,
				...inputFields,
			}),
		);

		onNavigateToSpecifier(
			specifierIDs.length < 3 ? id : specifierIDs.join('') + id,
		);
		onCloseModalHandler();
	};

	return (
		<Modal
			modalTitle={`เพิ่ม${thaiAccessor[accessor].name}`}
			onCloseModalHandler={onCloseModalHandler}>
			<InputWrapper>
				<InputLabel>ชื่อ{thaiAccessor[accessor].name}</InputLabel>
				<InputField
					value={inputFields.name}
					placeholder={thaiAccessor[accessor].example.name}
					onChange={handleChange('name')}
				/>
			</InputWrapper>
			<InputWrapper>
				<InputLabel>ID รายการ</InputLabel>
				<InputField
					value={inputFields.id}
					placeholder={thaiAccessor[accessor].example.id}
					onChange={handleChange('id')}
				/>
			</InputWrapper>
			<Group>
				<SectionLabel>ตำแหน่งที่เพิ่ม</SectionLabel>
				<SectionData>
					{specifiers?.length ? (
						<>
							{specifiers.map((s) => (
								<Span key={s}>
									<Slash />
									{s}
								</Span>
							))}
						</>
					) : (
						'-'
					)}
				</SectionData>
			</Group>
			<PrimaryButton onClick={handleAddConfirm}>
				เพิ่ม{thaiAccessor[accessor].name}
			</PrimaryButton>
		</Modal>
	);
};

export default NameInputModal;

const InputLabel = styled.label`
	font-size: 14px;
	font-weight: 500;
	color: var(--neutrals-500);
	margin-top: 0.25rem;
`;

const InputWrapper = styled.div`
	position: relative;
	width: 100%;
	padding: 0 1rem;
	background: white;
	display: flex;
	flex-direction: column;
	margin-bottom: 0.5rem;

	&:focus-within::before {
		content: '';
		height: calc(100% - 2px);
		position: absolute;
		top: 0;
		left: 0;
		background: var(--primary-500);
		width: 3px;
	}

	&:focus-within::after {
		content: '';
		height: calc(100% - 2px);
		position: absolute;
		top: 0;
		right: 0;
		background: var(--primary-500);
		width: 3px;
	}
`;

const InputField = styled.input`
	outline: none;
	appearance: none;
	border: none;
	width: 100%;
	height: 30px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	font-size: 16px;
	font-weight: 500;
	color: var(--neutrals-700);
	background: var(--neutrals-050);
	padding-left: 0.3rem;
	border-radius: 0.25rem;

	&::placeholder {
		color: var(--neutrals-500);
	}
`;

const PrimaryButton = styled.button`
	outline: none;
	border: none;
	appearance: none;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	height: 36px;
	color: var(--primary-600);
	background: var(--primary-050);
	border-radius: 6px;
	font-size: 16px;
	font-weight: 500;
	margin: 1rem;
	margin-top: 0;
	cursor: pointer;

	&:hover {
		background: var(--primary-100);
	}
`;

const Span = styled.span`
	margin-top: 0.375rem;
	color: var(--neutrals-600);

	&:first-of-type {
		margin-top: 0;
	}
`;

const Slash = styled.span`
	&::before {
		content: '→';
		padding: 0 0.375rem;
		font-size: 16px;
		font-weight: 500;
		color: var(--neutrals-400);
	}

	margin-right: 0.375rem;
`;

const Group = styled.div`
	padding: 1rem;
	padding-top: 0;
	display: flex;
	flex-direction: column;
`;

const SectionLabel = styled.div`
	font-size: 14px;
	font-weight: 500;
	color: var(--neutrals-500);
	margin-bottom: 0.375rem;
`;

const SectionData = styled.div`
	font-size: 16px;
	font-weight: 500;
	color: var(--neutrals-700);
	display: flex;
	flex-direction: column;
`;
