import firebase from 'firebase/app';
import 'firebase/firestore';
import { Product } from 'features/product/types/productTypes';

const getProductsFromID = (
	id: string,
	cb: (arr: Product[]) => void,
): (() => void) => {
	const typeId = id.slice(0, 2);
	const brandId = id.slice(2, 4);
	const modelId = id.slice(4, 6);

	return (
		firebase
			.firestore()
			.collection(
				`products/${typeId}/children/${brandId}/children/${modelId}/children`,
			)
			.where('isRemoved', '==', false)
			// eslint-disable-next-line consistent-return
			.onSnapshot((querySnapshot) => {
				if (!querySnapshot.size) return cb([]);
				const products: Product[] = [];
				querySnapshot.forEach((productDoc) => {
					const product = productDoc.data() as Product;
					products.push(product);
				});
				cb(products);
			})
	);
};

export default getProductsFromID;
