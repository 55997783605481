import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

import { AppThunk } from 'redux/reducers';
import setError from 'features/error/actions/edit/set.setError';
import { LoginFields } from 'features/account/auth/types/formTypes';
import errorTranslations from 'features/account/auth/constants/errorTranslations';

const login = (fields: LoginFields): AppThunk<Promise<void>> => async (
	dispatch,
) => {
	try {
		const { user } = await firebase
			.auth()
			.signInWithEmailAndPassword(fields.email, fields.password);

		if (user?.uid) {
			const userRef = firebase
				.firestore()
				.collection('User')
				.doc(user.uid);
			const { loginCount } = (await userRef.get())?.data() as any;
			userRef.update({
				loginCount: loginCount ? loginCount + 1 : 1,
			});
		}
	} catch (err) {
		dispatch(setError(errorTranslations.login[err.code]));
	}
};

export default login;
