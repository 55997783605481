import { ProjectRow } from 'features/project/types/projectTypes';
import { DiscountList, DiscountPercent } from '../types';

function applyDiscount(
	rows: ProjectRow[],
	discount: DiscountList,
): ProjectRow[] {
	const mr = new Map<string, number[]>();
	const md = new Map<string, DiscountPercent>();

	rows.forEach((r, i) => {
		const specifierId = r.id.match(/\d[A-Za-z]\d{5}/g)
			? r.id.substring(0, 6)
			: r.hash;

		if (mr.has(specifierId)) {
			mr.set(specifierId, [...mr.get(specifierId)!, i]);
		} else {
			mr.set(specifierId, [i]);
		}
	});

	discount.forEach((t) => {
		t.data.forEach((b) => {
			b.models.forEach((m) => {
				if (m.hash) {
					md.set(m.hash, {
						raw: m.discountPercent.raw,
						labour: m.discountPercent.labour,
					});
				} else {
					md.set(m.id, {
						raw: m.discountPercent.raw,
						labour: m.discountPercent.labour,
					});
				}
			});
		});
	});

	const nextRows = [...rows];

	md.forEach(({ raw, labour }, id) => {
		if (!mr.has(id)) {
			return;
		}

		const indices = mr.get(id)!;

		indices.forEach((index) => {
			const row = nextRows[index];
			nextRows[index] = {
				...row,
				price: {
					/** This will contains some deprecated values */
					...row.price,
					raw: row.price.raw * (1 + raw / 100),
					labour: row.price.labour * (1 + labour / 100),
				},
			};
		});
	});

	return nextRows;
}

export default applyDiscount;
