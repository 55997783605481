import { User } from 'features/account/user/types/userTypes';
import Roles from 'features/admin/constants/roles';
import { UserActionTypes, UserAction } from '../types/actionTypes';

const initialState: User = {
	role: Roles.EMPTY,
	uid: '',
	projects: [],
	isLoggedIn: undefined,
};

const userReducer = (state = initialState, action: UserActionTypes): User => {
	switch (action.type) {
		case UserAction.LOGIN: {
			return {
				...state,
				isLoggedIn: true,
			};
		}
		case UserAction.LOGOUT: {
			return {
				...state,
				uid: '',
				projects: [],
				isLoggedIn: false,
				role: Roles.EMPTY,
			};
		}
		case UserAction.SET_ROLE: {
			return {
				...state,
				role: action.role,
			};
		}
		case UserAction.SET_ACCOUNT_INFO: {
			return {
				...state,
				uid: action.uid,
			};
		}
		case UserAction.SET_PROJECTS: {
			return {
				...state,
				projects: action.projects,
			};
		}
		default: {
			return state;
		}
	}
};

export default userReducer;
