export interface ModalController {
	modal: Modal;
	params: any;
}

export enum Modal {
	EMPTY = '',
	ADD = 'ADD',
	UPDATE = 'UPDATE',
	VIEW = 'VIEW',
}

interface ModalData {
	headingText: string;
	submitBtnText?: string;
}
export type ModalConfig = {
	[key in Exclude<Modal, Modal.EMPTY>]: ModalData;
};
