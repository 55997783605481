import React, { FC } from 'react';

interface Props {
	size?: number;
	color?: string;
	[prop: string]: any;
}

const Magnifier: FC<Props> = ({ size = 24, color = 'white', ...delegated }) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 28 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...delegated}>
			<path
				d="M27.6172 24.2102L22.1648 18.7578C21.9187 18.5117 21.5852 18.375 21.2352 18.375H20.3438C21.8531 16.4445 22.75 14.0164 22.75 11.375C22.75 5.09141 17.6586 0 11.375 0C5.09141 0 0 5.09141 0 11.375C0 17.6586 5.09141 22.75 11.375 22.75C14.0164 22.75 16.4445 21.8531 18.375 20.3438V21.2352C18.375 21.5852 18.5117 21.9187 18.7578 22.1648L24.2102 27.6172C24.7242 28.1312 25.5555 28.1312 26.0641 27.6172L27.6117 26.0695C28.1258 25.5555 28.1258 24.7242 27.6172 24.2102ZM11.375 18.375C7.50859 18.375 4.375 15.2469 4.375 11.375C4.375 7.50859 7.50312 4.375 11.375 4.375C15.2414 4.375 18.375 7.50312 18.375 11.375C18.375 15.2414 15.2469 18.375 11.375 18.375Z"
				fill={color}
			/>
		</svg>
	);
};

export default Magnifier;
