import { getToken } from 'features/admin/functions/token';
import { AdminAction } from 'features/admin/types/actionTypes';
import { UserAccountList } from 'features/admin/types/adminTypes';
import { AppThunk } from 'redux/reducers';
import { ENDPOINT } from 'services/http/constants/endpoints';
import { getDataFromRequest } from 'services/http/functions/getDataFromRequest';
import { HTTPMethods } from 'services/http/types/httpTypes';

const listUsers = (): AppThunk => async (dispatch) => {
	dispatch({
		type: AdminAction.SET_USER_LIST_LOADING,
	});
	const userList: UserAccountList =
		(await dispatch(
			getDataFromRequest(
				ENDPOINT.FUNCTIONS_API.LIST_USERS,
				HTTPMethods.POST,
				getToken(),
			),
		)) || [];

	dispatch({
		type: AdminAction.SET_USER_LIST,
		userList,
	});
};
export default listUsers;
