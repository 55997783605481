import React, { FC, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useViewportScroll } from 'framer-motion';
import { connect, useDispatch } from 'react-redux';
import common_th from 'translations/th/common.json';
import { dropGeneral } from 'features/dragger/actions';
import { AppDispatch, RootState } from 'redux/reducers';
import { Clearbox, Uncheckbox } from 'components/icons';
import { Columns } from '../../types/tableTypes';
import HeaderCell from './cell';
import clearSelect from '../../actions/select/clear';
import { DroppableBorder } from '../body';

interface Props {
	columns: Columns;
	infoExpanded: boolean;
	hasSelection: boolean;
}

const HeaderRow: FC<Props> = ({
	infoExpanded,
	hasSelection,
	columns: {
		id,
		name,
		qty,
		unit,
		raw,
		rawTotal,
		labour,
		labourTotal,
		total,
		note,
	},
}) => {
	const dispatch = useDispatch() as AppDispatch;
	const containerRef = useRef<HTMLDivElement>(null);

	const { scrollY } = useViewportScroll();
	const [fixed, setFixed] = useState(false);

	useLayoutEffect(() => {
		return scrollY.onChange(() => {
			const offsetY = infoExpanded ? 313 : 33;
			const value = scrollY.get() - offsetY;
			if (value > 0) setFixed(true);
			else setFixed(false);
		});
	}, [scrollY, infoExpanded]);

	const handleDrop = () => dispatch(dropGeneral(-1));
	const handleClear = () => hasSelection && dispatch(clearSelect());

	return (
		<>
			<Container
				ref={containerRef}
				style={{
					position: fixed ? 'fixed' : 'absolute',
					top: fixed ? '88px' : 0,
					boxShadow: fixed
						? '0 2px 4px rgba(0, 0, 0, 0.04), 0 8px 16px rgba(0, 0, 0, 0.04)'
						: '',
				}}
				onDrop={handleDrop}
				onDragOver={(e) => e.preventDefault()}>
				<Cell width={id.width} dragId="id">
					{common_th.code}
				</Cell>
				<Cell width={name.width} dragId="name">
					{common_th.name}
				</Cell>
				<Cell width={qty.width} dragId="qty">
					{common_th.quantity}
				</Cell>
				<Cell width={unit.width} dragId="unit">
					{common_th.unit}
				</Cell>
				<Group>
					<GroupLabel>{common_th.material_price}</GroupLabel>
					<Cell width={raw.width} dragId="raw">
						{common_th.price_per_unit}
					</Cell>
					<Cell width={rawTotal.width - 1} dragId="rawTotal">
						{common_th.total_price}
					</Cell>
				</Group>
				<Group>
					<GroupLabel>{common_th.labour_price}</GroupLabel>
					<Cell width={labour.width} dragId="labour">
						{common_th.price_per_unit}
					</Cell>
					<Cell width={labourTotal.width - 1} dragId="labourTotal">
						{common_th.total_price}
					</Cell>
				</Group>
				<Cell width={total.width} dragId="total">
					{common_th.total_labour_and_material_price}
				</Cell>
				<Cell width={note.width} dragId="note">
					{common_th.note}
				</Cell>
				<WhiteBackground />
				<CheckboxWrapper onClick={handleClear}>
					{hasSelection ? (
						<Clearbox size={24} color="var(--neutrals-400)" />
					) : (
						<Uncheckbox size={24} color="var(--neutrals-050)" />
					)}
				</CheckboxWrapper>
			</Container>
			<DroppableBorder
				index={-1}
				onDrop={handleDrop}
				onDragOver={(e) => e.preventDefault()}
			/>
		</>
	);
};

const mapStateToProps = (state: RootState) => ({
	columns: state.table.columns,
	hasSelection: Boolean(state.selection.list.length),
});

export default connect(mapStateToProps)(HeaderRow);

const CheckboxWrapper = styled.button`
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 36px;
	width: 36px;
	border: none;
	outline: none;
	appearance: none;
	background: transparent;

	bottom: -1px;
	left: -36px;

	cursor: pointer;
`;

const WhiteBackground = styled.div`
	position: absolute;
	height: 100%;
	width: 60px;
	left: -61px;
	top: 0;
	background: white;
`;

const Container = styled.div`
	display: inline-flex;
	flex-flow: row nowrap;
	align-items: stretch;
	justify-content: flex-start;

	z-index: 3;

	height: 58px;
	min-height: 29px;
	background: var(--neutrals-050);
	border-bottom: 1px solid var(--neutrals-100);
	border-left: 1px solid var(--neutrals-100);
`;

const Group = styled.div`
	display: grid;
	grid-template-columns: max-content max-content;
	grid-template-rows: 29px 29px;
	border-right: 1px solid var(--neutrals-100);

	& > div {
		border-top: 1px solid var(--neutrals-100);
	}

	& > div:first-of-type {
		grid-column: 1 / 2;
		grid-row: 2 / 3;
	}

	& > div:last-of-type {
		grid-column: 2 / 3;
		grid-row: 2 / 3;
		border-right: none;
	}
`;

const GroupLabel = styled.span`
	grid-area: 1 / 1 / 2 / 3;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.25rem 0.5rem;
	font-size: 0.875rem;
	color: var(--neutrals-500);
`;

const Cell = styled(HeaderCell)`
	font-size: 0.875rem;
	color: var(--neutrals-500);
`;
