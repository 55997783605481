import computeTypeTotal from './computeTypeTotal';
import { DiscountList } from '../types';

function computeTotal(discount: DiscountList) {
	return discount
		.map((t) => computeTypeTotal(t))
		.reduce(
			(a, b) => {
				return {
					original: {
						raw: a.original.raw + b.original.raw,
						labour: a.original.labour + b.original.labour,
						total: a.original.total + b.original.total,
					},
					discounted: {
						raw: a.discounted.raw + b.discounted.raw,
						labour: a.discounted.labour + b.discounted.labour,
						total: a.discounted.total + b.discounted.total,
					},
				};
			},
			{
				original: {
					raw: 0,
					labour: 0,
					total: 0,
				},
				discounted: {
					raw: 0,
					labour: 0,
					total: 0,
				},
			},
		);
}

export default computeTotal;
