import { MutableRefObject, useEffect, useRef } from 'react';

function useOutsideClick(
	ref: MutableRefObject<HTMLElement | null>,
	cb: () => void,
) {
	const fn = useRef(cb);

	useEffect(() => {
		if (cb !== fn.current) {
			fn.current = cb;
		}
	}, [cb]);

	useEffect(() => {
		function handler(event: MouseEvent) {
			if (
				ref &&
				ref.current &&
				!ref.current.contains(event.target as Node)
			) {
				fn.current();
			}
		}
		document.addEventListener('mousedown', handler);
		return () => document.removeEventListener('mousedown', handler);
	}, [ref]);
}

export default useOutsideClick;
