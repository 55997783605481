import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import moment from 'moment';

import DropdownIndicator from 'components/icons/dropdownIndicator';
import { ProjectInfo } from 'features/project/types/projectTypes';
import toThaiDate from 'utilities/functions/toThaiDate';
import useQuery from 'utilities/hooks/useQuery';
import { useSelector } from 'redux/reducers';
import { selectBreadcrumb } from 'features/project/selectors/project';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import { MiniHome } from 'components/icons';

const DISCOUNT_PATH = '/projects/adjust';

interface Props {
	info: ProjectInfo;
	toggled: boolean;
	onClick: () => void;
}

const CompactInfo: FC<Props> = ({
	toggled,
	onClick,
	info: { name, area, date },
}) => {
	const history = useHistory();
	const location = useLocation();
	const query = useQuery();
	const br = useSelector((s) => selectBreadcrumb(s, query));
	const dir = br.split('/');

	const discountPath = matchPath(location.pathname, { path: DISCOUNT_PATH });
	const breadcrumb = dir
		.slice(1, discountPath ? 2 : 3)
		.map((d) => <Breadcrumb key={d}>{d}</Breadcrumb>);

	return (
		<Container>
			<Block>
				<NameText>{dir[0]}</NameText>
				{/* {area ? <Separator /> : null}
				{area ? <LocationText>{area}</LocationText> : null} */}
				<Button toggled={toggled} onClick={onClick}>
					<DropdownIndicator size={26} color="var(--neutrals-500)" />
					<span>{toggled ? 'ปิดรายละเอียด' : 'ดูรายละเอียด'}</span>
				</Button>
			</Block>
			<RoutingText>
				<Breadcrumb>
					<MiniHome
						size={16}
						color="var(--neutrals-500)"
						style={{ cursor: 'pointer' }}
						onClick={() =>
							history.push({
								pathname: '/projects',
								search: `?id=${query.id}`,
							})
						}
					/>
				</Breadcrumb>
				{!breadcrumb.length ? (
					<Breadcrumb>ต้นฉบับ</Breadcrumb>
				) : (
					breadcrumb
				)}
				{!!discountPath && <Breadcrumb>ปรับราคา</Breadcrumb>}
			</RoutingText>
			<Block>
				{date ? (
					<DateText>
						{toThaiDate(moment(date).format('D MMMM YYYY'))}
					</DateText>
				) : null}
			</Block>
		</Container>
	);
};

export default CompactInfo;

const RoutingText = styled.span`
	font-size: 16px;
	font-weight: 500;
	color: var(--neutrals-700);
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	flex-direction: row;
	align-items: center;
	position: absolute;
`;

const Breadcrumb = styled.span`
	font-size: 16px;
	font-weight: 500;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--neutrals-700);

	&:not(:last-child)::after {
		content: '/';
		display: inline-block;
		color: var(--neutrals-500);
		margin: 0 0.25rem;
	}
`;

const Container = styled.div`
	z-index: 1;
	position: sticky;
	top: 2.5rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	height: 3rem;
	padding: 0 1rem;

	width: 100%;

	background-color: white;
	border-bottom: 3px solid var(--neutrals-100);
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.025), 0 4px 8px rgba(0, 0, 0, 0.025),
		0 8px 16px rgba(0, 0, 0, 0.025);
`;

const Block = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	min-height: max-content;

	width: auto;
`;

const NameText = styled.h1`
	font-size: 18px;
	font-weight: 600;
	color: var(--neutrals-900);
`;

// const Separator = styled.span`
// 	font-size: 20px;
// 	font-weight: 500;
// 	color: var(--neutrals-400);
// 	margin: 0 0.5rem;

// 	&::after {
// 		content: '/';
// 	}
// `;

// const LocationText = styled.h2`
// 	font-size: 18px;
// 	font-weight: 400;
// 	color: var(--neutrals-600);
// `;

const DateText = styled.span`
	font-size: 16px;
	font-weight: 400;
	color: var(--neutrals-500);
`;

const Button = styled.button<{ toggled: boolean }>`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 34px;
	width: 8rem;
	margin-left: 0.4rem;
	padding: 0;
	padding-right: 0.4rem;
	font-size: 0.8rem;

	color: var(--neutrals-500);
	background: var(--neutrals-050);
	border-radius: 100px;

	outline: none;
	border: none;
	cursor: pointer;
	transition: all 170ms;

	&:hover {
		opacity: 0.8;
		background: var(--neutrals-100);
	}

	&:active {
		opacity: 0.4;
		transform: scale(0.95);
	}

	& > *:first-child {
		${({ toggled }) => {
			if (toggled) {
				return css`
					transform: rotate(180deg);
				`;
			}

			return css``;
		}}
	}

	& > *:last-child {
		text-justify: center;
	}
`;
