import { MiniHome } from 'components/icons';
import React, { FC, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

export type Props = {
	level: string[];
};

const Intro: FC<Props> = ({ level }) => {
	const history = useHistory();

	return (
		<Fragment>
			<UpperContainer>
				<Header>อุปกรณ์ในฐานข้อมูล</Header>
			</UpperContainer>
			<LowerContainer>
				<MiniHome
					size={18}
					color="var(--neutrals-600)"
					style={{ cursor: 'pointer' }}
					onClick={() => history.push('/tools')}
				/>
				<Breadcrumb>
					{level
						.filter((s) => s)
						.map((s) => (
							<Fragment key={s}>
								<Slash />
								{s}
							</Fragment>
						))}
					<Slash />
				</Breadcrumb>
			</LowerContainer>
		</Fragment>
	);
};

export default Intro;

const UpperContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 1.5rem 2rem;
	background-color: white;
	border-bottom: 1px solid var(--neutrals-100);
`;

const Header = styled.h1`
	font-size: 22px;
	font-weight: 600;
	color: var(--neutrals-800);
	margin-right: 0.5rem;
`;

const Breadcrumb = styled.h2`
	font-size: 16px;
	font-weight: 500;
	color: var(--neutrals-600);
`;

const Slash = styled.span`
	&::before {
		content: '/';
		padding: 0 0.375rem;
		font-size: 16px;
		font-weight: 500;
		color: var(--neutrals-400);
	}

	&:first-of-type::after {
		padding-left: 0;
	}
`;

const LowerContainer = styled(UpperContainer)`
	padding: 0.75rem 2rem;
`;
