import { RootState } from 'redux/reducers';
import { createSelector } from 'reselect';
import { Tab } from 'features/tabs/types';
import { ColumnInfo, ColumnName } from '../types/tableTypes';
import { ProjectRow } from '../types/projectTypes';

export const selectDragging = (s: RootState) => s.table.dragging;

export const selectTableWidth = createSelector<
	RootState,
	Record<ColumnName, ColumnInfo>,
	number
>(
	(s) => s.table.columns,
	(c) =>
		Object.keys(c)
			.map((k) => c[k as ColumnName].width)
			.reduce((a, b) => a + b),
);

export const selectTab = createSelector<RootState, string, Tab, Tab>(
	(s, id) => s.tabs.find((t) => t.id === id)!,
	(t) => t,
);

export const selectReference = createSelector<
	RootState,
	string,
	Tab,
	ProjectRow[]
>(selectTab, (t) => t.reference);

export type RowsSelectorProps = {
	id: string;
	tabId: string;
	ref: boolean;
};

export const selectRows = createSelector<
	RootState,
	RowsSelectorProps,
	ProjectRow[],
	ProjectRow[]
>(
	(s, p) => {
		const { id, tabId, ref } = p;
		const tab = selectTab(s, tabId);

		if (id && tabId) {
			if (ref) {
				return tab ? tab.reference : [];
			}

			return tab ? tab.rows : [];
		}

		return s.project.rows;
	},
	(r) => r,
);
