import firebase from 'firebase/app';
import 'firebase/firestore';
import { ModelSpecifier } from 'features/product/types/productTypes';

const getModels = (
	typeId: string,
	brandId: string,
	cb: (arr: ModelSpecifier[]) => void,
): (() => void) => {
	return (
		firebase
			.firestore()
			.collection(`products/${typeId}/children/${brandId}/children`)
			.where('isRemoved', '==', false)
			// eslint-disable-next-line consistent-return
			.onSnapshot((querySnapshot) => {
				if (!querySnapshot.size) return cb([]);
				const models: ModelSpecifier[] = [];
				querySnapshot.forEach((modelDoc) => {
					const model = modelDoc.data() as ModelSpecifier;
					models.push(model);
				});
				cb(models);
			})
	);
};

export default getModels;
