import { TypeDiscountItem } from '../types';
import computePercentOfValue from './computePercentOfValue';

const computeTypeTotalOriginal = (typeDiscount: TypeDiscountItem) => {
	const typeTotal = typeDiscount.data
		.map((brandDiscount) =>
			brandDiscount.models
				.map((modelDiscount) => [
					modelDiscount.totalPrice.raw,
					modelDiscount.totalPrice.labour,
					computePercentOfValue(
						modelDiscount.totalPrice.raw,
						modelDiscount.discountPercent.raw,
					),
					computePercentOfValue(
						modelDiscount.totalPrice.labour,
						modelDiscount.discountPercent.labour,
					),
				])
				.reduce((sum, curr) => sum.map((sum, idx) => sum + curr[idx])),
		)
		.reduce((sum, curr) => sum.map((sum, idx) => sum + curr[idx]));
	// [ original.raw, original.labour, discounted.raw, discounted.labour ]
	return {
		original: {
			raw: typeTotal[0],
			labour: typeTotal[1],
			total: typeTotal[0] + typeTotal[1],
		},
		discounted: {
			raw: typeTotal[2],
			labour: typeTotal[3],
			total: typeTotal[2] + typeTotal[3],
		},
	};
};

export default computeTypeTotalOriginal;
