import { LoginFields, RegisterFields } from '../types/formTypes';

export const EMPTY_LOGIN_FIELDS: LoginFields = {
	email: '',
	password: '',
};

export const EMPTY_REGISTER_FIELDS: RegisterFields = {
	displayName: '',
	email: '',
	password: '',
	confirmPassword: '',
	company: '',
	phoneNumber: '',
};
