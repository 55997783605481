import React, { FC, useLayoutEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import useQuery from 'utilities/hooks/useQuery';
import { useSelector } from 'redux/reducers';
import { useViewportScroll } from 'framer-motion';

import {
	selectRows,
	selectDragging,
	selectTableWidth,
} from '../selectors/table';
import HeaderRow from './header';
import BodyRow from './body';

interface Props {
	expanded: boolean;
	isTableShown: boolean;
}

const Table: FC<Props> = ({ expanded, isTableShown }) => {
	const query = useQuery();

	const container = useRef<HTMLDivElement>(null);
	const scroll = useRef<HTMLDivElement>(null);
	const adjustmentBar = useRef<HTMLDivElement>(null);

	const rows = useSelector((s) => selectRows(s, query));
	const dragging = useSelector(selectDragging);
	const tableWidth = useSelector(selectTableWidth);

	const { scrollY } = useViewportScroll();

	const [width, setWidth] = useState<number>(document.body.scrollWidth);
	const [start, setStart] = useState<number>(getRowWindow(scrollY.get())[0]);
	const [end, setEnd] = useState<number>(getRowWindow(scrollY.get())[1]);

	useLayoutEffect(() => {
		return scrollY.onChange(() => {
			const [nextStart, nextEnd] = getRowWindow(scrollY.get(), expanded);
			setStart(nextStart);
			setEnd(nextEnd);
		});
	}, [scrollY, expanded]);

	useLayoutEffect(() => {
		const update = () => setWidth(document.body.scrollWidth);
		window.addEventListener('resize', update);
		return () => window.removeEventListener('resize', update);
	}, []);

	useLayoutEffect(() => {
		if (dragging.id) {
			const frame = requestAnimationFrame(() => {
				const { to, min } = dragging;
				const { left } = container.current!.getBoundingClientRect()!;
				const { scrollLeft } = scroll.current!;
				const pageX = to > min ? to : min;
				const offsetX = pageX - left - scrollLeft;
				adjustmentBar.current!.style.transform = `translate(${offsetX}px)`;
			});

			return () => cancelAnimationFrame(frame);
		}

		return undefined;
	}, [dragging]);

	const body = rows
		.map((row, index) => <BodyRow key={row.hash} row={row} index={index} />)
		.filter((_, index) => index >= start && index <= end);

	return (
		<>
			{!isTableShown && (
				<div
					style={{
						marginTop: '4rem',
						color: 'var(--neutrals-500)',
					}}>
					- โปรดกรอกข้อมูลด้านบนให้ครับ -
				</div>
			)}
			<Scroll ref={scroll} width={width} isVisible={isTableShown}>
				<Container
					ref={container}
					style={{
						height: rows.length * 35 + 58 + 88,
						width: tableWidth,
					}}>
					<HeaderRow infoExpanded={expanded} />
					{body}
					<AdjustmentBar ref={adjustmentBar} active={!!dragging.id} />
				</Container>
			</Scroll>
		</>
	);
};

export default Table;

function getRowWindow(
	scrollY: number,
	infoExpanded: boolean = true,
): [number, number] {
	const offset = infoExpanded ? 510 : 188;
	return [
		Math.floor((scrollY - offset) / 35) - 9,
		Math.ceil((scrollY + document.body.clientHeight - offset) / 35) + 9,
	];
}

const Scroll = styled.div<{ width: number; isVisible: boolean }>`
	display: ${(props) => (props.isVisible ? 'block' : 'none')};
	padding-left: 4rem;
	width: 100%;

	/**
	 * Setting overflow-y to auto causes two scrollbar to 
	 * appears which badly affect the UX. 
	 */
	overflow-y: hidden;
	overflow-x: auto;

	&::after {
		content: '';
		display: ${(props) => (props.isVisible ? 'block' : 'none')};
		width: 2rem;
		height: 2rem;
		min-width: 2rem;
		transform: translateX(2rem);

		@media screen and (min-width: 1600px) {
			display: none;
		}
	}

	@media screen and (min-width: 1600px) {
		display: ${(props) => (props.isVisible ? 'flex' : 'none')};
		flex-flow: row;
		justify-content: center;
	}
`;

const Container = styled.div`
	position: relative;
	top: 2rem;
	z-index: 0;

	transition: transform 300ms;
	border-top: 1px solid var(--neutrals-100);
`;

const AdjustmentBar = styled.div<{ active: boolean }>`
	display: flex;
	flex-direction: column;
	align-items: center;

	position: absolute;
	width: 2px;
	height: 100%;
	transition: opacity 150ms;
	background-color: var(--primary-400);

	${({ active }) => {
		if (active) {
			return css`
				opacity: 1;
				pointer-events: auto;
			`;
		}

		return css`
			opacity: 0;
			pointer-events: none;
		`;
	}}
`;
