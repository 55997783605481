import { useLocation } from 'react-router-dom';

export type Query = {
	id: string;
	tabId: string;
	ref: boolean;
};

function useQuery(): Query {
	const s = new URLSearchParams(useLocation().search);

	return {
		id: s.get('id')!,
		tabId: s.get('tabId') || '',
		ref: Boolean(s.has('ref')),
	};
}

export default useQuery;
