/* eslint-disable no-param-reassign */

const range = (start: number, end?: number, step = 1): number[] => {
	const result = [];

	if (typeof end === 'undefined') {
		end = start;
		start = 0;
	}

	for (let i = start; i < end; i += step) {
		result.push(i);
	}

	return result;
};

export default range;
