import * as Sentry from '@sentry/react';

import { AppThunk } from 'redux/reducers';

import { ErrorAction } from 'features/error/types/actionTypes';

const setError = (
	errorMessage: string,
	params: {
		returnHome?: boolean;
		log?: boolean;
		errorHeaderText?: string;
		errorBtnText?: string;
		themeColor?: string;
	} = {
		returnHome: true,
		log: false,
	},
): AppThunk => (dispatch) => {
	const { returnHome, log } = params;

	// Manually log error to sentry
	if (log) Sentry.captureMessage(errorMessage);

	dispatch({
		type: ErrorAction.SET_ERROR,
		errorMessage,
		params: {
			returnHome,
			errorHeaderText: params.errorHeaderText,
			errorBtnText: params.errorBtnText,
			themeColor: params.themeColor,
		},
	});
};

export default setError;
