import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import moment from 'moment';

import { User, UserProject } from 'features/account/user/types/userTypes';
import { Project } from 'features/project/types/projectTypes';
import { store } from 'App';
import { ProjectState } from 'features/project/reducers/project';

let current: ProjectState;
let timeoutId: number;

const subscribe = () => {
	current = store.getState().project;

	const unsubscribe = store.subscribe(async () => {
		const previous = current;
		current = store.getState().project;

		if (current.latestChangesFrom === 'remote') {
			return;
		}

		if (previous !== current) {
			clearTimeout(timeoutId);
			if (previous.id !== current.id) {
				throw new Error('internal error: project id mismatched.');
			}

			const { uid } = store.getState().user;

			if (uid) {
				const userRef = firebase
					.firestore()
					.collection('User')
					.doc(uid);
				const projectRef = firebase
					.firestore()
					.collection('Project')
					.doc(current.id);

				(timeoutId as any) = setTimeout(async () => {
					const changes: Partial<Project> = {};

					const userDoc = await userRef.get();
					const { projects } = userDoc.data() as User;

					const index = projects.findIndex(
						(p) => p.info.id === current.id,
					);

					if (typeof index === 'undefined') {
						throw new Error(
							'authentication failed: project does not belong to the user.',
						);
					}

					const nextProjects: UserProject[] = [
						{
							...projects[index],
							lastOpened: moment().valueOf(),
						},
						...projects.slice(0, index),
						...projects.slice(index + 1),
					];

					if (previous.info !== current.info) {
						changes.info = current.info;
						nextProjects[0].info = {
							...nextProjects[0].info,
							name: current.info.name,
							area: current.info.area,
						};
					}

					userRef.update({
						projects: nextProjects,
					});

					if (previous.tabs !== current.tabs) {
						changes.tabs = current.tabs;
					}

					if (previous.rows !== current.rows && !current.rowsLocked) {
						changes.rows = current.rows;
					}

					projectRef.update(changes);
				}, 750);
			} else {
				throw new Error('authentication failed: user not logged in.');
			}
		}
	});

	return unsubscribe;
};

export default subscribe;
