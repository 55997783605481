import { configureStore } from '@reduxjs/toolkit';
import rootReducer from 'redux/reducers';

const store = (initialState: Object = {}) => {
	return configureStore({
		reducer: rootReducer,
		preloadedState: initialState,
		devTools: {
			shouldHotReload: false,
		},
	});
};

export default store;
