import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

import range from 'utilities/functions/range';
import hash from 'utilities/functions/hash';

import {
	EMPTY_PROJECT,
	EMPTY_PROJECT_ROW,
	EMPTY_PROJECT_DISPLAY_INFO,
} from 'features/project/constants/templates';
import { User } from 'features/account/user/types/userTypes';
import { AppThunk } from 'redux/reducers';
import setError from 'features/error/actions/edit/set.setError';
import { UserAction } from 'features/account/user/types/actionTypes';

const createProject = (): AppThunk<Promise<string>> => async (
	dispatch,
	getState,
) => {
	const { uid } = getState().user;

	if (uid) {
		const userRef = firebase.firestore().collection('User').doc(uid);
		const projectRef = firebase.firestore().collection('Project').doc();

		await firebase.firestore().runTransaction(async (transaction) => {
			const userDoc = await transaction.get(userRef);

			if (userDoc.exists) {
				const { projects } = userDoc.data() as User;

				const updatedProjects: User['projects'] = [
					...projects,
					{
						info: {
							...EMPTY_PROJECT_DISPLAY_INFO,
							id: projectRef.id,
						},
						lastOpened: 0,
					},
				];

				transaction.update(userRef, {
					projects: updatedProjects,
				});

				// updates users projects locally
				dispatch({
					type: UserAction.SET_PROJECTS,
					projects: updatedProjects,
				});

				transaction.set(projectRef, {
					...EMPTY_PROJECT,
					id: projectRef.id,
					rows: range(500).map(() => ({
						...EMPTY_PROJECT_ROW,
						hash: hash(),
					})),
				});

				return;
			}

			dispatch(setError('authentication failed: user not found.'));
		});

		return projectRef.id;
	}

	dispatch(setError('authentication failed: user not logged in.'));

	return '';
};

export default createProject;
