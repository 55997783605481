import { ErrorAction, ErrorActionTypes } from '../types/actionTypes';

interface ErrorState {
	errors: Array<{
		errorMessage: string;
		params: {
			returnHome: boolean;
			log: boolean;
			errorHeaderText?: string;
			errorBtnText?: string;
			themeColor?: string;
		};
	}>;
}

const initialState: ErrorState = {
	errors: [],
};

const errorReducer = (
	state = initialState,
	action: ErrorActionTypes,
): typeof initialState => {
	switch (action.type) {
		case ErrorAction.SET_ERROR: {
			return {
				...state,
				errors: [
					...state.errors,
					{
						errorMessage: action.errorMessage,
						params: {
							...action.params,
						},
					},
				],
			};
		}
		case ErrorAction.REMOVE_ERROR: {
			return {
				...state,
				errors: state.errors.slice(1),
			};
		}
		default: {
			return state;
		}
	}
};

export default errorReducer;
