export type SelectionState = { previous: number; list: number[] };

export enum SelectionActionType {
	INIT = '[SELECTION] INIT',
	SINGLE = '[SELECTION] SINGLE',
	TOGGLE = '[SELECTION] TOGGLE',
	RANGE = '[SELECTION] RANGE',
	CLEAR = '[SELECTION] CLEAR',
}

/**
 * Usually this if for a testing purpose.
 */
export type Init = {
	type: SelectionActionType.INIT;
};

/**
 * This will clear previous selection and select a new one.
 *
 * @keybind {none}
 */
export type Single = {
	type: SelectionActionType.SINGLE;
	index: number;
};

/**
 * This will toggle the selection on that index
 * while keeping the exiting ones as well.
 *
 * @keybind {Ctrl} & {Cmd}
 */

export type Toggle = {
	type: SelectionActionType.TOGGLE;
	index: number;
};

/**
 * Used for range selection. Also, there is no `from` properties
 * because the reducer will automatically figure out where should from be.
 *
 * @keybind {Shift}
 */
export type Range = {
	type: SelectionActionType.RANGE;
	to: number;
};

/**
 * Clear all exiting selections.
 */
export type Clear = {
	type: SelectionActionType.CLEAR;
};

export type SelectionAction = Init | Single | Toggle | Range | Clear;
