import React from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import styled from 'styled-components';

interface Props {
	headingText: string;
	onCloseHandler: () => void;
}

const ModalHeader: React.FC<Props> = ({ headingText, onCloseHandler }) => {
	return (
		<Header>
			<span>{headingText}</span>
			<div>
				<AiOutlineClose onClick={() => onCloseHandler()} />
			</div>
		</Header>
	);
};

const Header = styled.div`
	font-weight: bold;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 1.2rem;
	color: var(--neutrals-100);
	background: var(--neutrals-800);

	& > span:first-child {
		padding-left: 5%;
	}

	& > div:last-child {
		padding-right: 4%;
		display: flex;
		justify-content: center;
		align-items: center;

		& > * {
			&:hover {
				color: var(--warning-400);
				cursor: pointer;
			}
		}
	}
`;

export default ModalHeader;
