import { AccessorMap } from '../types/accessor';

const thaiAccessor: AccessorMap = {
	type: {
		name: 'หมวด',
		example: {
			name: 'เช่น ท่อไฟฟ้า',
			id: 'เช่น 1C',
		},
	},
	brand: {
		name: 'ยี่ห้อ',
		example: {
			name: 'เช่น Thai Yazaki',
			id: 'เช่น 01',
		},
	},
	model: {
		name: 'ประเภท',
		example: {
			name: 'เช่น THW',
			id: 'เช่น 02',
		},
	},
	name: {
		name: 'อุปกรณ์',
		example: {
			name: 'เช่น THW Cable 1 sq.mm',
			id: 'เช่น 001',
		},
	},
};

export default thaiAccessor;
