import { Tab, TabsAction, TabsActionType, TabsState } from './types';

const genesis: TabsState = [];

const tabsReducer = (
	state: TabsState = genesis,
	action: TabsAction,
): TabsState => {
	switch (action.type) {
		case TabsActionType.INIT: {
			return action.tabs;
		}
		case TabsActionType.ADD: {
			return [...state, action.tab];
		}
		case TabsActionType.UPDATE: {
			const { id, tab } = action;
			const i = state.findIndex((x) => x.id === id);

			Object.keys(tab).map(
				(k) =>
					tab[k as keyof Tab] === undefined &&
					delete tab[k as keyof Tab],
			);

			return [
				...state.slice(0, i),
				{
					...state[i],
					...tab,
				},
				...state.slice(i + 1),
			];
		}
		default: {
			return state;
		}
	}
};

export default tabsReducer;
