import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import { AppThunk } from 'redux/reducers';
import { ProjectAction } from 'features/project/types/actionTypes';

const logout = (): AppThunk => (dispatch, getState) => {
	if (getState().user.uid) {
		dispatch({
			type: ProjectAction.RESET_PROJECT,
		});

		const userRef = firebase
			.database()
			.ref(`/users/${getState().user.uid}`);

		userRef.set({
			is_online: false,
			last_seen: firebase.database.ServerValue.TIMESTAMP,
		});
		firebase.auth().signOut();
	}
};

export default logout;
