/* eslint-disable no-case-declarations */
import Ring from 'components/animated/ring';
import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import sortByKey from 'utilities/functions/sort';
import { TableDefinition, TableSelect } from 'utilities/types/table';
import CustomCheckbox from './customCheckbox';

interface Props<T> {
	list: Array<T>;
	indexer: string;
	showLoadingState: boolean;
	tableDefinition: TableDefinition<T>;
	onSelectCallback: (selectedIds: Array<string>) => void;
}

const AdminTable = <T extends { [key: string]: any }>({
	list,
	indexer,
	showLoadingState,
	tableDefinition,
	onSelectCallback,
}: Props<T>) => {
	const [selectedItems, setSelectedItems] = useState<Array<TableSelect>>([]);
	const [selectAllChecked, setSelectAllChecked] = useState(false);
	const [sortObject, setSortObject] = useState({
		sortKey: tableDefinition.sortKey,
		reverse: false,
	});

	const sortedData = React.useMemo(() => {
		const dataArray = list;
		const sortedData = sortByKey(dataArray.slice(), sortObject.sortKey);
		// Need to use slice() to recompute array of same length but different elements
		return sortObject.reverse ? sortedData.reverse() : sortedData;
	}, [list, sortObject]);

	// for selecting one item w/ checkbox behavior (with metakey)
	const handleSelectItem = useCallback(
		(item: T, idx: number) => {
			if (
				selectedItems
					.map((selectedItem) => selectedItem.value)
					.includes(item[indexer])
			) {
				// if found in selected already, remove it
				setSelectedItems(
					selectedItems.filter(
						(selectedItem) => selectedItem.value !== item[indexer],
					),
				);
			} else {
				setSelectedItems([
					...selectedItems,
					{
						value: item[indexer],
						idx,
					},
				]);
			}
		},
		[selectedItems, indexer],
	);

	// for selecting one item w/ radio button behavior (default)
	const handleSelectAndReplace = useCallback(
		(item: T, idx) => {
			if (
				selectedItems
					.map((selectedItem) => selectedItem.value)
					.includes(item[indexer])
			) {
				setSelectedItems([]);
			} else {
				setSelectedItems([
					{
						value: item[indexer],
						idx,
					},
				]);
			}
		},
		[selectedItems, indexer],
	);

	// for selecting multiple items at once (shift-key)
	const handleSelectMultipleItems = useCallback(
		(item: T, idx: number) => {
			let startIdx: number;
			let endIdx: number;
			switch (selectedItems.length) {
				case 0:
					setSelectedItems([
						{
							value: item[indexer],
							idx,
						},
					]);
					break;
				default:
					const selection = [
						...selectedItems.map((item) => item.idx),
					];
					const minSelection = Math.min(...selection);
					const maxSelection = Math.max(...selection);

					startIdx = idx <= minSelection ? idx : maxSelection;
					endIdx = idx >= maxSelection ? idx : minSelection;

					setSelectedItems(
						sortedData
							.map((row, idx) => ({
								...row,
								idx,
							}))
							.filter(
								(_row, idx) => idx >= startIdx && idx <= endIdx,
							)
							.map((row) => ({
								idx: row.idx,
								value: row[indexer],
							})),
					);
			}
		},
		[selectedItems, sortedData, indexer],
	);

	const handleRowClick = useCallback(
		(e: React.MouseEvent, row: T, idx: number) => {
			setSelectAllChecked(false);
			// metaKey = 'command' for MAC, 'windows' for Windows
			// ctrlKey = 'ctrl' for windows
			if (e.metaKey || e.ctrlKey) {
				handleSelectItem(row, idx);
			} else if (e.shiftKey) {
				handleSelectMultipleItems(row, idx);
			} else {
				handleSelectAndReplace(row, idx);
			}
		},
		[handleSelectItem, handleSelectMultipleItems, handleSelectAndReplace],
	);

	const handleColumnClick = useCallback(
		(sortKey?: string) => {
			// conditional in case some don't provide sort key
			if (sortKey) {
				setSortObject({
					reverse:
						sortObject.sortKey === sortKey
							? !sortObject.reverse
							: false,
					sortKey,
				});
			}
		},
		[sortObject],
	);

	const isSelected = useCallback(
		(item: T) =>
			selectedItems.map((item) => item.value).includes(item[indexer]),
		[selectedItems, indexer],
	);

	const handleSelectAll = useCallback(() => {
		if (selectAllChecked) {
			setSelectedItems([]);
			setSelectAllChecked(false);
			return;
		}
		setSelectAllChecked(true);
		setSelectedItems(
			list.map((item, idx) => ({
				value: item[indexer],
				idx,
			})),
		);
	}, [list, indexer, selectAllChecked]);

	const displayedColumns = React.useMemo(() => {
		return (
			<>
				<StyledHeaderCell onClick={() => handleSelectAll()}>
					<CustomCheckbox isChecked={selectAllChecked} />
				</StyledHeaderCell>
				{tableDefinition.columns.map((column) => {
					const key =
						column.accessor +
						(column.subaccessor ? `.${column.subaccessor}` : '');

					return (
						<StyledHeaderCell
							key={key}
							style={{ flex: column.flex || 1 }}
							onClick={() => handleColumnClick(key)}>
							{column.header}
						</StyledHeaderCell>
					);
				})}
			</>
		);
	}, [
		handleSelectAll,
		handleColumnClick,
		selectAllChecked,
		tableDefinition.columns,
	]);

	const displayedDataRows = React.useMemo(() => {
		// processing data of type Lists into a table-friendly format

		return sortedData.map((row, idx) => (
			<StyledTableRow
				key={row[indexer]}
				isSelected={isSelected(row)}
				onClick={(e: React.MouseEvent) => handleRowClick(e, row, idx)}>
				<StyledTableCell
					onClick={(e: React.MouseEvent) =>
						handleRowClick(e, row, idx)
					}>
					<CustomCheckbox isChecked={isSelected(row)} />
				</StyledTableCell>
				{tableDefinition.rowTemplate.map((rowData) => (
					<StyledTableCell
						key={rowData.key}
						style={{ flex: rowData.flex || 1 }}>
						{rowData.accessor(row) || '-'}
					</StyledTableCell>
				))}
			</StyledTableRow>
		));
	}, [
		indexer,
		isSelected,
		handleRowClick,
		tableDefinition.rowTemplate,
		sortedData,
	]);

	useEffect(() => {
		onSelectCallback(selectedItems.map((item) => item.value));
	}, [selectedItems, onSelectCallback]);

	return (
		<Container>
			<RealTable>
				<StyledTableHead>
					<StyledHeaderRow>{displayedColumns}</StyledHeaderRow>
				</StyledTableHead>
				{showLoadingState ? (
					<LoadingContainer>
						<Ring color="var(--accent-500)" />
					</LoadingContainer>
				) : (
					<StyledTableBody>{displayedDataRows}</StyledTableBody>
				)}
			</RealTable>
		</Container>
	);
};

export default AdminTable;

const LoadingContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
`;

const CellCSS = css<{ width?: number }>`
	height: 2rem;
	font-size: 0.9rem;
	overflow: auto;
	width: ${(props) => (props.width ? `${props.width}rem` : '6rem')};
	white-space: nowrap;

	// i.e. checkbox
	&:first-child {
		width: 2.6rem;
	}
`;

const RowCSS = css``;

const FlexCenter = css`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const StyledTableBody = styled.div``;

const StyledTableRow = styled.div<{ isSelected: boolean }>`
	${RowCSS}
	display: flex;
	border-bottom: solid 0.5px var(--neutrals-200);
	background: ${({ isSelected }) =>
		isSelected ? 'var(--accent-050)' : 'inherit'};

	&:hover {
		background: ${({ isSelected }) =>
			isSelected ? 'var(--accent-100)' : 'var(--accent-050)'};
		cursor: pointer;
	}
`;

const StyledTableCell = styled.div<{ width?: number }>`
	${CellCSS}
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--neutrals-600);
`;

const StyledTableHead = styled.div``;

const StyledHeaderRow = styled.div`
	${RowCSS}
	display: flex;
`;

const StyledHeaderCell = styled.div<{ width?: number }>`
	${CellCSS}
	${FlexCenter}
    border-right: solid 1px var(--neutrals-300);
	background: var(--neutrals-200);
	color: var(--neutrals-700);
	font-weight: 600;

	&:hover {
		cursor: pointer;
		background: var(--neutrals-300);
	}

	&:last-child {
		border-right: none;
	}
`;

const RealTable = styled.div`
	display: flex;
	flex-flow: column;
	height: 100%;

	& > * {
		&:first-child {
			min-height: 2rem;
		}
		&:last-child {
			overflow: auto;
		}
	}
`;

const Container = styled.div`
	background: var(--neutrals-050);
`;
