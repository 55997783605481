import { ProjectRow } from 'features/project/types/projectTypes';
import { DiscountList, BrandDiscountItem } from '../types';

const generateDiscountList = (
	// Reinforces type-checking for conditional specifiers property
	rows: ProjectRow[],
) => {
	const discountList: DiscountList = [];
	rows.sort((a, b) => (a.id > b.id ? 1 : -1));
	let typeIndex = 0;
	let brandIndex = 0;
	let modelIndex = 0;
	let tempType = '';
	let tempBrand = '';
	let tempModel = '';

	/**
	 * Here we are firstly generating the discount list
	 * for products with valid id only or has a specifier.
	 * @condition
	 *  - has a valid id
	 */
	const registeredRows = rows.filter((r) =>
		r.id.match(/\d[A-Za-z]\d{5}/g),
	) as Required<ProjectRow>[];

	registeredRows.forEach((row) => {
		const { id, specifiers, price, qty } = row;
		const { type, brand, model } = specifiers;
		const typeId = id.substring(0, 2);
		const brandId = id.substring(2, 4);
		const modelId = id.substring(4, 6);
		const specifierId = typeId + brandId + modelId;
		if (tempType && tempType !== typeId) {
			typeIndex += 1;
			brandIndex = 0;
			modelIndex = 0;
		}
		if (tempType === typeId) {
			if (tempBrand && tempBrand !== brandId) {
				brandIndex += 1;
				modelIndex = 0;
			}
			if (tempBrand === brandId) {
				if (tempModel && tempModel !== modelId) modelIndex += 1;
			}
		}
		if (!discountList[typeIndex])
			discountList.push({
				type,
				data: [],
				discountPercent: {
					raw: 0,
					labour: 0,
				},
				totalPrice: {
					raw: 0,
					labour: 0,
				},
			});
		if (!discountList[typeIndex].data[brandIndex])
			discountList[typeIndex].data.push({
				brand,
				discountPercent: { raw: 0, labour: 0 },
				models: [],
			});
		if (!discountList[typeIndex].data[brandIndex].models[modelIndex])
			discountList[typeIndex].data[brandIndex].models.push({
				model,
				totalPrice: {
					raw: price.raw * qty,
					labour: price.labour * qty,
				},
				discountPercent: { raw: 0, labour: 0 },
				id: specifierId,
			});
		else {
			discountList[typeIndex].data[brandIndex].models[
				modelIndex
			].totalPrice.raw += price.raw * qty;
			discountList[typeIndex].data[brandIndex].models[
				modelIndex
			].totalPrice.labour += price.labour * qty;
		}
		tempType = typeId;
		tempBrand = brandId;
		tempModel = modelId;
	});

	/**
	 * Then we later generate a discount list for custom product
	 * @conditions
	 *  - has price
	 *  - isn't sum row
	 *  - doesn't have a specifiers and,
	 *  - doesn't have a valid id
	 */
	const customRows = rows
		.sort((l, r) => {
			if (l.note === undefined) return 1;
			if (r.note === undefined) return 0;
			return l.note < r.note ? -1 : 1;
		})
		.filter(
			(r) =>
				(r.price.raw || r.price.labour) &&
				!r.price.sum &&
				!r.id.match(/\d[A-Za-z]\d{5}/g),
		) as ProjectRow[];

	if (customRows.length) {
		const brands = [...new Set(customRows.map((r) => r.note))] as string[];
		const bdi: BrandDiscountItem[] = brands.map((b) => ({
			brand: b,
			discountPercent: { raw: 0, labour: 0 },
			models: customRows
				.filter((r) => r.note === b)
				.map((r) => ({
					model: r.name,
					discountPercent: { raw: 0, labour: 0 },
					totalPrice: {
						raw: r.price.raw * r.qty || 0,
						labour: r.price.labour * r.qty || 0,
					},
					id: r.id,
					hash: r.hash,
				})),
		}));

		discountList.push({
			type: 'อื่นๆ',
			data: bdi,
			discountPercent: {
				raw: 0,
				labour: 0,
			},
			totalPrice: {
				raw: 0,
				labour: 0,
			},
		});
	}

	return discountList;
};

export default generateDiscountList;
