import React from 'react';
import styled from 'styled-components';
import { Checkbox, Uncheckbox } from 'components/icons';

interface Props {
	isChecked: boolean;
}

const CustomCheckbox: React.FC<Props> = ({ isChecked }) => {
	return (
		<Container>
			{isChecked ? (
				<Checkbox size={24} color="#334E68" />
			) : (
				<Uncheckbox size={24} color="#334E68" />
			)}
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 1rem;
	height: 1rem;

	&:hover {
		cursor: pointer;
	}
`;

export default CustomCheckbox;
