import React, { ChangeEvent, FormEvent, useState } from 'react';
import styled from 'styled-components';
import { UserRegistrationInfo } from 'features/admin/types/adminTypes';
import { verifyForm } from 'features/admin/functions/form';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'redux/reducers';
import setError from 'features/error/actions/edit/set.setError';
import { ROLE_SELECTION_OPTIONS } from 'features/admin/constants/form';
import Roles from 'features/admin/constants/roles';
import CustomSelect from './customSelect';
import AdminProjectsBrowser from './adminProjectsBrowser';

interface Props {
	onSubmitCallback: (fields: UserRegistrationInfo) => void;
	submitBtnText: string;
	uid?: string;
	originalFields?: UserRegistrationInfo;
	readOnlyMode?: boolean;
}

const AccessForm: React.FC<Props> = ({
	onSubmitCallback,
	submitBtnText,
	uid,
	originalFields,
	readOnlyMode = false,
}) => {
	const [fields, setFields] = useState<UserRegistrationInfo>({
		email: '',
		displayName: '',
		phoneNumber: '',
		company: '',
		password: '',
		role: Roles.USER,
		...originalFields,
	});
	const dispatch = useDispatch<AppDispatch>();
	const userOwnId = useSelector((state: RootState) => state.user.uid);

	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (readOnlyMode) return;

		const uploadedFields = {
			...fields,
		};

		// only upload image to server on submit
		try {
			await verifyForm(uploadedFields, originalFields !== undefined);
			onSubmitCallback(uploadedFields);
		} catch (err) {
			// eslint-disable-next-line no-alert
			dispatch(
				setError(err || 'Error with upload fields', {
					returnHome: false,
				}),
			);
		}
	};

	// 3 types of onchange handlers for normal inputs, TinyMCE editors, and image input

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (readOnlyMode) return;
		setFields({
			...fields,
			[e.target.name]: e.target.value,
		});
	};

	const handleSelect = (value: Roles) => {
		setFields({
			...fields,
			role: value,
		});
	};

	return (
		<StyledForm onSubmit={handleSubmit}>
			<InputGroup>
				<Label>ชื่อ</Label>
				<StyledInput
					name="displayName"
					value={fields.displayName}
					onChange={handleChange}
				/>
			</InputGroup>
			<InputGroup>
				<Label>อีเมล</Label>
				<StyledInput
					name="email"
					type="email"
					value={fields.email}
					onChange={handleChange}
				/>
			</InputGroup>
			<InputGroup>
				<Label>เบอร์โทรศัพท์</Label>
				<StyledInput
					name="phoneNumber"
					value={fields.phoneNumber}
					onChange={handleChange}
				/>
			</InputGroup>
			<InputGroup>
				<Label>ชื่อบริษัท</Label>
				<StyledInput
					name="company"
					value={fields.company}
					onChange={handleChange}
				/>
			</InputGroup>
			<InputGroup>
				<Label>{readOnlyMode ? 'ตำแหน่ง ' : 'เลือกตำแหน่ง'}</Label>
				<StyledSelectContainer>
					<CustomSelect
						options={ROLE_SELECTION_OPTIONS}
						onChangeHandler={handleSelect}
						initialValue={{
							display:
								fields.role[0].toUpperCase() +
								fields.role.slice(1),
							value: fields.role,
						}}
						disabled={readOnlyMode || userOwnId === uid}
					/>
				</StyledSelectContainer>
			</InputGroup>
			{!readOnlyMode && (
				<InputGroup>
					<Label>รหัสผ่าน</Label>
					<StyledInput
						name="password"
						value={fields.password}
						onChange={handleChange}
					/>
				</InputGroup>
			)}
			{!readOnlyMode && (
				<ButtonContainer>
					<StyledButton type="submit">{submitBtnText}</StyledButton>
				</ButtonContainer>
			)}
			{readOnlyMode && (
				<InputGroup>
					<Label>โครงการของบัญชีดังกล่าว</Label>
					<AdminProjectsBrowser uid={uid as string} />
				</InputGroup>
			)}
		</StyledForm>
	);
};

const StyledForm = styled.form`
	overflow: auto;
	padding: 5%;
	color: var(--neutrals-800);

	& > *:last-child {
		margin-bottom: 5%;
	}
`;

const InputGroup = styled.div`
	display: flex;
	flex-flow: column;
	margin-bottom: 16px;
`;

const Label = styled.div`
	font-size: 1rem;
	margin-bottom: 4px;
`;

const StyledInput = styled.input`
	border: solid 1px var(--neutrals-300);
	font-size: 0.95rem;
	height: 1.8rem;
	width: 50%;
	padding-left: 5px;
	background: white;

	&:hover {
		background: var(--neutrals-050);
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	margin-top: 5%;
`;

const StyledSelectContainer = styled.div`
	font-size: 0.95rem;
	height: 1.8rem;
	width: 50%;

	& > * {
		height: 100%;
	}
`;

const StyledButton = styled.button`
	border: none;
	background-image: none;
	background-color: transparent;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	font-size: inherit;
	outline: none;

	display: flex;
	justify-content: center;
	align-items: center;
	width: 40%;
	height: 2.2rem;
	font-weight: 500;
	color: var(--neutrals-700);
	background: var(--neutrals-200);
	font-size: 1rem;

	&:hover {
		cursor: pointer;
		background: var(--neutrals-300);
	}
`;

export default AccessForm;
