import firebase from 'firebase/app';
import { Product } from 'features/product/types/productTypes';
import { Brand, Model, Type } from '../../types/types';
import { Params as ParamsBase } from '../../screen/ToolsPage';

type Params = Partial<ParamsBase>;

export function getPath(p: Params): string[] {
	return [p.typeId, p.brandId, p.modelId, p.productId].filter(
		(s) => s,
	) as string[];
}

export function attachTypes(
	_: unknown,
	cb: (t: Record<string, Type>) => void,
): () => void {
	return firebase
		.firestore()
		.collection('products')
		.where('isRemoved', '==', false)
		.onSnapshot((querySnapshot) => {
			const types: Record<string, Type> = {};

			if (querySnapshot.size) {
				querySnapshot.forEach((typeDoc) => {
					const t = typeDoc.data() as Type;
					types[t.id] = t;
				});
			}

			cb(types);
		});
}

export function attachBrands(
	params: Params,
	cb: (b: Record<string, Brand>) => void,
): () => void {
	const p = getPath(params);

	if (p.length < 1) {
		return () => undefined;
	}

	const path = `products/${p.slice(0, 1).join('/children/')}/children`;

	return firebase
		.firestore()
		.collection(path)
		.where('isRemoved', '==', false)
		.onSnapshot((querySnapshot) => {
			const brands: Record<string, Brand> = {};

			if (querySnapshot.size) {
				querySnapshot.forEach((brandDoc) => {
					const b = brandDoc.data() as Brand;
					brands[b.id] = b;
				});
			}

			cb(brands);
		});
}

export function attachModels(
	params: Params,
	cb: (b: Record<string, Model>) => void,
): () => void {
	const p = getPath(params);

	if (p.length < 2) {
		return () => undefined;
	}

	const path = `products/${p.slice(0, 2).join('/children/')}/children`;

	return firebase
		.firestore()
		.collection(path)
		.where('isRemoved', '==', false)
		.onSnapshot((querySnapshot) => {
			const models: Record<string, Model> = {};
			if (querySnapshot.size) {
				querySnapshot.forEach((modelDoc) => {
					const m = modelDoc.data() as Model;
					models[m.id] = m;
				});
			}

			cb(models);
		});
}

export function attachProducts(
	params: Params,
	cb: (b: Record<string, Product>) => void,
): () => void {
	const p = getPath(params);

	if (p.length < 3) {
		return () => undefined;
	}

	const path = `products/${p.slice(0, 3).join('/children/')}/children`;

	return firebase
		.firestore()
		.collection(path)
		.where('isRemoved', '==', false)
		.onSnapshot((querySnapshot) => {
			const products: Record<string, Product> = {};
			if (querySnapshot.size) {
				querySnapshot.forEach((productDoc) => {
					const p = productDoc.data() as Product;
					products[p.id.slice(6)] = p;
				});
			}

			cb(products);
		});
}
