import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AppDispatch } from 'redux/reducers';
import styled from 'styled-components';

import getUserProjects from 'features/admin/actions/query/query.getUserProjects';
import { UserProject } from 'features/account/user/types/userTypes';

const AdminProjectsBrowser: React.FC<{ uid: string }> = ({ uid }) => {
	const dispatch = useDispatch<AppDispatch>();
	const history = useHistory();

	const [projects, setProjects] = useState<Array<UserProject>>([]);
	const [hasLoaded, setHasLoaded] = useState(false);

	useEffect(() => {
		const asyncCall = async () => {
			const userProjects = await dispatch(getUserProjects(uid));
			setProjects(userProjects);
			setHasLoaded(true);
		};

		asyncCall();
	}, [dispatch, uid]);

	const handleOpenProject = (id: string) => {
		history.push({
			pathname: '/projects',
			search: `?id=${id}`,
		});
	};

	if (!projects.length && hasLoaded) return <div>- ไม่มีโครงการ</div>;

	return (
		<ProjectsWrapper>
			{projects.map((project) =>
				project.info ? (
					<ProjectWrapper
						key={project.info.id}
						onClick={() => handleOpenProject(project.info.id)}>
						<NameText>{project.info.name || 'Untitled'}</NameText>
						{project.info.area ? (
							<>
								<Separator />
								<AreaText>{project.info.area}</AreaText>
							</>
						) : null}
						<Spacer />
						<AdjustPill>
							{project.info.adjustment || 'Originals'}
						</AdjustPill>
					</ProjectWrapper>
				) : null,
			)}
		</ProjectsWrapper>
	);
};

const ProjectsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: flex-start;
	align-content: center;
	border-radius: 8px;

	overflow-y: auto;
	transition: opacity 120ms;

	& > *:not(:first-child) {
		margin-top: 0.75rem;
	}
`;

const ProjectWrapper = styled.button`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;

	z-index: 0;
	position: relative;
	background: var(--neutrals-050);
	padding: 0.875rem 1rem;
	border-radius: 8px;

	text-align: start;
	outline: none;
	border: none;
	appearance: none;

	cursor: pointer;
	transition: all 100ms;

	&:hover {
		background: var(--neutrals-200);
	}

	&:active {
		opacity: 0.4;
	}
`;

const NameText = styled.span`
	font-size: 1rem;
	font-weight: 600;
	color: var(--neutrals-800);
`;

const Separator = styled.span`
	font-size: 20px;
	font-weight: 500;
	color: var(--neutrals-400);
	margin: 0 0.5rem;

	&::after {
		content: '|';
	}
`;

const AreaText = styled.span`
	font-size: 1rem;
	font-weight: 500;
	color: var(--neutrals-500);
`;

const AdjustPill = styled.span`
	display: inline-flex;
	justify-content: center;
	align-items: center;
	padding: 0 0.625rem;
	min-height: 24px;

	font-size: 14px;
	font-weight: 500;
	color: var(--support-800);
	background: var(--support-100);
	border-radius: 100px;

	transition: all 100ms;
`;

const Spacer = styled.div`
	flex-grow: 1;
`;

export default AdminProjectsBrowser;
