import { AdminAction, AdminActionTypes } from '../types/actionTypes';
import { AdminState } from '../types/adminTypes';

const initialState: AdminState = {
	userList: [],
	isUserListLoading: false,
	siteSettings: {
		allowRegistrations: false,
	},
};

const adminReducer = (
	state = initialState,
	action: AdminActionTypes,
): AdminState => {
	switch (action.type) {
		case AdminAction.SET_USER_LIST: {
			return {
				...state,
				userList: [...action.userList],
				isUserListLoading: false,
			};
		}
		case AdminAction.SET_USER_LIST_LOADING: {
			return {
				...state,
				isUserListLoading: true,
			};
		}
		case AdminAction.SET_REGISTRATIONS_ALLOWED: {
			return {
				...state,
				siteSettings: {
					allowRegistrations: action.allowed,
				},
			};
		}
		default: {
			return state;
		}
	}
};

export default adminReducer;
