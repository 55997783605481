import React, {
	FunctionComponent,
	DragEvent,
	useRef,
	MouseEvent,
	useMemo,
	useState,
} from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'redux/reducers';

import { ProjectRow } from 'features/project/types/projectTypes';
import { SelectionAction } from 'features/selection/types';

import { Checkbox, Close, Uncheckbox } from 'components/icons';
import { dropGeneral } from 'features/dragger/actions';
import { DraggerAction, DraggerActionType } from 'features/dragger/type';
import selectToggle from 'features/project/actions/select/toggle';
import selectSingle from 'features/project/actions/select/single';
import selectRange from 'features/project/actions/select/range';
import { selectLock } from 'features/project/selectors/project';

import useQuery from 'utilities/hooks/useQuery';
import DragIndicator from 'components/icons/dragIndicator';
import { makePriceSelector } from 'features/project/selectors/sum';
import duplicateProject from 'features/project/actions/update/update.duplicateProject';
import Portal from 'utilities/components/portal';
import NormalCell from './cell';

interface Props {
	row: ProjectRow;
	index: number;
}

const BodyRow: FunctionComponent<Props> = ({ row, index }) => {
	const query = useQuery();
	const selectPrice = useMemo(() => makePriceSelector(), []);
	const dispatch = useDispatch() as AppDispatch;
	const container = useRef<HTMLDivElement>(null);
	const locked = useSelector(selectLock);
	const [isNoEditModalShown, setNotEditModalShown] = useState(false);

	const selected = useSelector<RootState, boolean>(
		(s) => s.selection.list.includes(index),
		(l, r) => l === r,
	);

	const handleDuplicate = async () => {
		const copyId = await dispatch(duplicateProject());
		const win = window.open(`/projects?id=${copyId}`, '_blank');
		if (win) win.focus();
	};

	const handleGeneralDrop = () => {
		if (locked) {
			setNotEditModalShown(true);
			return;
		}
		dispatch(dropGeneral(index));
	};

	const handleDragStart = (e: DragEvent<HTMLDivElement>, invert: boolean) => {
		if (container.current) {
			if (!invert) {
				e.dataTransfer.setDragImage(container.current, 20, 0);
			} else {
				const { clientWidth } = container.current;
				e.dataTransfer.setDragImage(
					container.current,
					40 + clientWidth,
					0,
				);
			}

			dispatch<DraggerAction>({
				type: DraggerActionType.DRAG_ROW,
				from: index,
			});
		}
	};

	const handleSelect = (e: MouseEvent<HTMLButtonElement>) => {
		if (e.shiftKey) {
			dispatch<SelectionAction>(selectRange(index));
		} else if (e.metaKey || e.ctrlKey) {
			dispatch<SelectionAction>(selectToggle(index));
		} else {
			dispatch<SelectionAction>(selectSingle(index));
		}
	};

	const { price } = row;

	let tRaw = price.raw * row.qty;
	let tLabour = price.labour * row.qty;
	let total = tRaw + tLabour;

	const sum = useSelector<
		RootState,
		undefined | ReturnType<typeof selectPrice>
	>((s) =>
		price.sum ? selectPrice(s, { query, list: price.sum }) : undefined,
	);

	if (sum) {
		tRaw = sum.raw;
		tLabour = sum.labour;
		total = sum.both;
	}

	return (
		<>
			<DroppableRow
				ref={container}
				index={index}
				onDrop={handleGeneralDrop}
				style={{ fontWeight: sum ? 600 : 400 }}
				onDragOver={(e) => e.preventDefault()}>
				<NormalCell
					onLockedCellEdit={() => setNotEditModalShown(true)}
					index={index}
					id="id"
					value={row.id}
				/>
				<NormalCell
					onLockedCellEdit={() => setNotEditModalShown(true)}
					index={index}
					id="name"
					value={row.name}
				/>
				<NormalCell
					onLockedCellEdit={() => setNotEditModalShown(true)}
					index={index}
					id="qty"
					value={row.qty}
				/>
				<NormalCell
					onLockedCellEdit={() => setNotEditModalShown(true)}
					index={index}
					id="unit"
					value={row.unit}
				/>
				<NormalCell
					onLockedCellEdit={() => setNotEditModalShown(true)}
					index={index}
					id="raw"
					value={price.raw}
				/>
				<NormalCell
					onLockedCellEdit={() => setNotEditModalShown(true)}
					index={index}
					id="rawTotal"
					value={tRaw}
				/>
				<NormalCell
					onLockedCellEdit={() => setNotEditModalShown(true)}
					index={index}
					id="labour"
					value={price.labour}
				/>
				<NormalCell
					onLockedCellEdit={() => setNotEditModalShown(true)}
					index={index}
					id="labourTotal"
					value={tLabour}
				/>
				<NormalCell
					onLockedCellEdit={() => setNotEditModalShown(true)}
					index={index}
					id="total"
					value={total}
				/>
				<NormalCell
					onLockedCellEdit={() => setNotEditModalShown(true)}
					index={index}
					id="note"
					value={row.note}
				/>
				<LeftHandle
					draggable
					onDragStart={(e) => handleDragStart(e, false)}>
					<VerticalDragIndicator
						size={20}
						color="var(--neutrals-100)"
					/>
				</LeftHandle>
				<RightHandle
					draggable
					onDragStart={(e) => handleDragStart(e, true)}>
					<VerticalDragIndicator
						size={20}
						color="var(--neutrals-100)"
					/>
				</RightHandle>
				<CheckboxWrapper onClick={handleSelect}>
					{selected ? (
						<Checkbox size={24} color="var(--primary-700)" />
					) : (
						<Uncheckbox size={24} color="var(--checkbox)" />
					)}
				</CheckboxWrapper>
			</DroppableRow>
			<DroppableBorder
				index={index}
				onDrop={handleGeneralDrop}
				onDragOver={(e) => e.preventDefault()}
			/>
			{isNoEditModalShown && (
				<Portal>
					<Backdrop>
						<WarningModal>
							<WarningTitle>คำเตือน</WarningTitle>
							<CloseButton
								onClick={() => setNotEditModalShown(false)}>
								<Close size={26} color="var(--neutrals-300)" />
							</CloseButton>
							<WarningText>
								คุณไม่สามารถแก้ไขโครงการที่มีการปรับราคาไปแล้ว
								หากคุณประสงค์ที่จะแก้ไขโปรดลบการปรับราคาทั้งหมด
								หรือคัดลอกต้นฉบับของโครงการนี้ไปยังโครงการใหม่
							</WarningText>
							<PrimaryButton onClick={handleDuplicate}>
								คัดลอกไปยังโครงการใหม่
							</PrimaryButton>
							<NeutralButton
								onClick={() => setNotEditModalShown(false)}>
								ยกเลิก
							</NeutralButton>
						</WarningModal>
					</Backdrop>
				</Portal>
			)}
		</>
	);
};

export default BodyRow;

const LeftHandle = styled.div`
	display: grid;
	grid-template-columns: 20px 32px;
	align-items: center;
	justify-items: center;
	left: -51px;
	top: -1px;

	position: absolute;
	height: 36px;
	width: 50px;
	background: white;
	border: 1px solid var(--neutrals-200);
	border-right-width: 0;
	border-radius: 4px 0 0 4px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04);
	cursor: grab;

	&:active {
		cursor: grabbing;
	}
`;

const RightHandle = styled(LeftHandle)`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20px;
	height: 36px;
	left: auto;
	right: -20px;
	border-radius: 0 4px 4px 0;
	border-left-width: 0;
	border-right-width: 1px;
`;

interface RowUIProps {
	index: number;
}

export const DroppableRow = styled.div.attrs<RowUIProps>((p) => ({
	style: {
		transform: `translateY(${p.index * 35 + 58}px)`,
	},
}))<RowUIProps>`
	position: absolute;
	display: inline-flex;
	flex-flow: row nowrap;
	align-items: stretch;
	justify-content: flex-start;
	background: white;
	height: 34px;
	min-height: 34px;
	border-left: 1px solid var(--neutrals-100);
	--checkbox: var(--neutrals-050);

	&:hover {
		--checkbox: var(--neutrals-200);
	}

	& > ${LeftHandle}, & > ${RightHandle} {
		display: none;
	}

	&:hover > ${LeftHandle} {
		display: grid;
	}

	&:hover > ${RightHandle} {
		display: flex;
	}
`;

interface BorderUIProps {
	index: number;
}

export const DroppableBorder = styled.div.attrs<BorderUIProps>((p) => ({
	style: {
		transform: `translateY(${(p.index + 1) * 35 + 57}px)`,
	},
}))<BorderUIProps>`
	display: block;
	height: 1px;
	background: var(--neutrals-100);
	position: absolute;
	width: 100%;
	z-index: 2;

	&::after {
		content: '';
		z-index: 99;
		display: block;
		position: absolute;
		height: 5px;
		width: 100%;
		background: var(--support-500);
		transform: translateY(-2px);
		opacity: 0;
	}

	&.targeted::after {
		opacity: 1;
	}
`;

const VerticalDragIndicator = styled(DragIndicator)`
	transform: rotate(90deg);
`;

const CheckboxWrapper = styled.button`
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 36px;
	width: 36px;
	border: none;
	outline: none;
	appearance: none;
	background: transparent;

	top: -1px;
	left: -36px;

	cursor: pointer;
`;

const PrimaryButton = styled.button`
	outline: none;
	border: none;
	appearance: none;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	height: 36px;
	color: var(--primary-600);
	background: var(--primary-050);
	border-radius: 6px;
	font-size: 16px;
	font-weight: 500;
	margin: 0 1rem 0.5rem;
	cursor: pointer;

	&:hover {
		background: var(--primary-100);
	}
`;
const CloseButton = styled.button`
	outline: none;
	border: none;
	background: transparent;
	appearance: none;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
	position: absolute;
	right: 10px;
	top: 10px;
	border-radius: 100px;
	cursor: pointer;

	&:hover {
		background: rgba(0, 0, 0, 0.07);
	}

	&:active {
		background: rgba(0, 0, 0, 0.09);
	}
`;

const NeutralButton = styled(PrimaryButton)`
	color: var(--neutrals-600);
	background: var(--neutrals-050);
	margin-bottom: 1rem;
	&:hover {
		background: var(--neutrals-100);
	}
`;

const Backdrop = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: ${document.body.clientWidth}px;
	height: ${document.body.clientHeight}px;
	background: rgba(0, 0, 0, 0.6);
`;

const WarningModal = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	padding: 0;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 4px 8px rgba(0, 0, 0, 0.05),
		0 16px 32px rgba(0, 0, 0, 0.05);
	background: white;
	transform: translate(-50%, -50%);
	width: 300px;
	border-radius: 8px;
	overflow: hidden;
`;

const WarningTitle = styled.div`
	font-size: 20px;
	font-weight: 600;
	color: var(--neutrals-800);
	padding: 0.75rem 1rem;
	background: var(--neutrals-050);
`;

const WarningText = styled.div`
	font-size: 14px;
	font-weight: 400;
	color: var(--neutrals-500);
	padding: 1rem 1rem 0.75rem;
	line-height: 1.5;
`;
