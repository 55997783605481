import React, { FC } from 'react';

interface Props {
	size?: number;
	color?: string;
	[prop: string]: any;
}

const DragIndicator: FC<Props> = ({
	size = 24,
	color = 'white',
	...delegated
}) => {
	return (
		<svg
			width={`${size}px`}
			height={`${size}px`}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...delegated}>
			<path d="M7 10L12 15L17 10H7Z" fill={color} />
		</svg>
	);
};

export default DragIndicator;
