import { Product, ProductPrice } from 'features/product/types/productTypes';
import {
	Project,
	ProjectRow,
	Specifiers,
	DiscountListWithInfo,
	ProjectTab,
	ProjectInfo,
} from './projectTypes';

/**
 * @TODO
 * 1. Batch move
 * 2. Summing using edit price
 */
export enum ProjectAction {
	/**
	 * @core project metadata
	 */
	INIT_PROJECT = '[PROJECT] INIT_PROJECT',
	SET_PROJECT = '[PROJECT] SET_PROJECT',
	RESET_PROJECT = '[PROJECT] RESET_PROJECT',
	SET_PROJECT_INFO = '[PROJECT] SET_PROJECT_INFO',
	SET_INFO = '[PROJECT] SET_INFO',

	/**
	 * @core row manipulation
	 */
	SET_ROWS = '[PROJECT] SET_ROWS',
	EDIT_ROW = '[PROJECT] EDIT_ROW',
	EDIT_PRICE = '[PROJECT] EDIT_PRICE',
	MOVE_PRODUCT = '[PROJECT] MOVE_PRODUCT',
	BATCH_MOVE = '[PROJECT] BATCH_MOVE',

	/**
	 * @core project discount
	 */
	ADD_PROJECT_DISCOUNT = '[PROJECT] ADD_PROJECT_DISCOUNT',
	REMOVE_PROJECT_DISCOUNT = '[PROJECT] REMOVE_PROJECT_DISCOUNT',
	SET_PROJECT_CURRENT_DISCOUNT = '[PROJECT] SET_PROJECT_CURRENT_DISCOUNT',

	/**
	 * @core project tabs manager
	 */
	SET_TAB = '[PROJECT] SET_TAB',
	ADD_TAB = '[PROJECT] ADD_TAB',
	REMOVE_TAB = '[PROJECT] REMOVE_TAB',

	/**
	 * @utility for debouncing algorithms
	 */
	SET_LATEST_CHANGES_FROM = '[PROJECT] SET_CHANGE_FROM',

	/**
	 * @utility for blocking changes to rows while in read-only tab
	 */
	LOCK_ROWS = '[PROJECT] LOCK_ROWS',
	UNLOCK_ROWS = '[PROJECT] UNLOCK_ROWS',

	/**
	 * @deprecated
	 */
	ADD_ROW = '[PROJECT@DEP] ADD_ROW',
	INSERT_PRODUCT = '[PROJECT@DEP]INSERT_PRODUCT',
}

type LatestChangesFrom = 'local' | 'remote';

// Project

interface SetProject {
	type: ProjectAction.SET_PROJECT;
	project: Project;
}

interface ResetProject {
	type: ProjectAction.RESET_PROJECT;
}

interface SetProjectInfo {
	type: ProjectAction.SET_PROJECT_INFO;
	property: string;
	value: string;
}

interface AddTab {
	type: ProjectAction.ADD_TAB;
	tab: ProjectTab;
}

interface RemoveTab {
	type: ProjectAction.REMOVE_TAB;
	id: string;
}

interface SetTab {
	type: ProjectAction.SET_TAB;
	tabs: ProjectTab[];
}

interface AddProjectDiscount {
	type: ProjectAction.ADD_PROJECT_DISCOUNT;
	discountListWithInfo: DiscountListWithInfo;
}

interface RemoveProjectDiscount {
	type: ProjectAction.REMOVE_PROJECT_DISCOUNT;
	name: string;
}

interface SetLatestChangesFrom {
	type: ProjectAction.SET_LATEST_CHANGES_FROM;
	from: LatestChangesFrom;
}

export interface SetRows {
	type: ProjectAction.SET_ROWS;
	rows: ProjectRow[];
}

interface EditRow {
	type: ProjectAction.EDIT_ROW;
	index: number;
	mutation: Partial<ProjectRow>;
}

interface EditPrice {
	type: ProjectAction.EDIT_PRICE;
	index: number;
	mutation: Partial<ProductPrice>;
}

interface BatchMove {
	type: ProjectAction.BATCH_MOVE;
	to: number;
	list: number[];
}

// For testing purpose

interface InitProject {
	type: ProjectAction.INIT_PROJECT;
}

// Under considering of deprecating these actions

interface AddRow {
	type: ProjectAction.ADD_ROW;
	index: number;
	options: {
		insertPrev: boolean;
	};
}

interface InsertProduct {
	type: ProjectAction.INSERT_PRODUCT;
	product: Product;
	index: number;
	specifiers?: Specifiers;
}

interface MoveProduct {
	type: ProjectAction.MOVE_PRODUCT;
	from: number;
	to: number;
}

interface LockRows {
	type: ProjectAction.LOCK_ROWS;
}

interface UnlockRows {
	type: ProjectAction.UNLOCK_ROWS;
}

interface SetInfo {
	type: ProjectAction.SET_INFO;
	info: ProjectInfo;
}

export type ProjectActionTypes =
	| SetLatestChangesFrom
	| SetProject
	| ResetProject
	| SetProjectInfo
	| SetInfo
	// Tabs Manager
	| SetTab
	| AddTab
	| RemoveTab
	// Discount feature
	| AddProjectDiscount
	| RemoveProjectDiscount
	// Row Manipulation
	| SetRows
	| EditRow
	| EditPrice
	| MoveProduct
	| BatchMove
	// For Readonly Table
	| LockRows
	| UnlockRows
	// For testing purpose.
	| InitProject
	// Considering deprecating these.
	| InsertProduct
	| AddRow;
