import firebase from 'firebase/app';
import 'firebase/firestore';
import { BrandSpecifier } from 'features/product/types/productTypes';

const getBrands = (
	typeId: string,
	cb: (arr: BrandSpecifier[]) => void,
): (() => void) => {
	return (
		firebase
			.firestore()
			.collection(`products/${typeId}/children`)
			.where('isRemoved', '==', false)
			// eslint-disable-next-line consistent-return
			.onSnapshot((querySnapshot) => {
				if (!querySnapshot.size) return cb([]);
				const brands: BrandSpecifier[] = [];
				querySnapshot.forEach((brandDoc) => {
					const brand = brandDoc.data() as BrandSpecifier;
					brands.push(brand);
				});
				cb(brands);
			})
	);
};

export default getBrands;
