import React, { FC } from 'react';
import styled from 'styled-components';
import Wave from 'components/animated/wave';

const LoadingRoute: FC = () => {
	return (
		<Container>
			<Wave color="var(--accent-500)" />
			<Text>Loading</Text>
		</Container>
	);
};

export default LoadingRoute;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
`;

const Text = styled.span`
	margin-top: 2.25rem;
	font-size: 16px;
	font-weight: 600;
	color: var(--neutrals-500);
`;
