import firebase from 'firebase/app';
import 'firebase/firestore';

import { AppThunk } from 'redux/reducers';
import Roles from 'features/admin/constants/roles';
import setError from 'features/error/actions/edit/set.setError';
import { ProjectAction } from 'features/project/types/actionTypes';
import { Project } from 'features/project/types/projectTypes';
import { Tab, TabsActionType, TabsInit } from 'features/tabs/types';

const attachProject = (id: string): AppThunk<Promise<any>> => async (
	dispatch,
	getState,
) => {
	// can use user from getState because authListener always protects it
	const { user } = getState();

	if (!user) {
		dispatch(setError('ท่านไม่ได้เข้าสู่ระบบ'));
		return;
	}

	// project only opens if user has project or role is admin
	if (
		!user.projects.find((project) => project.info?.id === id) &&
		user.role !== Roles.ADMIN
	) {
		dispatch(setError('ท่านไม่มีโครงการดังกล่าว'));
		return;
	}

	if (!id) {
		dispatch(setError('เกิดข้อผิดพลาดกับ id โครงการ'));
		return;
	}

	const projectRef = firebase.firestore().collection('Project').doc(id);
	const tabsRef = projectRef.collection('Tabs');

	dispatch({
		type: ProjectAction.UNLOCK_ROWS,
	});

	const detachProject = projectRef.onSnapshot((projectDoc) => {
		if (projectDoc.exists) {
			if (projectDoc.metadata.hasPendingWrites) {
				return;
			}

			dispatch({
				type: ProjectAction.SET_LATEST_CHANGES_FROM,
				from: 'remote',
			});

			const project = projectDoc.data() as Project;

			dispatch({
				type: ProjectAction.SET_PROJECT,
				project,
			});

			if (!user.projects.find((project) => project.info?.id === id)) {
				dispatch({
					type: ProjectAction.LOCK_ROWS,
				});
			}
		} else {
			dispatch(setError('โครงการดังกล่าวไม่ได้มีอยู่หรือถูกลบไปแล้ว'));
		}
	});

	const detachTabs = tabsRef.onSnapshot((querySnapshot) => {
		try {
			if (querySnapshot.metadata.hasPendingWrites) {
				return;
			}

			if (!user.projects.find((project) => project.info?.id === id)) {
				return;
			}

			// No Tabs
			if (querySnapshot.size === 0) {
				dispatch({
					type: ProjectAction.UNLOCK_ROWS,
				});
				return;
			}

			const tabs: Tab[] = [];

			querySnapshot.forEach((tabDoc) => {
				const tab = tabDoc.data() as Tab;
				tabs.push(tab);
			});

			dispatch<TabsInit>({
				type: TabsActionType.INIT,
				tabs,
			});

			dispatch({
				type: ProjectAction.LOCK_ROWS,
			});
		} catch (e) {
			dispatch(setError('เกิดข้อผิดพลากกับการโหลดแท็บ'));
		}
	});

	// eslint-disable-next-line consistent-return
	return () => {
		// Clear discount tabs on project unmount
		dispatch({
			type: TabsActionType.INIT,
			tabs: [],
		});
		detachProject();
		detachTabs();
	};
};

export default attachProject;
