import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { RootState } from 'redux/reducers';

import removeError from '../actions/edit/set.removeError';
import setError from '../actions/edit/set.setError';
import ErrorMessage from './ErrorMessage';

class ErrorBoundary extends Component<PropsFromRedux & RouteComponentProps> {
	componentDidCatch(error: Error) {
		Sentry.captureException(error);
		this.props.setError(error.message);
	}

	handleAcceptError = () => {
		const { removeError, history, errors } = this.props;
		removeError();
		if (errors[0]?.params.returnHome) history.push('/welcome');
	};

	render() {
		const { children, errors } = this.props;

		return (
			<>
				{children}
				{errors[0]?.errorMessage && (
					<ErrorMessage
						errorMessage={errors[0]?.errorMessage}
						errorBtnText={errors[0]?.params.errorBtnText}
						errorHeaderText={errors[0]?.params.errorHeaderText}
						themeColor={errors[0]?.params.themeColor}
						onAcceptError={this.handleAcceptError}
					/>
				)}
			</>
		);
	}
}

const stateMap = (state: RootState) => ({
	errors: state.error.errors,
});

const actionCreators = {
	setError,
	removeError,
};

const connector = connect(stateMap, actionCreators);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(ErrorBoundary));
