import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/reducers';
import styled from 'styled-components';

import setError from 'features/error/actions/edit/set.setError';
import { verifyForm } from 'features/project/functions/form';
import common_th from 'translations/th/common.json';

import RedAsterisk from 'utilities/components/redAsterisk';
import { RiArrowGoBackLine } from 'react-icons/ri';
import { IoCheckboxOutline } from 'react-icons/io5';
import { ProjectInfo } from '../../types/projectTypes';

interface Props {
	info: ProjectInfo;
	readOnly: boolean;
	onChange: (info: ProjectInfo) => void;
}

const ExpandedInfo: FC<Props> = ({ info, readOnly, onChange }) => {
	const [infoField, setInfoField] = useState<ProjectInfo>(info);
	const [editing, setEditing] = useState(false);
	const [noEditingError, setNoEditingError] = useState(false);
	const {
		area,
		date,
		contractor,
		contactInfo,
		name,
		organization,
	} = infoField;

	const dispatch = useDispatch<AppDispatch>();

	const handleEdit = (e: React.FormEvent<HTMLInputElement>) => {
		if (readOnly) {
			window.setTimeout(() => {
				setNoEditingError(false);
			}, 500);
			if (!noEditingError) {
				dispatch(
					setError('ท่านไม่ใช่เจ้าของโครงการนี้', {
						returnHome: false,
					}),
				);
				setNoEditingError(true);
			}
			return;
		}
		!editing && setEditing(true);
		const p = e.currentTarget.name;
		const v = e.currentTarget.value;
		setInfoField({
			...infoField,
			[p]: v,
		});
	};

	const handleCancel = () => {
		setEditing(false);
		setInfoField(info);
	};

	const handleConfirm = async () => {
		try {
			await verifyForm(infoField);
			setEditing(false);
			dispatch(
				setError('ท่านกรอกข้อมูลสำเร็จ', {
					errorHeaderText: 'โปรดทราบ',
					errorBtnText: 'ตกลง',
					themeColor: 'var(--primary-800)',
				}),
			);
			onChange(infoField);
		} catch (err) {
			dispatch(setError(err, { returnHome: false }));
			// setInfoField(info);
		}
	};

	return (
		<Container onSubmit={(e) => e.preventDefault()}>
			<InputGroup>
				<Label>
					{common_th.project}
					<RedAsterisk />
				</Label>
				<Input
					type="text"
					name="name"
					value={name}
					onChange={handleEdit}
				/>
				<Label>
					{common_th.location}
					<RedAsterisk />
				</Label>
				<Input
					type="text"
					name="area"
					value={area}
					onChange={handleEdit}
				/>
				<Label>
					{common_th.date}
					<RedAsterisk />
				</Label>
				<Input
					type="date"
					name="date"
					value={date}
					onChange={handleEdit}
				/>
			</InputGroup>
			<InputGroup>
				<Label>
					{common_th.organization}
					<RedAsterisk />
				</Label>
				<Input
					type="text"
					name="organization"
					value={organization}
					onChange={handleEdit}
				/>
				<Label>
					{common_th.contractor}
					<RedAsterisk />
				</Label>
				<Input
					type="text"
					name="contractor"
					value={contractor}
					onChange={handleEdit}
				/>
				<Label>
					{common_th.contactInfo}
					<RedAsterisk />
				</Label>
				<Input
					type="text"
					name="contactInfo"
					value={contactInfo}
					onChange={handleEdit}
				/>
			</InputGroup>
			<InstructionText>
				<RedAsterisk />
				<span>
					โปรดกรอกข้อมูลให้ครบทุกช่อง หากข้อมูลไม่ครบ
					<br />
					ทาง<strong>ระบบจะไม่อนุญาติให้ทำการแก้ไข</strong>โครงการ
				</span>
			</InstructionText>
			<ButtonWrapper>
				<CancelButton
					type="button"
					style={{
						pointerEvents: !editing ? 'none' : 'auto',
						color: !editing ? 'var(--neutrals-200)' : undefined,
					}}
					onClick={handleCancel}>
					<RiArrowGoBackLine />
					ยกเลิกการแก้ไข
				</CancelButton>
				<ConfirmButton
					onClick={handleConfirm}
					type="submit"
					style={
						!editing
							? {
									color: 'var(--neutrals-050)',
									background: 'var(--neutrals-300)',
									pointerEvents: 'none',
							  }
							: undefined
					}>
					<IoCheckboxOutline />
					ยืนยันข้อมูล
				</ConfirmButton>
			</ButtonWrapper>
		</Container>
	);
};

export default ExpandedInfo;

const InstructionText = styled.div`
	display: flex;
	color: var(--neutrals-500);
	font-size: 0.8rem;

	& > *:first-child {
		margin-right: 0.2rem;
	}
`;

const ButtonWrapper = styled.div`
	grid-column: 2 / 3;
	display: flex;
	height: auto;
	flex-direction: row;
	align-items: stretch;
	justify-content: flex-end;
`;

const ConfirmButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	outline: none;
	border: none;
	appearance: none;
	background: var(--primary-800);
	border-radius: 8px;
	font-size: 16px;
	font-weight: 600;
	color: white;
	height: 2.2rem;
	padding: 0 1rem;
	transition: all 150ms;
	width: 9rem;

	* {
		&:first-child {
			margin-right: 0.5rem;
		}
	}

	&:hover {
		cursor: pointer;
		background: var(--primary-600);
	}

	&:active {
		background: var(--primary-500);
	}
`;

const CancelButton = styled(ConfirmButton)`
	background: var(--neutrals-100);
	color: var(--neutrals-500);
	margin-right: 1rem;
	width: 11rem;

	&:hover {
		background: var(--neutrals-200);
	}

	&:active {
		background: var(--neutrals-200);
	}
`;

const Container = styled.form`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr auto;
	grid-gap: 1rem;

	width: 100%;
	padding: 1rem;

	padding-bottom: 2rem;
	background-color: var(--neutrals-050);

	@media screen and (min-width: 1024px) {
		padding: 1rem max(1.5rem, calc(50vw - 512px));
		padding-bottom: 2rem;
	}
`;

const InputGroup = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;

	& > *:not(:first-child) {
		margin-top: 0.5rem;
	}
`;

const Input = styled.input`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0.5rem 1rem;

	font-size: 1rem;
	color: var(--neutrals-900);
	min-height: 3rem;

	border-radius: 4px;
	background-color: var(--neutrals-100);

	outline: none;
	border: none;
	appearance: none;

	transition: border 300ms ease;
	border: 2px solid rgba(0, 0, 0, 0);

	&:focus {
		border: 2px solid var(--support-500);
	}

	&:invalid {
		border: 2px solid var(--danger-500);
	}
`;

const Label = styled.label`
	font-size: 12px;
	font-weight: 500;
	color: var(--neutrals-400);

	position: relative;
	bottom: -0.125rem;
`;
