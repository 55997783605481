import React, { FC } from 'react';

interface Props {
	size?: number;
	color?: string;
	[prop: string]: any;
}

const Close: FC<Props> = ({ size = 24, color = 'white', ...delegated }) => {
	return (
		<svg
			width={`${size}px`}
			height={`${size}px`}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...delegated}>
			<path
				d="M19.5 6.41L18.09 5L12.5 10.59L6.91 5L5.5 6.41L11.09 12L5.5 17.59L6.91 19L12.5 13.41L18.09 19L19.5 17.59L13.91 12L19.5 6.41Z"
				fill={color}
			/>
		</svg>
	);
};

export default Close;
