import { Query } from 'utilities/hooks/useQuery';
import { RootState } from 'redux/reducers';
import { ProjectRow } from 'features/project/types/projectTypes';
import { createSelector } from 'reselect';
import { DiscountList } from './types';
import generateDiscountList from './functions/generateDiscountList';

export const selectDiscountList = createSelector<
	RootState,
	Query,
	ProjectRow[],
	DiscountList | undefined,
	DiscountList
>(
	(s) => [...s.project.rows],
	(s, q) => s.tabs.find((x) => x.id === q.tabId)?.discount,
	(r, d) => d || generateDiscountList(r),
);
