export const SENTRY_DSN =
	'https://904ca05f36434dba8bbe9dbacbf73c17@o394986.ingest.sentry.io/5363039';

export const ENVIRONMENT = process.env.NODE_ENV;

export const FIREBASE_CONFIG = {
	apiKey: 'AIzaSyC3h1l15vwdhn23YkKuDBS6DuOHtwxUt5o',
	authDomain: 'sampon-erp.firebaseapp.com',
	projectId: 'sampon-erp',
	storageBucket: 'gs://sampon-erp.appspot.com/',
	databaseURL: 'https://sampon-erp.firebaseio.com',
};
