import { Product, SpecifierType } from 'features/product/types/productTypes';

export enum DraggerType {
	IDLE = 'IDLE',
	SUM = 'SUM',
	ROW = 'ROW',
	PRODUCT = 'PRODUCT',
}

export interface DraggerIdle {
	type: DraggerType.IDLE;
}

export interface DraggerProduct {
	type: DraggerType.PRODUCT;
	product: Product;
	specifiers: Record<SpecifierType, string>;
}

export interface DraggerRow {
	type: DraggerType.ROW;
	from: number;
}

export interface DraggerSum {
	type: DraggerType.SUM;
	list: number[];
}

export type DraggerState =
	| DraggerIdle
	| DraggerProduct
	| DraggerRow
	| DraggerSum;

export enum DraggerActionType {
	INIT = '[DRAGGER] INIT',
	DROP = '[DRAGGER] DROP',
	DRAG_ROW = '[DRAGGER] DRAG_ROW',
	DRAG_PRODUCT = '[DRAGGER] DRAG_PRODUCT',
	DRAG_SUM = '[DRAGGER] DRAG_SUM',
}

export interface DragProduct {
	type: DraggerActionType.DRAG_PRODUCT;
	product: Product;
	specifiers: Record<SpecifierType, string>;
}

export interface DragRow {
	type: DraggerActionType.DRAG_ROW;
	from: number;
}

export interface DragSum {
	type: DraggerActionType.DRAG_SUM;
	list: number[];
}

export interface Drop {
	type: DraggerActionType.DROP;
}

export type DraggerAction = DragProduct | DragRow | DragSum | Drop;
