import React, { FC, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { DraggerAction, DraggerActionType } from 'features/dragger/type';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/reducers';
import { Product } from '../types/productTypes';

interface Props {
	product: Product;
	filters: [string, string, string];
	isHighlighted?: boolean;
}

const ProductResult: FC<Props> = ({
	product,
	filters,
	isHighlighted = false,
}) => {
	const dispatch: AppDispatch = useDispatch();
	const wrapper = useRef<HTMLDivElement>(null);

	const handleDragStart = useCallback(
		(e: React.DragEvent<HTMLDivElement>) => {
			e.dataTransfer.setDragImage(wrapper.current!, 0, 0);
			dispatch<DraggerAction>({
				type: DraggerActionType.DRAG_PRODUCT,
				product,
				specifiers: {
					type: filters[0],
					brand: filters[1],
					model: filters[2],
				},
			});
		},
		[product, filters, dispatch],
	);

	return (
		<Wrapper
			draggable
			onDragStart={handleDragStart}
			ref={wrapper}
			isHighlighted={isHighlighted}>
			<InfoWrapper>
				<HighlightedText>{product.id}</HighlightedText>
				<TextWrapper>
					<HighlightedText>{`${product.price.total} `}</HighlightedText>
					บาท / {product.unit}
				</TextWrapper>
			</InfoWrapper>
			<NameText>{product.name}</NameText>
		</Wrapper>
	);
};

export default ProductResult;

const Wrapper = styled.div<{ isHighlighted: boolean }>`
	display: flex;
	flex-direction: column;
	align-items: stretch;

	max-width: 100%;
	border-radius: 8px;
	padding: 0.675rem 0.75rem;
	background: ${(props) =>
		props.isHighlighted ? 'var(--accent-100)' : 'var(--neutrals-050)'};
	min-height: min-content;
	cursor: grab;
	min-height: min-content;

	&:active {
		cursor: grabbing;
	}
`;

const InfoWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	height: auto;
	font-size: 1rem;
	position: relative;
	align-items: baseline;
`;

const NameText = styled.span`
	font-size: inherit;
	font-weight: 500;
	margin-top: 0.625rem;
	color: var(--neutrals-600);
	white-space: nowrap;
`;

const TextWrapper = styled.span`
	font-size: 1rem;
	font-weight: 500;
	color: var(--neutrals-500);
`;

const HighlightedText = styled.span`
	font-weight: 600;
	color: var(--neutrals-800);
`;
