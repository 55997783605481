/* eslint-disable prefer-promise-reject-errors */
import Fuse from 'fuse.js';
import { UserAccountList, UserRegistrationInfo } from '../types/adminTypes';

export const searchArrayOfUsers = (
	searchValue: string,
	users: UserAccountList,
) => {
	const fuse = new Fuse(users, {
		threshold: 0.2,
		keys: ['email', 'role', 'displayName'],
	});
	const results = fuse.search(searchValue).map((result) => result.item);
	return results as UserAccountList;
};

export const verifyForm = (
	fields: UserRegistrationInfo,
	isUpdateForm = false,
) =>
	new Promise(
		(resolve: (res: string) => void, reject: (err: string) => void) => {
			if (!fields.email) reject('กรุณาใส่อีเมล');
			if (!fields.displayName) reject('กรุณาใส่ชื่อ');
			if (!fields.role) reject('กรุณาใส่ตำแหน่ง');
			if (!fields.password && !isUpdateForm) reject('กรุณาใส่ระหัสผ่าน');
			if (fields.password.length < 6 && !isUpdateForm)
				reject('รหัสผ่านต้องมีอย่างน้อย 6 ตัวอักษร');

			resolve('สำเร็จ');
		},
	);
