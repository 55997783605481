import hash from 'utilities/functions/hash';
import { ProductPrice } from 'features/product/types/productTypes';

import { Project, ProjectRow } from '../types/projectTypes';
import { ProjectAction, ProjectActionTypes } from '../types/actionTypes';
import { EMPTY_PROJECT, EMPTY_PROJECT_ROW } from '../constants/templates';

export interface ProjectState extends Project {
	latestChangesFrom: 'local' | 'remote';
	rowsLocked: boolean;
}

const initialState: ProjectState = {
	...EMPTY_PROJECT,
	latestChangesFrom: 'remote',
	rowsLocked: false,
};

const projectReducer = (
	state = initialState,
	action: ProjectActionTypes,
): ProjectState => {
	switch (action.type) {
		case ProjectAction.SET_LATEST_CHANGES_FROM: {
			return {
				...state,
				latestChangesFrom: action.from,
			};
		}
		case ProjectAction.SET_PROJECT: {
			return state.rowsLocked
				? { ...state, ...action.project, rows: state.rows }
				: {
						...state,
						...action.project,
				  };
		}
		case ProjectAction.RESET_PROJECT: {
			return {
				...initialState,
			};
		}
		case ProjectAction.SET_PROJECT_INFO: {
			return {
				...state,
				info: {
					...state.info,
					[action.property]: action.value,
				},
				latestChangesFrom: 'local',
			};
		}
		case ProjectAction.SET_INFO: {
			return {
				...state,
				info: action.info,
				latestChangesFrom: 'local',
			};
		}
		case ProjectAction.SET_TAB: {
			return {
				...state,
				tabs: action.tabs,
				latestChangesFrom: 'local',
			};
		}
		case ProjectAction.ADD_TAB: {
			return {
				...state,
				tabs: [...state.tabs, action.tab],
				latestChangesFrom: 'local',
			};
		}
		case ProjectAction.REMOVE_TAB: {
			return {
				...state,
				tabs: [...state.tabs.filter((t) => t.id !== action.id)],
				latestChangesFrom: 'local',
			};
		}
		case ProjectAction.ADD_PROJECT_DISCOUNT: {
			return {
				...state,
				discount: [...state.discount, action.discountListWithInfo],
			};
		}
		case ProjectAction.REMOVE_PROJECT_DISCOUNT: {
			return {
				...state,
				discount: state.discount.filter(
					(discountListWithInfo) =>
						discountListWithInfo.name !== action.name,
				),
			};
		}
		case ProjectAction.SET_ROWS: {
			return state.rowsLocked
				? state
				: {
						...state,
						latestChangesFrom: 'local',
						rows: action.rows,
				  };
		}
		case ProjectAction.EDIT_ROW: {
			const { rows } = state;
			const { index, mutation } = action;

			Object.keys(mutation).map(
				(key) =>
					mutation[key as keyof ProjectRow] === undefined &&
					delete mutation[key as keyof ProjectRow],
			);

			return state.rowsLocked
				? state
				: {
						...state,
						rows: [
							...rows.slice(0, index),
							{
								...rows[index],
								...mutation,
							},
							...rows.slice(index + 1),
						],
						latestChangesFrom: 'local',
				  };
		}
		case ProjectAction.EDIT_PRICE: {
			const { rows } = state;
			const { index, mutation } = action;

			const { sum, ...rest } = rows[index].price;

			Object.keys(mutation).map(
				(key) =>
					mutation[key as keyof ProductPrice] === undefined &&
					delete mutation[key as keyof ProductPrice],
			);

			return state.rowsLocked
				? state
				: {
						...state,
						rows: [
							...rows.slice(0, index),
							{
								...rows[index],
								price: {
									...rest,
									...mutation,
								},
							},
							...rows.slice(index + 1),
						],
						latestChangesFrom: 'local',
				  };
		}
		case ProjectAction.INSERT_PRODUCT: {
			const { rows } = state;
			const insertIndex = action.index;
			const generatedHash = hash();

			return state.rowsLocked
				? state
				: {
						...state,
						rows: [
							...rows.slice(0, insertIndex),
							{
								qty: 1,
								...action.product,
								hash: generatedHash,
								specifiers: action.specifiers,
								note: action.specifiers?.brand,
							},
							...rows.slice(insertIndex + 1),
						],
						latestChangesFrom: 'local',
				  };
		}
		case ProjectAction.LOCK_ROWS: {
			return {
				...state,
				rowsLocked: true,
			};
		}
		case ProjectAction.UNLOCK_ROWS: {
			return {
				...state,
				rowsLocked: false,
			};
		}
		case ProjectAction.ADD_ROW: {
			const { rows } = state;
			const insertIndex = action.options.insertPrev
				? action.index - 1
				: action.index;

			if (insertIndex < 0) {
				return {
					...state,
					rows: [{ ...EMPTY_PROJECT_ROW }, ...rows.slice(0)],
					latestChangesFrom: 'local',
				};
			}

			return {
				...state,
				rows: [
					...rows.slice(0, insertIndex),
					{ ...EMPTY_PROJECT_ROW },
					...rows.slice(insertIndex),
				],
				latestChangesFrom: 'local',
			};
		}
		case ProjectAction.MOVE_PRODUCT: {
			if (action.from === action.to || action.from === action.to + 1) {
				return {
					...state,
					latestChangesFrom: 'local',
				};
			}

			const a = state.rows.slice(0, Math.min(action.from, action.to + 1));
			const b = state.rows.slice(
				Math.min(action.from + 1, action.to + 1),
				Math.max(action.from, action.to + 1),
			);
			const c = state.rows.slice(
				Math.max(action.to + 1, action.from + 1),
			);

			return {
				...state,
				latestChangesFrom: 'local',
				rows:
					action.from < action.to
						? [...a, ...b, state.rows[action.from], ...c]
						: [...a, state.rows[action.from], ...b, ...c],
			};
		}
		case ProjectAction.BATCH_MOVE: {
			const { rows } = state;
			const { to, list } = action;

			if (list.includes(to)) {
				return state;
			}

			const filteredRow = rows.filter((_, i) => !list.includes(i));
			const toMove = list.map((x) => rows[x]);

			if (to === -1) {
				return {
					...state,
					latestChangesFrom: 'local',
					rows: [...toMove, ...filteredRow],
				};
			}

			const { hash } = rows[to];
			const targetIndex = filteredRow.findIndex((x) => x.hash === hash);

			return {
				...state,
				latestChangesFrom: 'local',
				rows: [
					...filteredRow.slice(0, targetIndex + 1),
					...toMove,
					...filteredRow.slice(targetIndex + 1),
				],
			};
		}
		default: {
			return state;
		}
	}
};

export default projectReducer;
