import React, { Fragment } from 'react';
import { Ellipsis } from 'components/icons';
import styled from 'styled-components';
import Modal from 'features/tools/constants/modal';

interface Props {
	headerText?: string;
	toggleModal: any;
	menuIconShown: boolean | undefined;
}

const ReadModeHeader: React.FC<Props> = ({
	toggleModal,
	headerText,
	menuIconShown,
}) => {
	return (
		<Fragment>
			{headerText || 'ทั้งหมด'}
			{menuIconShown && (
				<ActionButton
					onClick={() => toggleModal(Modal.OPTIONS_MENU, true)}>
					<Ellipsis size={21} color="var(--neutrals-400)" />
				</ActionButton>
			)}
		</Fragment>
	);
};

const ActionButton = styled.button`
	outline: none;
	appearance: none;
	border: none;
	background: none;

	display: flex;
	align-items: center;
	justify-content: center;
	width: 36px;
	height: 36px;
	border-radius: 8px;

	position: absolute;
	z-index: 2;
	right: 0;
	top: 0;

	cursor: pointer;

	&:hover {
		background: var(--neutrals-100);
	}
`;

export default ReadModeHeader;
