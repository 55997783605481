import React, { FC } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'redux/reducers';

import Portal from 'utilities/components/portal';
import Close from 'components/icons/close';

interface Props {
	onClose: () => void;
}

const Browser: FC<Props> = ({ onClose }) => {
	const { projects } = useSelector((state: RootState) => state.user);
	const history = useHistory();

	const handleOpenProject = (id: string) => {
		history.push({
			pathname: '/projects',
			search: `?id=${id}`,
		});
	};

	return (
		<Portal>
			<Backdrop onClick={() => onClose()}>
				<Modal onClick={(e) => e.stopPropagation()}>
					<Title>Open Project</Title>
					<CircleButton onClick={() => onClose()}>
						<Close size={26} color="var(--neutrals-700)" />
					</CircleButton>
					<ProjectsWrapper>
						{projects.map((project) =>
							project.info ? (
								<ProjectWrapper
									key={project.info.id}
									onClick={() =>
										handleOpenProject(project.info.id)
									}>
									<NameText>
										{project.info.name || 'Untitled'}
									</NameText>
									{project.info.area ? (
										<>
											<Separator />
											<AreaText>
												{project.info.area}
											</AreaText>
										</>
									) : null}
									<Spacer />
									<AdjustPill>
										{project.info.adjustment || 'Originals'}
									</AdjustPill>
								</ProjectWrapper>
							) : null,
						)}
					</ProjectsWrapper>
				</Modal>
			</Backdrop>
		</Portal>
	);
};

export default React.memo(Browser);

const Backdrop = styled.div`
	z-index: 2;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;

	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.6);
`;

const Modal = styled.div`
	position: relative;
	height: 80vh;
	width: min(600px, 90%);
	background: white;
	border-radius: 14px;

	overflow: auto;

	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: flex-start;
	padding: 1.25rem 1.5rem;
`;

const Title = styled.span`
	font-size: 1.25rem;
	font-weight: 600;
	color: var(--neutrals-900);
	margin-bottom: 1rem;
`;

const ProjectsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: flex-start;
	align-content: center;
	border-radius: 8px;

	overflow-y: auto;
	transition: opacity 120ms;

	& > *:not(:first-child) {
		margin-top: 0.75rem;
	}
`;

const ProjectWrapper = styled.button`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;

	z-index: 0;
	position: relative;
	background: var(--neutrals-050);
	padding: 0.875rem 1rem;
	border-radius: 8px;

	text-align: start;
	outline: none;
	border: none;
	appearance: none;

	cursor: pointer;
	transition: all 100ms;

	&:hover {
		background: var(--neutrals-100);
	}

	&:active {
		opacity: 0.4;
	}
`;

const NameText = styled.span`
	font-size: 1rem;
	font-weight: 600;
	color: var(--neutrals-800);
`;

const Separator = styled.span`
	font-size: 20px;
	font-weight: 500;
	color: var(--neutrals-400);
	margin: 0 0.5rem;

	&::after {
		content: '|';
	}
`;

const AreaText = styled.span`
	font-size: 1rem;
	font-weight: 500;
	color: var(--neutrals-500);
`;

const AdjustPill = styled.span`
	display: inline-flex;
	justify-content: center;
	align-items: center;
	padding: 0 0.625rem;
	min-height: 24px;

	font-size: 14px;
	font-weight: 500;
	color: var(--support-800);
	background: var(--support-100);
	border-radius: 100px;

	transition: all 100ms;
`;

const CircleButton = styled.button`
	position: absolute;
	right: 1.5rem;
	top: 1rem;

	display: flex;
	justify-content: center;
	align-items: center;
	height: 34px;
	width: 34px;
	padding: 0;

	background: none;
	border-radius: 100px;

	outline: none;
	border: none;
	cursor: pointer;
	transition: all 170ms;
	transform: translateX(8px);

	&:hover {
		opacity: 0.8;
		background: var(--neutrals-050);
	}

	&:active {
		opacity: 0.4;
		transform: translateX(8px) scale(0.95);
	}
`;

const Spacer = styled.div`
	flex-grow: 1;
`;
