import { DiscountList } from '../types';

const modifyTypePercent = (
	discountList: DiscountList,
	type: string,
	percentType: 'raw' | 'labour',
	percent: number,
): DiscountList => {
	const typeIndex = discountList.findIndex(
		(typeDiscount) => typeDiscount.type === type,
	);
	const newDiscountList = [
		...discountList.slice(0, typeIndex),
		{
			...discountList[typeIndex],
			data: discountList[typeIndex].data.map((brandDiscount) => ({
				...brandDiscount,
				models: brandDiscount.models.map((model) => ({
					...model,
					discountPercent: {
						...model.discountPercent,
						[percentType]: percent,
					},
				})),
			})),
			discountPercent: {
				...discountList[typeIndex].discountPercent,
				[percentType]: percent,
			},
		},
		...discountList.slice(typeIndex + 1),
	];
	return newDiscountList;
};

export default modifyTypePercent;
