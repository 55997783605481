import React, { FC, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AppDispatch, useSelector } from 'redux/reducers';

import subscribe from 'apis/project/subscribe';
import useToggle from 'utilities/hooks/useToggle';

import DiscountPage from 'features/discount/screens/discount';
import listenToDragger from 'features/dragger/listener';
import useQuery from 'utilities/hooks/useQuery';
import Tab from '../components/tab';
import Info from '../components/info';
import Menu from '../components/menu/menu';
import Table from '../components/table';
import Palette from '../components/palette';
import Product from '../../product/components/ProductModal';

import attachProject from '../actions/listeners/attachProject';

const ProjectPage: FC = () => {
	const { path } = useRouteMatch();
	const { id } = useQuery();

	const [products, toggleProducts] = useToggle(false);
	const [expanded, toggleExpanded] = useToggle(true);

	const project = useSelector((s) => s.project);
	const dispatch: AppDispatch = useDispatch();

	const projectInfoEmpty = useMemo(() => {
		return project.id
			? Object.values(project.info).filter((value) => !value).length !== 0
			: false;
	}, [project.info, project.id]);

	useEffect(() => {
		let detachProject: () => void;
		let detachDebouncer: () => void;
		let detachDragger: () => void;

		const init = async () => {
			detachProject = await dispatch(attachProject(id));
			detachDebouncer = subscribe();
			detachDragger = listenToDragger();
		};

		init();
		return () => {
			if (detachProject) detachProject();
			if (detachDebouncer) detachDebouncer();
			if (detachDragger) detachDragger();
		};
	}, [id, dispatch]);

	return (
		<Container>
			<Product onClose={toggleProducts} isShown={products} />
			<Menu onToggle={toggleProducts} projectId={project.id} />
			{project.id === id && (
				<>
					<Info expanded={expanded} toggleExpanded={toggleExpanded} />
					<Switch>
						<Route path={`${path}`} exact>
							<Table
								expanded={expanded}
								isTableShown={!projectInfoEmpty}
							/>
						</Route>
						<Route path={`${path}/adjust`}>
							<DiscountPage />
						</Route>
					</Switch>
					<Palette />
				</>
			)}
			<Tab />
		</Container>
	);
};

export default ProjectPage;

const Container = styled.main`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
`;
