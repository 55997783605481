import firebase from 'firebase/app';
import 'firebase/firestore';
import {
	ProjectAction,
	ProjectActionTypes,
} from 'features/project/types/actionTypes';
import { Tab, TabsActionType, TabsAdd } from 'features/tabs/types';
import { AppThunk } from 'redux/reducers';
import { batch as dispatchBatch } from 'react-redux';
import hash from 'utilities/functions/hash';

const addTab = (t: Omit<Tab, 'id'>): AppThunk<Promise<string>> => async (
	dispatch,
	getState,
) => {
	const { id } = getState().project;

	const tabId = hash();
	const batch = firebase.firestore().batch();
	const projectRef = firebase.firestore().collection('Project').doc(id);
	const tabsRef = projectRef.collection('Tabs').doc(tabId);

	batch.set(tabsRef, { id: tabId, ...t });
	batch.update(projectRef, {
		tabs: firebase.firestore.FieldValue.arrayUnion({
			id: tabId,
			name: t.name,
		}),
	});

	await batch.commit();

	dispatchBatch(() => {
		dispatch<ProjectActionTypes>({
			type: ProjectAction.ADD_TAB,
			tab: {
				id: tabId,
				name: t.name,
			},
		});

		dispatch<TabsAdd>({
			type: TabsActionType.ADD,
			tab: {
				id: tabId,
				...t,
			},
		});

		dispatch<ProjectActionTypes>({
			type: ProjectAction.LOCK_ROWS,
		});
	});

	return tabId;
};

export default addTab;
