import firebase from 'firebase/app';
import 'firebase/firestore';

import { Project } from 'features/project/types/projectTypes';
import { AppThunk } from 'redux/reducers';
import { ProjectAction } from 'features/project/types/actionTypes';
import { EMPTY_PROJECT } from 'features/project/constants/templates';
import { UserAction } from 'features/account/user/types/actionTypes';
import setProject from '../edit/set.setProject';

const deleteProject = (id: Project['id']): AppThunk<Promise<void>> => async (
	dispatch,
	getState,
) => {
	// Deletion occurs in two places - user and project collections
	// Batch used to reduce operations count

	const { uid, projects } = getState().user;

	const batch = firebase.firestore().batch();

	const projectRef = firebase.firestore().collection('Project').doc(id);
	const userRef = firebase.firestore().collection('User').doc(uid);

	batch.update(userRef, {
		projects: projects.filter((project) => project.info.id !== id),
	});
	batch.delete(projectRef);

	await batch.commit();

	// Locally update the data

	dispatch({
		type: ProjectAction.SET_LATEST_CHANGES_FROM,
		from: 'remote',
	});
	dispatch(setProject({ ...EMPTY_PROJECT }));

	dispatch({
		type: UserAction.SET_PROJECTS,
		projects: projects.filter((project) => project.info.id !== id),
	});
};

export default deleteProject;
