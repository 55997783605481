import React, { ChangeEvent, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { GrDocument } from 'react-icons/gr';
import { BsEye, BsPencilSquare } from 'react-icons/bs';
import { BiRefresh, BiTrash } from 'react-icons/bi';
import styled from 'styled-components';

interface Props {
	onSearchCallback: (searchValue: string) => void;
	onAddCallback: () => void;
	onDeleteCallback: () => void;
	onUpdateCallback: () => void;
	onViewCallback: () => void;
	selectedItemsCount: number;
	onRefreshCallback?: () => void;
}

const TableMenu: React.FC<Props> = ({
	onSearchCallback,
	onAddCallback,
	onDeleteCallback,
	onUpdateCallback,
	onViewCallback,
	selectedItemsCount,
	onRefreshCallback,
}) => {
	const [searchValue, setSearchValue] = useState<string>('');

	const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
		// controls only the search input display
		setSearchValue(e.target.value);
		// controls actual searching
		onSearchCallback(e.target.value);
	};

	return (
		<Container>
			<div>
				<Searchbar>
					<SearchLabel>
						<AiOutlineSearch />
					</SearchLabel>
					<SearchInput
						value={searchValue}
						onChange={handleSearch}
						placeholder="กรอกเพื่อค้นหา"
					/>
				</Searchbar>
			</div>
			<div>
				<AddItem>
					<AddContainer onClick={() => onAddCallback()}>
						<GrDocument />
						<span>เพิ่ม</span>
					</AddContainer>
				</AddItem>
				{onRefreshCallback ? (
					<Item onClick={() => onRefreshCallback()}>
						<BiRefresh />
						<span>รีเฟรช</span>
					</Item>
				) : null}
				{selectedItemsCount === 1 ? (
					<>
						<Item onClick={() => onViewCallback()}>
							<BsEye />
							<span>ดูข้อมูล</span>
						</Item>
						<Item onClick={() => onUpdateCallback()}>
							<BsPencilSquare />
							<span>แก้ไข</span>
						</Item>
					</>
				) : null}
				{selectedItemsCount > 0 ? (
					<>
						<Item onClick={() => onDeleteCallback()}>
							<BiTrash />
							<span>ลบ</span>
						</Item>
					</>
				) : null}
			</div>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	background: var(--neutrals-900);
	box-shadow: inset 0 7px 9px -7px rgba(0, 0, 0, 0.1);

	& > * {
		display: flex;

		&:first-child {
			flex: 1;
			margin-left: 2.5%;
		}

		&:last-child {
			flex: 1;
			flex-flow: row-reverse;
			align-items: center;

			& > * {
				margin-right: 4%;
				height: 60%;
			}
		}
	}
`;

const Searchbar = styled.div`
	display: flex;
	align-items: center;

	& > * {
		min-height: 100%;
		color: var(--neutrals-300);
		transition: all 0.2s linear;
	}

	&:focus,
	&:hover {
		& > * {
			color: var(--neutrals-100);
		}
	}
`;

const SearchLabel = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 5%;
	min-width: fit-content;
	margin-right: 5%;
`;

const SearchInput = styled.input`
	border: none;
	background-image: none;
	background-color: transparent;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	font-size: inherit;
	outline: none;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1rem;
	width: 40%;
	min-width: fit-content;
	transition: all 0.1s linear;
`;

const Item = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 7rem;
	color: var(--neutrals-200);
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.5);
	background: var(--neutrals-800);
	border-radius: 0.2rem;

	&:hover {
		background: var(--neutrals-700);
		color: var(--neutrals-100);
		cursor: pointer;

		&:first-child {
			& > *:first-child {
				& > * {
					stroke: var(--neutrals-100);
				}
			}
		}
	}

	&:first-child {
		& > *:first-child {
			& > * {
				stroke: var(--neutrals-200);
			}
		}
	}

	& > *:first-child {
		margin-right: 10px;
	}
`;

const AddItem = styled.div`
	display: flex;
	flex-flow: row-reverse;
	transition: all 1s linear;

	& > *:first-child {
		& > *:nth-child(3) {
			display: none;
		}
	}

	& > *:nth-child(2) {
		display: none;
		color: var(--support-500);
		& > *:first-child {
			& > * {
				stroke: var(--support-500);
			}
		}
	}
`;

const AddContainer = styled(Item)``;

export default TableMenu;
