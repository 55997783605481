import React, { FC } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { Home } from 'components/icons';

const AdminMenu: FC = () => {
	const history = useHistory();

	const handleGoHome = () => {
		history.push('/welcome');
	};

	return (
		<Container>
			<Group>
				<Border />
				<SquareButton onClick={handleGoHome}>
					<Home
						style={{ transform: 'translateY(-1px)' }}
						size={21}
						color="none"
					/>
				</SquareButton>
				<Border />
			</Group>
		</Container>
	);
};

export default AdminMenu;

const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	width: 100%;

	background-color: var(--primary-900);
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 4px 16px rgba(0, 0, 0, 0.05);
`;

const Group = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	height: 40px;
	background: transparent;
`;

const SquareButton = styled.button`
	position: relative;
	outline: none;
	display: flex;
	align-items: center;
	justify-content: center;
	appearance: none;
	border: none;
	background: transparent;
	width: 40px;
	height: 100%;
	color: var(--neutrals-050);
	cursor: pointer;

	&:hover {
		background: var(--primary-800);
	}

	&:active {
		background: var(--primary-700);
	}
`;

/*
const RectangleButton = styled(SquareButton)`
	width: auto;
	padding: 0 0.5rem;
`;

const Text = styled.span`
	margin-left: 0.5rem;
	margin-right: 0.25rem;
	font-family: Sarabun;
	font-size: 14px;
	font-weight: 500;
	color: var(--neutrals-050);
`;
*/

const Border = styled.div`
	height: 100%;
	width: 1px;
	background: var(--neutrals-700);
`;

/*
const Backdrop = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: ${document.body.clientWidth}px;
	height: ${document.body.clientHeight}px;
	background: rgba(0, 0, 0, 0.6);
`;

const SettingModal = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	padding: 0;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 4px 8px rgba(0, 0, 0, 0.05),
		0 16px 32px rgba(0, 0, 0, 0.05);
	background: white;
	transform: translate(-50%, -50%);
	width: 300px;
	border-radius: 8px;
	overflow: hidden;
`;

const SettingTitle = styled.div`
	font-size: 20px;
	font-weight: 600;
	color: var(--neutrals-800);
	padding: 0.75rem 1rem;
	background: var(--neutrals-050);
`;

const WarningText = styled.div`
	font-size: 14px;
	font-weight: 400;
	color: var(--neutrals-500);
	padding: 1rem 1rem 0.75rem;
	line-height: 1.5;
`;

const DangerButton = styled.button`
	outline: none;
	border: none;
	appearance: none;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	height: 36px;
	color: var(--danger-500);
	background: var(--danger-050);
	border-radius: 6px;
	font-size: 16px;
	font-weight: 500;
	margin: 1rem;
	margin-top: 0;
	cursor: pointer;

	&:hover {
		background: var(--danger-100);
	}
`;

const NeutralButton = styled(DangerButton)`
	color: var(--primary-600);
	background: var(--primary-050);
	&:hover {
		background: var(--primary-100);
	}
`;

const CloseButton = styled.button`
	outline: none;
	border: none;
	background: transparent;
	appearance: none;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
	position: absolute;
	right: 10px;
	top: 10px;
	border-radius: 100px;
	cursor: pointer;

	&:hover {
		background: rgba(0, 0, 0, 0.07);
	}

	&:active {
		background: rgba(0, 0, 0, 0.09);
	}
`;

const StyledDivider = styled.hr`
	margin: 0 auto;
	width: 75%;
	border: none;
	border-top: 1px solid var(--neutrals-200);
`;
*/
