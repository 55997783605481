import { useState } from 'react';
import Modal from '../constants/modal';

type ActiveModals = Record<string, any>;

// This hook allows tracking the display state of multiple modals
// With params passed in the same place as each modal's on/off state
const useModal = (log: string) => {
	const [activeModals, setActiveModals] = useState<ActiveModals>({});

	const toggleModal = (
		modalName: Modal,
		isShown: boolean,
		params: any = {},
	) => {
		const activeModalsClone = {
			...activeModals,
		};
		if (isShown) {
			activeModalsClone[modalName] = {
				...params,
			};
		} else {
			delete activeModalsClone[modalName];
		}
		setActiveModals(activeModalsClone);
	};

	const closeAllModals = () => {
		setActiveModals({});
	};

	return [activeModals, toggleModal, closeAllModals] as const;
};

export default useModal;
