/* eslint-disable import/no-duplicates */

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

import { FIREBASE_CONFIG } from 'config/constants/app';
import { User } from 'features/account/user/types/userTypes';

// eslint-disable-next-line import/no-mutable-exports
let app: firebase.app.App | undefined;

if (!firebase.apps.length) {
	app = firebase.initializeApp(FIREBASE_CONFIG);
	firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
}

export default app;

export function getCurrentUser(a: firebase.auth.Auth) {
	return new Promise<firebase.User | null>((resolve, reject) => {
		const unsubscribe = a.onAuthStateChanged((user) => {
			unsubscribe();
			resolve(user);
		}, reject);
	});
}

export async function checkOwnership(projectId: string) {
	const user = await getCurrentUser(firebase.auth());

	if (!user) {
		throw Error('authentication error: user not logged in.');
	}

	const userRef = firebase.firestore().collection('User').doc(user.uid);
	const userDoc = await userRef.get();
	const { projects } = userDoc.data() as User;

	if (projects.findIndex((p) => p.info.id === projectId) !== -1) {
		return true;
	}

	return false;
}
