import { TableDefinition } from 'utilities/types/table';
import { UserAccountInfo } from '../types/adminTypes';

const ADMIN_TABLE: TableDefinition<UserAccountInfo> = {
	columns: [
		{
			header: 'จำนวนครั้งที่เข้าสู่ระบบ',
			accessor: 'loginCount',
			width: 90,
			textAlign: 'start',
		},
		{
			header: 'อีเมล',
			accessor: 'email',
			width: 90,
			textAlign: 'start',
		},
		{
			header: 'ชื่อ',
			accessor: 'displayName',
			width: 90,
			textAlign: 'start',
		},
		{
			header: 'เบอร์โทรศัพท์',
			accessor: 'phoneNumber',
			width: 90,
			textAlign: 'start',
		},
		{
			header: 'ชื่อบริษัท',
			accessor: 'company',
			width: 90,
			textAlign: 'start',
		},
		{
			header: 'ตำแหน่ง',
			accessor: 'role',
			width: 90,
			textAlign: 'start',
		},
	],
	rowTemplate: [
		{
			key: 'loginCount',
			accessor: (row) => row.loginCount,
		},
		{
			key: 'email',
			accessor: (row) => row.email,
		},
		{
			key: 'displayName',
			accessor: (row) => row.displayName,
		},
		{
			key: 'phoneNumber',
			accessor: (row) => row.phoneNumber,
		},
		{
			key: 'company',
			accessor: (row) => row.company,
		},
		{
			key: 'role',
			accessor: (row) => row.role,
		},
	],
	sortKey: 'email',
};

export default ADMIN_TABLE;
