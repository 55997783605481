import React, { FC } from 'react';

interface Props {
	size?: number;
	color?: string;
	[prop: string]: any;
}

const DragIndicator: FC<Props> = ({
	size = 24,
	color = 'white',
	...delegated
}) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 48 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...delegated}>
			<path
				d="M36 26C38.2 26 40 27.8 40 30C40 32.2 38.2 34 36 34C33.8 34 32 32.2 32 30C32 27.8 33.8 26 36 26ZM20 30C20 32.2 21.8 34 24 34C26.2 34 28 32.2 28 30C28 27.8 26.2 26 24 26C21.8 26 20 27.8 20 30ZM8 30C8 32.2 9.8 34 12 34C14.2 34 16 32.2 16 30C16 27.8 14.2 26 12 26C9.8 26 8 27.8 8 30ZM16 18C16 15.8 14.2 14 12 14C9.8 14 8 15.8 8 18C8 20.2 9.8 22 12 22C14.2 22 16 20.2 16 18ZM20 18C20 20.2 21.8 22 24 22C26.2 22 28 20.2 28 18C28 15.8 26.2 14 24 14C21.8 14 20 15.8 20 18ZM32 18C32 20.2 33.8 22 36 22C38.2 22 40 20.2 40 18C40 15.8 38.2 14 36 14C33.8 14 32 15.8 32 18Z"
				fill={color}
			/>
		</svg>
	);
};

export default DragIndicator;
