import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from 'redux/reducers';

import AdminMenu from '../components/menu/adminMenu';
import TableMenu from '../components/table/tableMenu';
import AdminTable from '../components/table/adminTable';
import ModalDisplayAdapter from '../components/modals/modalDisplayAdapter';
import AccessForm from '../components/form/accessForm';

import ADMIN_TABLE from '../constants/table';
import ACCESS_MODAL_CONFIG from '../constants/modal';

import { UserAccountInfo, UserRegistrationInfo } from '../types/adminTypes';
import { Modal, ModalController } from '../types/modal';

import listUsers from '../actions/auth/auth.listUsers';
import createUser from '../actions/auth/auth.createUser';
import updateUser from '../actions/auth/auth.updateUser';
import deleteUser from '../actions/auth/auth.deleteUser';

import { searchArrayOfUsers } from '../functions/form';
import AdminInfoBar from '../components/menu/adminInfoBar';
import listenToSiteSettings from '../actions/site/listen.listenToSiteSettings';

const AdminPage = () => {
	const dispatch = useDispatch<AppDispatch>();
	const { userList, isUserListLoading } = useSelector(
		(state: RootState) => state.admin,
	);
	const [searchResults, setSearchResults] = useState<Array<UserAccountInfo>>(
		[],
	);
	const [searchEmpty, setSearchEmpty] = useState(true);

	// Contains ids of selected lists
	const [selectedItems, setSelectedItems] = useState<Array<string>>([]);
	const [modalController, setModal] = useState<ModalController>({
		modal: Modal.EMPTY,
		params: null,
	});

	const handleAddUser = (fields: UserRegistrationInfo) => {
		dispatch(createUser(fields));
	};

	const handleUpdateUser = (
		uid: string,
		updateFields: Partial<UserRegistrationInfo>,
	) => {
		dispatch(updateUser(uid, updateFields));
	};

	const handleRemoveUser = (uids: Array<string>) => {
		uids.forEach((uid) => dispatch(deleteUser(uid)));
	};

	const handleRefreshUserList = () => {
		dispatch(listUsers());
	};

	const toggleModal = (modal: Modal, params: any = null) => {
		setModal({
			modal,
			params,
		});
	};

	const handleSearch = useCallback(
		(searchValue: string) => {
			const isSearchEmpty = !searchValue.trim();
			setSearchEmpty(isSearchEmpty);
			if (isSearchEmpty) return; // stop searching if input's empty

			const results = searchArrayOfUsers(searchValue, userList);
			setSearchResults(results);
		},
		[userList],
	);

	const handleSelectItems = useCallback((selectedIds: Array<string>) => {
		setSelectedItems(selectedIds);
	}, []);

	const getLastSelectedItem = (): UserAccountInfo => {
		// used to create a clone of the the last selected item
		return {
			...userList.filter(
				(item) => item.uid === selectedItems[selectedItems.length - 1],
			)[0],
		};
	};

	const handleDeleteItems = () => {
		const toRemove = [...selectedItems];
		setSelectedItems([]);
		handleRemoveUser(toRemove);
	};

	useEffect(() => {
		dispatch(listUsers());
		const unsubscribeToSettings = dispatch(listenToSiteSettings());

		return () => {
			unsubscribeToSettings();
		};
	}, [dispatch]);

	return (
		<Container>
			<AdminMenu />
			<AdminInfoBar />
			<TableMenu
				onAddCallback={() => toggleModal(Modal.ADD)}
				onSearchCallback={handleSearch}
				onDeleteCallback={() => handleDeleteItems()}
				onUpdateCallback={() =>
					toggleModal(Modal.UPDATE, {
						user: getLastSelectedItem(),
						uid: selectedItems[0],
					})
				}
				onViewCallback={() =>
					toggleModal(Modal.VIEW, {
						user: getLastSelectedItem(),
						uid: selectedItems[0],
					})
				}
				onRefreshCallback={handleRefreshUserList}
				selectedItemsCount={selectedItems.length}
			/>
			<AdminTable
				indexer="uid"
				list={searchEmpty ? userList : searchResults}
				tableDefinition={ADMIN_TABLE}
				onSelectCallback={handleSelectItems}
				showLoadingState={isUserListLoading}
			/>
			<ModalDisplayAdapter
				form={AccessForm}
				modalConfig={ACCESS_MODAL_CONFIG}
				onUpdateCallback={handleUpdateUser}
				onAddCallback={handleAddUser}
				controller={modalController}
				indexer="uid"
				onClear={() => toggleModal(Modal.EMPTY)}
			/>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100vh;

	& > * {
		width: inherit;

		&:first-child {
			flex: 1 0 2.5rem;
		}

		&:nth-child(2) {
			flex: 1 0 3rem;
		}

		&:nth-child(3) {
			flex: 1 0 4rem;
		}

		&:nth-child(4) {
			flex: 1 0 calc(100vh - 9.5rem);
			max-height: calc(100vh - 9.5rem);
		}
	}
`;

export default AdminPage;
