import firebase from 'firebase/app';
import 'firebase/storage';

import setError from 'features/error/actions/edit/set.setError';
import { AppThunk } from 'redux/reducers';

interface FunctionParams {
	file: File;
	path: string;
	name?: string;
}

const uploadFile = ({
	file,
	path,
	name,
}: FunctionParams): AppThunk<Promise<any>> => (dispatch) =>
	new Promise((resolve: (downloadUrl: string) => void) => {
		const storage = firebase.storage().ref();
		const uploadTask = storage
			.child(path)
			.child(name || file.name)
			.put(file);
		uploadTask.on(
			firebase.storage.TaskEvent.STATE_CHANGED,
			(_snapshot) => {
				// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
			},
			(error: any) => {
				// A full list of error codes is available at
				// https://firebase.google.com/docs/storage/web/handle-errors
				// eslint-disable-next-line default-case
				switch (error.code) {
					case 'storage/unauthorized':
						dispatch(
							setError('ท่านมีสิทธิ์ไม่เพียงพอ', {
								returnHome: false,
							}),
						);
						break;

					case 'storage/canceled':
						dispatch(
							setError('การอัปโหลดถูกยกเลิก', {
								returnHome: false,
							}),
						);
						break;

					case 'storage/unknown':
						dispatch(
							setError('เกิดข้อผิดพลาด', { returnHome: false }),
						);
						break;
				}
			},
			() => {
				// Upload completed successfully, now we can get the download URL
				uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
					resolve(downloadURL);
				});
			},
		);
	});

export default uploadFile;
