// Third-party services starter
import 'services/firebase';
import 'services/sentry';
import 'services/moment';

import React, { FC, useEffect } from 'react';
import Router from 'features/routing';
import getStore from 'redux/store';
import { Provider } from 'react-redux';
import DefaultTheme from 'config/themes/default';
import authListener from 'features/account/user/actions/listener/auth.authListener';

export const store = getStore();

const App: FC = () => {
	useEffect(() => {
		// @ts-ignore
		const unsubscribeAuthListener = store.dispatch(authListener());

		return () => {
			// @ts-ignore
			unsubscribeAuthListener();
		};
	}, []);

	return (
		<Provider store={store}>
			<DefaultTheme />
			<Router />
		</Provider>
	);
};

export default App;
