import firebase from 'firebase/app';
import 'firebase/firestore';
import { TypeSpecifier } from 'features/product/types/productTypes';

const getTypes = (cb: (arr: TypeSpecifier[]) => void): (() => void) => {
	return (
		firebase
			.firestore()
			.collection('products')
			.where('isRemoved', '==', false)
			// eslint-disable-next-line consistent-return
			.onSnapshot((querySnapshot) => {
				if (!querySnapshot.size) return cb([]);
				const types: TypeSpecifier[] = [];
				querySnapshot.forEach((typeDocument) => {
					const typeData = typeDocument.data() as TypeSpecifier;
					types.push(typeData);
				});
				cb(types);
			})
	);
};

export default getTypes;
