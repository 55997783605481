import React, { FC, DOMAttributes } from 'react';
import styled from 'styled-components';

interface Props extends DOMAttributes<HTMLSelectElement> {
	value: string;
	placeholder: string;
	onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
	[prop: string]: any;
}

const Dropdown: FC<Props> = ({
	value,
	placeholder,
	onChange,
	children,
	...delegated
}) => (
	<Select
		value={value}
		onClick={(e) => {
			e.stopPropagation();
			e.preventDefault();
		}}
		onChange={onChange}
		{...delegated}
		style={{
			color: value ? 'var(--neutrals-900)' : 'var(--neutrals-500)',
		}}>
		<option value="">{placeholder}</option>
		{children}
	</Select>
);

export default React.memo(Dropdown);

const Select = styled.select<{ placeholderShown?: boolean }>`
	font-size: 1rem;
	font-weight: 500;
	background: transparent;

	height: 39px;
	padding: 0 0.5rem;
	background: var(--neutrals-050);
	border: none;
	border-radius: 8px 0 0 8px;

	outline: none;
	cursor: pointer;

	& > * {
		color: var(--neutrals-900);
	}
`;
