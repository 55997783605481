import React, { FC } from 'react';
import styled from 'styled-components';

interface Props {
	width: number | string;
}

const Cell: FC<Props> = ({ width, children }) => {
	return <Wrapper style={{ width }}>{children}</Wrapper>;
};

export default Cell;

const Wrapper = styled.div`
	border-top: 1px solid var(--neutrals-100);
	flex: 0 0 auto;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	position: relative;
	padding: 0.25rem 0.5rem;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	font-size: 1rem;
	border-right: 1px solid var(--neutrals-100);
	font-size: 14px;
	color: var(--neutrals-500);
	background: var(--neutrals-050);
	border-bottom: 1px solid var(--neutrals-100);
`;
