import { UserAction } from 'features/account/user/types/actionTypes';
import firebase from 'firebase/app';
import 'firebase/firestore';

import { AppThunk } from 'redux/reducers';

const duplicateProject = (): AppThunk<Promise<string>> => async (
	dispatch,
	getState,
) => {
	// Deletion occurs in two places - user and project collections
	// Batch used to reduce operations count

	const { user, project } = getState();
	const { uid, projects } = user;

	// Use destructuring to remove property latestChangesFrom
	const { latestChangesFrom, tabs, ...projectClone } = project;

	const batch = firebase.firestore().batch();

	const duplicateProjectRef = firebase
		.firestore()
		.collection('Project')
		.doc();
	const userRef = firebase.firestore().collection('User').doc(uid);

	const duplicateId = duplicateProjectRef.id;

	const { name } = projectClone.info;
	const regex = new RegExp(`${name}\\s*\\(Rev.\\d+\\)`, 'g');

	// Count all duplicates using RegExp matching
	const count = projects.filter(
		({ info }) => info.name === name || info.name.match(regex),
	).length;

	// The new name attaches the clone count to the end of the original name
	const projectCloneName =
		projectClone.info.name + (count ? ` (Rev.${count})` : '');
	const updatedUserProjects = [
		...projects,
		{
			info: {
				...projects.find(
					(userProject) => userProject.info.id === project.id,
				)?.info,
				name: projectCloneName,
				id: duplicateId,
			},
			lastOpened: 0,
		},
	];

	batch.set(duplicateProjectRef, {
		...projectClone,
		info: {
			...projectClone.info,
			name: projectCloneName,
		},
		id: duplicateId,
	});
	batch.update(userRef, {
		projects: updatedUserProjects,
	});

	await batch.commit();

	dispatch({
		type: UserAction.SET_PROJECTS,
		projects: updatedUserProjects,
	});

	return duplicateId;
};

export default duplicateProject;
