import Portal from 'utilities/components/portal';
import React, { ReactChild } from 'react';
import styled from 'styled-components';

const BLUR_BACKGROUND = '#blurBackground';

interface Props {
	onCloseHandler: () => void;
	children: ReactChild;
}

const Modal: React.FC<Props> = ({ onCloseHandler, children }) => {
	// element to which the modal will be rendered

	const handleClose = (e: any) => {
		if (e.target.id === BLUR_BACKGROUND) onCloseHandler();
	};

	return (
		<Portal>
			<BlurBackground id={BLUR_BACKGROUND} onClick={handleClose}>
				{children}
			</BlurBackground>
		</Portal>
	);
};

const BlurBackground = styled.div`
	position: absolute;
	background-color: rgba(0, 0, 0, 0.25);
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export default Modal;
