import React from 'react';
import styled from 'styled-components';

import Portal from 'utilities/components/portal';

import common_th from 'translations/th/common.json';

interface Props {
	errorMessage: string;
	errorHeaderText?: string;
	errorBtnText?: string;
	themeColor?: string;
	onAcceptError: () => void;
}

const ErrorMessage = ({
	errorMessage,
	errorHeaderText,
	errorBtnText,
	themeColor,
	onAcceptError,
}: Props) => {
	return (
		<Portal>
			<Container>
				<ErrorContainer>
					<TitleContainer headerColor={themeColor}>
						<span>{errorHeaderText || common_th.error}</span>
					</TitleContainer>
					<MessageContainer>
						<div>{errorMessage}</div>
					</MessageContainer>
					<ButtonContainer>
						<StyledButton
							type="button"
							btnColor={themeColor}
							onClick={() => onAcceptError()}>
							{errorBtnText || common_th.ok}
						</StyledButton>
					</ButtonContainer>
				</ErrorContainer>
			</Container>
		</Portal>
	);
};

const Container = styled.div`
	position: relative;
	z-index: 1;
	height: 100vh;
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(0, 0, 0, 0.5);
`;

const ErrorContainer = styled.div`
	height: fit-content;
	max-height: 30rem;
	width: 25rem;

	display: flex;
	flex-flow: column;
	justify-content: space-evenly;
	align-items: center;

	background: white;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

	border-radius: 0.2rem;
`;

const TitleContainer = styled.div<{ headerColor?: string }>`
	font-weight: bold;
	min-height: 3rem;
	font-size: 1.2rem;
	color: ${(props) =>
		props.headerColor ? props.headerColor : 'var(--danger-400)'};

	display: flex;
	align-items: center;
	padding: 1rem 0 0;
`;

const MessageContainer = styled.div`
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	color: var(--neutrals-600);
	width: 24rem;
	min-height: 5rem;
	height: fit-content;

	& > *:first-child {
		padding: 0.2rem;
		overflow: auto;
		width: 100%;
		text-align: center;
		white-space: pre-wrap;
	}
`;

const ButtonContainer = styled.div`
	min-height: 3.5rem;
	display: flex;
	align-items: center;
	padding: 1rem 0;
`;

const StyledButton = styled.button<{ btnColor?: string }>`
	outline: none;
	background: ${(props) =>
		props.btnColor ? props.btnColor : 'var(--danger-400)'};
	color: var(--neutrals-050);
	font-weight: bold;
	height: 2.5rem;
	width: 6rem;
	border: none;
	border-radius: 0.2rem;
	font-size: 1rem;
	transition: all 100ms linear;

	&:hover {
		cursor: pointer;
		opacity: 0.8;
		transform: scale(1.03);
	}
`;

export default ErrorMessage;
