import { TableState, TableAction } from '../types/tableTypes';

export const genesis: TableState = {
	columns: {
		id: { width: 100 },
		name: { width: 400 },
		qty: { width: 70 },
		unit: { width: 70 },
		raw: { width: 120 },
		rawTotal: { width: 120 },
		labour: { width: 120 },
		labourTotal: { width: 120 },
		total: { width: 150 },
		note: { width: 200 },
	},
	dragging: {
		id: '',
		from: 0,
		to: 0,
		min: 0,
	},
};

const TableReducer = (state = genesis, action: TableAction): TableState => {
	switch (action.type) {
		case 'table.column.set':
			return {
				...state,
				columns: {
					...state.columns,
					[action.id]: {
						width: action.value > 50 ? action.value : 50,
					},
				},
			};
		case 'table.dragging.init':
			return {
				...state,
				dragging: {
					id: action.id,
					from: action.value,
					to: action.value,
					min: action.min,
				},
			};
		case 'table.dragging.update':
			return {
				...state,
				dragging: {
					...state.dragging,
					to: state.dragging.to + action.value,
				},
			};
		case 'table.dragging.end': {
			if (state.dragging.id) {
				return {
					...TableReducer(state, {
						type: 'table.column.set',
						id: state.dragging.id,
						value:
							state.columns[state.dragging.id].width +
							(state.dragging.to - state.dragging.from),
					}),
					dragging: {
						...state.dragging,
						id: '',
					},
				};
			}
			return {
				...state,
				dragging: {
					...state.dragging,
					id: '',
				},
			};
		}
		case 'table.focus.on': {
			const { type, ...mutation } = action;

			Object.keys(action).map(
				(key) =>
					mutation[key as keyof typeof mutation] === undefined &&
					delete mutation[key as keyof typeof mutation],
			);

			return {
				...state,
				focus: {
					...state.focus,
					...mutation,
				},
			};
		}
		default:
			return state;
	}
};

export default TableReducer;
