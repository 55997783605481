export enum ErrorAction {
	SET_ERROR = '[ERROR] SET_ERROR',
	REMOVE_ERROR = '[ERROR] REMOVE_ERROR',
}

interface SetError {
	type: ErrorAction.SET_ERROR;
	errorMessage: string;
	params: {
		returnHome: boolean;
		log: boolean;
		errorHeaderText?: string;
		errorBtnText?: string;
		themeColor?: string;
	};
}

interface RemoveError {
	type: ErrorAction.REMOVE_ERROR;
}

export type ErrorActionTypes = SetError | RemoveError;
