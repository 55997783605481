import React, {
	useRef,
	useState,
	ChangeEvent,
	KeyboardEvent,
	useLayoutEffect,
} from 'react';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import { RootState, useSelector } from 'redux/reducers';
import { ProjectAction } from 'features/project/types/actionTypes';
import { selectLock } from 'features/project/selectors/project';
import setError from 'features/error/actions/edit/set.setError';
import { ColumnName } from '../../types/tableTypes';

const COLUMNS = [
	'id',
	'name',
	'qty',
	'unit',
	'raw',
	'rawTotal',
	'labour',
	'labourTotal',
	'total',
	'note',
];

interface Props {
	id: ColumnName;
	index: number;
	value: string | number | undefined;
	onLockedCellEdit: () => void;
}

const NormalCell: React.FunctionComponent<MergedProps> = ({
	id,
	index,
	value,
	width,
	focus,
	onLockedCellEdit,
	...delegated
}) => {
	const dispatch = useDispatch();
	const locked = useSelector(selectLock);
	const user = useSelector((state: RootState) => state.user);
	const projectId = useSelector((state: RootState) => state.project.id);
	const inputRef = useRef<HTMLInputElement>(null);

	const [field, setField] = useState(value);
	const [noEditingError, setNoEditingError] = useState(false);

	const mutateState = (e: ChangeEvent<HTMLInputElement>) => {
		if (!user.projects.find((project) => project.info?.id === projectId)) {
			window.setTimeout(() => {
				setNoEditingError(false);
			}, 500);
			if (!noEditingError) {
				dispatch(
					setError('ท่านไม่ใช่เจ้าของโครงการนี้', {
						returnHome: false,
					}),
				);
				setNoEditingError(true);
			}
			return;
		}

		if (locked) {
			onLockedCellEdit();
			return;
		}
		setField(e.target.value);

		if (id.match(/raw|labour|total|qty/)) {
			if (!e.target.value) {
				dispatch({
					type: id.match(/qty/)
						? ProjectAction.EDIT_ROW
						: ProjectAction.EDIT_PRICE,
					index,
					mutation: {
						[id]: 0,
					},
				});
			}

			const parsedValue = parseFloat(e.target.value);

			if (Number.isNaN(parsedValue)) {
				return;
			}

			const fixedValue = fix(parsedValue);

			dispatch({
				type: id.match(/qty/)
					? ProjectAction.EDIT_ROW
					: ProjectAction.EDIT_PRICE,
				index,
				mutation: {
					[id]: fixedValue,
				},
			});
		} else {
			dispatch({
				type: ProjectAction.EDIT_ROW,
				index,
				mutation: {
					[id]: e.target.value,
				},
			});
		}
	};

	const setFocus = () => {
		inputRef.current!.focus();
		if (!focus) {
			dispatch({
				type: 'table.focus.on',
				index,
				id,
			});
		}
	};

	const moveFocus = (e: KeyboardEvent) => {
		if (focus) {
			switch (e.keyCode) {
				case 13:
					if (e.shiftKey) {
						dispatch({
							type: 'table.focus.on',
							index: index - 1,
						});
					} else {
						dispatch({
							type: 'table.focus.on',
							index: index + 1,
						});
					}
					break;
				case 38:
					dispatch({
						type: 'table.focus.on',
						index: index - 1,
					});
					break;
				case 40:
					dispatch({
						type: 'table.focus.on',
						index: index + 1,
					});
					break;
				case 9: {
					e.preventDefault();
					if (e.shiftKey) {
						dispatch({
							type: 'table.focus.on',
							id:
								COLUMNS[
									(COLUMNS.indexOf(id) % COLUMNS.length) - 1
								],
						});
					} else {
						dispatch({
							type: 'table.focus.on',
							id:
								COLUMNS[
									(COLUMNS.indexOf(id) % COLUMNS.length) + 1
								],
						});
					}
				}
			}
		}
	};

	useLayoutEffect(() => {
		if (focus) {
			inputRef.current!.focus();
		} else if (field !== value) {
			setField(value);
		}
	}, [focus, field, value, setField]);

	const displayValue =
		(id.match(/^.*[t|T]otal$/) ? fix(value as number) : field) || '';

	return (
		<Wrapper width={width} {...delegated}>
			<Input
				ref={inputRef}
				type="text"
				value={displayValue}
				onFocus={setFocus}
				onChange={mutateState}
				onKeyDown={moveFocus}
				style={getAlignment(id)}
			/>
		</Wrapper>
	);
};

const mapStateToProps = (state: RootState, props: Props) => ({
	width: state.table.columns[props.id].width,
	focus: state.table.focus,
});

const mergeProps = (
	state: ReturnType<typeof mapStateToProps>,
	_: unknown,
	own: Props,
) => {
	return {
		...own,
		width: state.width,
		focus: !!(
			state.focus &&
			state.focus.id === own.id &&
			state.focus.index === own.index
		),
	};
};

type MergedProps = ReturnType<typeof mergeProps>;

export default connect(mapStateToProps, undefined, mergeProps)(NormalCell);

function fix(value: number, digits: number = 2): number {
	return Math.round(value * Math.pow(10, digits)) / Math.pow(10, digits);
}

export function getAlignment(id: ColumnName): React.CSSProperties {
	if (id.match(/(raw|labour)|(.*[t|T]otal)/)) {
		return {
			justifyContent: 'flex-end',
			textAlign: 'end',
		};
	}

	if (id === 'qty' || id === 'unit' || id === 'note') {
		return {
			justifyContent: 'center',
			textAlign: 'center',
		};
	}

	return {};
}

const Wrapper = styled.div<{ width: number }>`
	flex: 0 0 auto;
	min-width: 0;
	width: ${(props) => props.width}px;
	border-right: 1px solid var(--neutrals-100);
`;

const Input = styled.input`
	color: var(--neutrals-900);
	padding: 0.25rem 0.5rem;
	white-space: nowrap;
	overflow: hidden;
	width: 100%;
	height: 100%;
	font-size: 0.9em;
	appearance: none;
	display: inline-flex;
	align-items: center;
	justify-content: flex-start;
	border: none;
	background: transparent;
	font-weight: var(--row-font-weight);

	&:hover {
		outline: none;
		background: var(--accent-050);
	}

	&:focus {
		outline: none;
		border: 2px solid var(--accent-400);
	}
`;
